import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vizu-audio-perm',
  templateUrl: './vizu-audio-perm.component.html',
  styleUrls: ['./vizu-audio-perm.component.scss']
})
export class VizuAudioPermComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
