import { Component, ElementRef, Input, SimpleChanges, ViewChild } from "@angular/core";
import { Collaborator, DomainData, Limitations, Message } from "src/app/domain/domain";
import { RtcService } from "src/app/services/rtc.service";

const emojisToneCompatible: number[] = [
  0x261d, 0x26f9, 0x270a, 0x270b, 0x270c, 0x270d, 0x1f385, 0x1f3c2, 0x1f3c3, 0x1f3c4, 0x1f3c7, 0x1f3ca, 0x1f3cb, 0x1f3cc, 0x1f442, 0x1f443,
  0x1f446, 0x1f447, 0x1f448, 0x1f449, 0x1f44a, 0x1f44b, 0x1f44c, 0x1f44d, 0x1f44e, 0x1f44f, 0x1f450, 0x1f466, 0x1f467, 0x1f468, 0x1f469,
  0x1f46e, 0x1f470, 0x1f471, 0x1f472, 0x1f473, 0x1f474, 0x1f475, 0x1f476, 0x1f477, 0x1f478, 0x1f47c, 0x1f481, 0x1f482, 0x1f483, 0x1f485,
  0x1f486, 0x1f487, 0x1f4aa, 0x1f574, 0x1f575, 0x1f57a, 0x1f590, 0x1f595, 0x1f596, 0x1f645, 0x1f646, 0x1f647, 0x1f64b, 0x1f64c, 0x1f64d,
  0x1f64e, 0x1f64f, 0x1f6a3, 0x1f6b4, 0x1f6b5, 0x1f6b6, 0x1f6c0, 0x1f6cc, 0x1f918, 0x1f919, 0x1f91a, 0x1f91b, 0x1f91c, 0x1f91d, 0x1f91e,
  0x1f91f, 0x1f926, 0x1f930, 0x1f931, 0x1f932, 0x1f933, 0x1f934, 0x1f935, 0x1f936, 0x1f937, 0x1f938, 0x1f939, 0x1f93d, 0x1f93e, 0x1f9d1,
  0x1f9d2, 0x1f9d3, 0x1f9d4, 0x1f9d5, 0x1f9d6, 0x1f9d7, 0x1f9d8, 0x1f9d9, 0x1f9da, 0x1f9dc, 0x1f9dd,
];

const SkinTonesKeys = ["Default", "Light", "MediumLight", "Medium", "MediumDark", "Dark"];
const SkinTones: any = {
  Default: 0,
  Light: 0x1f3fb,
  MediumLight: 0x1f3fc,
  Medium: 0x1f3fd,
  MediumDark: 0x1f3fe,
  Dark: 0x1f3ff,
};

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrl: "./messages.component.scss",
})
export class MessagesComponent {
  @Input() Customer: any = {};
  @Input() Limitations: Limitations;
  @Input() messagesRecepient: string = "";
  @Input() isAnonymeDisplay: boolean = false;
  @Input() DomainData: DomainData;
  @Input() messagesTo: string = "";
  @Input() SearchField: string = "";
  @Input() lastMessage: Message = undefined;

  @Input() messagesDisplayed: Message[] = [];

  @Input() Contacts_and_Anonymous!: () => Collaborator[];
  @Input() backMessages!: () => void;
  @Input() onmessage!: (message: Message) => void;
  @Input() displayMessages!: (to: string, displayName: string) => void;
  @Input() sendClickInfoMessage!: (info: string) => void;
  @Input() deleteChatMessages!: (to: string, displayName: string) => void;
  @Input() deleteChatMessage!: (messageId: string) => void;

  messageData: string = "";
  isMessageHover: any[] = [];

  emojis: string[] = [];
  skinTone: number = SkinTones.Default;
  skinTonesKeys: string[] = SkinTonesKeys;
  skinTones: object = SkinTones;

  constructor(private rtc: RtcService) {
    this.generateEmojis();
    console.log(this.messagesDisplayed);
  }

  sendChatMessage() {
    this.rtc.sendOfflineMessage(this.messagesTo, this.messageData); //, ""
    this.messageData = "";
  }

  OnKeyDown(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      //document.getElementById("sendMessageSubmit").click();
      this.sendChatMessage();
      //this.scrollToBottom();
    }
  }

  formatTimestamp(timestamp: number): string {
    const messageDate = new Date(timestamp);
    const currentDate = new Date();

    const isToday =
      messageDate.getDate() === currentDate.getDate() &&
      messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getFullYear() === currentDate.getFullYear();

    const isThisYear = messageDate.getFullYear() === currentDate.getFullYear();

    const timeString = messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    if (isToday) {
      return timeString;
    } else {
      const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
      };

      if (!isThisYear) {
        dateOptions.year = "numeric";
      }

      //const dateString = messageDate.toLocaleDateString('fr-FR', dateOptions);
      const dateString = messageDate.toLocaleDateString([], dateOptions);
      return `${dateString} ${timeString}`;
    }
  }

  selectEmoji(emoji: string) {
    this.messageData += emoji;
  }

  generateEmojis(): void {
    this.emojis = [];
    for (let i = 0x1f600; i <= 0x1f637; i++) {
      this.emojis.push(this.getEmoji(i));
    }
    for (let i = 0x1f446; i <= 0x1f449; i++) {
      this.emojis.push(this.getEmoji(i));
    }

    const handsFingersClosed = [0x270a, 0x1f44a, 0x1f44d, 0x1f44e, 0x1f91b, 0x1f91c];

    for (let emoji of handsFingersClosed) {
      this.emojis.push(this.getEmoji(emoji));
    }
  }

  getEmoji(value: number) {
    if (this.isToneCompatible(value)) {
      return String.fromCodePoint(value, this.skinTone);
    } else {
      return String.fromCodePoint(value);
    }
  }

  setSkinTone(skinToneKey: string) {
    this.skinTone = SkinTones[skinToneKey];
    this.generateEmojis();
  }

  isToneCompatible(emoji: number): boolean {
    return emojisToneCompatible.includes(emoji);
  }

  getHours(timestamp: number | string | Date) {
    return new Date(timestamp).toLocaleTimeString("default", { hour: "2-digit", minute: "2-digit" });
  }

  getLogoURIContact(from: string): string {
    for (let contact of this.Contacts_and_Anonymous()) {
      if (contact?.data?.Username == from) {
        return contact.data.LogoURI;
      }
    }
    if (from == "anonymous") {
      return "/assets/icons/profile-anonymous-default.svg";
    } else {
      return "/assets/icons/profile-default.svg";
    }
  }

  @ViewChild("messageContainer") private messageContainer: ElementRef;
  private scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error("Scroll to bottom error:", err);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["lastMessage"]) {
      const currentValue = changes["lastMessage"].currentValue;
      if (this.messagesTo == currentValue?.From || this.messagesTo == currentValue?.To) {
        this.scrollToBottom();
      }
    }
  }
}
