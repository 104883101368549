import { Component, OnInit } from '@angular/core'
import { RtcService } from 'src/app/services/rtc.service';

@Component({
    selector: 'app-vizu-public',
    templateUrl: './vizu-public.component.html',
    styleUrls: ['./vizu-public.component.scss'],
})
export class VizuPublicComponent implements OnInit {

    cookies: any = undefined;

    constructor(public rtc: RtcService) {}

    ngOnInit() {}
}
