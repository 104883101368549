import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import * as CryptoJS from 'crypto-js';
import { AuthService } from '../services/auth.service'
import { Customer, DomainData, Limitations } from '../domain/domain'

declare const DataServer: any
declare const ngmediaRTC: any

import { Observable, Subject } from 'rxjs'

import * as platform from 'platform'

export interface InMessage {
    Type: string
    From?: number
    Content?: any
}

interface outMessage {
    Type: string
    To?: number
    Content?: any
}

export interface Attendee {
    ID: number
    Name: string
    Connected: boolean
    InFailed: boolean
    OutFailed: boolean
    Stream?: MediaStream
    isAudio: boolean
    isVideo: boolean
    InScreenFailed: boolean
    OutScreenFailed: boolean
    ScreenStream?: MediaStream
    LaserState: {
        Enabled: boolean,
        X: number,
        Y: number,
        W: number,
        H: number,
    },
}

export interface AttendeeLocked {
    ID: number
    Name: string
}

const extensionID = 'dbabfolfgmkobccpkcpedoikilofmapf'


@Injectable({
    providedIn: 'root',
})
export class RtcService {
    private closed = false
    private call: any;

    private websocket: WebSocket
    bWebsocketConnected: boolean = false
    bWebsocketReconnecting: boolean = false
    Events = new Subject<InMessage>()

    AttendeesAccepted: any[] = []

    private sigEvents = new Subject<outMessage>()

    //LocalStream = new BehaviorSubject<MediaStream>(undefined)
    ScreenStream: any = undefined
    LocalStream: any = undefined
    LocalStreamVideo: boolean = false
    LocalStreamAudio: boolean = false

    LocalScreenStream: MediaStream
    messages: any[] = []
    end_user_messages: any[] = []
    cdrs: any[] = []
    contacts: any[] = []
    confRooms: any[] = []
    privRoom = {
        id: "",
        Administrator: false,
        CustomData: "",
        LogoURI: "",
        Email: "",
        Room: "",
        Name: "",
        Username: "",
        Participants: [] = <any>[],
        ParticipantsCount: 0,
    }

    requestId: number = undefined
    options = {
        mediaServer: location.host,
        to      : '',
        toName  : '',
        token   : '',
        domain  : '',
        from    : '',
        fromName: '',
        subject : '',
        uui     : '',
        audio   : 'true',
        video   : 'true',
        log     : 'false',
        display : '',
        reconnectId: '',
        credentialUsername: '',
        credentialPassword: ''
    };

    Limitations: Limitations
    callAuthorization: any = undefined
    pingInterval: any = undefined
    bUseDataChannel: boolean = true

    bPrivate: boolean = false;
    bPrivateOutboundCall: boolean = true
    isRegistered: boolean = false;
    registration: any = undefined;
    shouldBeRegistered: boolean = false
    //shouldBeReconnected: boolean = true

    ngmsUrl: any = ""

    deviceInfos: any = {
        OS: undefined,
        Browser: undefined,
        AudioDevices: undefined,
        VideoDevices: undefined,
        hasPermissions: undefined,
        FriendlyName: undefined,
    }
	cookies: any;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
    )
    {
        this.ngmsUrl = DataServer
    }

    notifyOnWebSocketConnected       = function()           { this._notifyOnWebSocketConnected(); }
    notifyOnWebSocketError           = function(event: any) { this.notifyOnWebSocketError(event); }
    notifyOnWebSocketReconnecting    = function(event: any) { this.notifyOnWebSocketReconnecting(event); }
    notifyOnMeData                   = function(event: any) { this._notifyOnMeData(event); }
    notifyOnProductData              = function(event: any) { this._notifyOnProductData(event); }
    notifyOnDomainData               = function(event: any) { this._notifyOnDomainData(event); }
    notifyOnPrivateRoomChanged       = function(event: any) { this._notifyOnPrivateRoomChanged(event); }
    notifyOnConfRoomsChanged         = function(event: any) { this._notifyOnConfRoomsChanged(event); }
    notifyOnContactsChanged          = function(event: any) { this._notifyOnContactsChanged(event); }
    notifyOnNewCdr                   = function(event: any) { this._notifyOnNewCdr(event); }
    notifyOnGetUserMedia             = function()           { this._notifyOnGetUserMedia(); }
    notifyOnNewIncomingCall          = function(name:string){ this._notifyOnNewIncomingCall(name) }
    notifyOnNewCall                  = function(attendee:any){ this._notifyOnNewCall(attendee); }
    notifyOnCallStateChanged         = function(event: any) { this._notifyOnCallStateChanged(event); }
    notifyOnCallConnected            = function(event: any) { this._notifyOnCallConnected(event); }
    notifyOnCallDisconnected         = function(event: any) { this._notifyOnCallDisconnected(event); }
    notifyOnMediaStreamAdded         = function(event: any) { this._notifyOnMediaStreamAdded(event); }
    notifyOnStreamAdded              = function(event: any) { this._notifyOnStreamAdded(event); }
    notifyOnStreamRemoved            = function(event: any) { this._notifyOnStreamRemoved(event); }
    notifyOnParticipantAdded         = function(event: any) { this._notifyOnParticipantAdded(event); }
    notifyOnParticipantUpdated       = function(event: any) { this._notifyOnParticipantUpdated(event); }
    notifyOnParticipantRemoved       = function(event: any) { this._notifyOnParticipantRemoved(event); }
    notifyOnMessage                  = function(event: any) { this._notifyOnMessage(event); }
    notifyOnMessageRemoved           = function(event: any) { this._notifyOnMessageRemoved(event); }
    notifyOnMessageDataChannel       = function(event: any) { this._notifyOnMessageDataChannel(event); }
    notifyOnMessageDeliveryProgress  = function(event: any) { this._notifyOnMessageDeliveryProgress(event); }
    notifyOnAuthorization            = function()           { this._notifyOnAuthorization(); }
    notifyOnAuthorizationForbidden   = function()           { this._notifyOnAuthorizationForbidden(); }
    notifyOnParticipantMissed        = function()           { this._notifyOnParticipantMissed(); }
    notifyOnSelfRoomEditError        = function(event: any) { this._notifyOnSelfRoomEditError(event); }
    notifyOnSelfNameEditError        = function(event: any) { this._notifyOnSelfNameEditError(event); }
    notifyOnSelfLogoURIEditError     = function(event: any) { this._notifyOnSelfLogoURIEditError(event); }
    
    _notifyOnWebSocketConnected         ()              {}
    _notifyOnWebSocketError             (event: any)    { return event }
    _notifyOnWebSocketReconnecting      (event: any)    { return event }
    _notifyOnMeData                     (event: any)    { return event }
    _notifyOnProductData                (event: any)    { return event }
    _notifyOnDomainData                 (event: any)    { return event }
    _notifyOnPrivateRoomChanged         (event: any)    { return event }
    _notifyOnConfRoomsChanged           (event: any)    { return event }
    _notifyOnContactsChanged            (event: any)    { return event }
    _notifyOnNewCdr                     (event: any)    { return event }
    _notifyOnGetUserMedia               ()              {}
    _notifyOnNewIncomingCall            (name: string)  { return name  }
    _notifyOnNewCall                    (attendee: any) { return attendee  }
    _notifyOnCallStateChanged           (event: any)    { return event }
    _notifyOnCallConnected              (event: any)    { return event }
    _notifyOnCallDisconnected           (event: any)    { return event }
    _notifyOnMediaStreamAdded           (event: any)    { return event }
    _notifyOnStreamAdded                (event: any)    { return event }
    _notifyOnStreamRemoved              (event: any)    { return event }
    _notifyOnParticipantAdded           (event: any)    { return event }
    _notifyOnParticipantUpdated         (event: any)    { return event }
    _notifyOnParticipantRemoved         (event: any)    { return event }
    _notifyOnMessage                    (event: any)    { return event }
    _notifyOnMessageRemoved             (event: any)    { return event }
    _notifyOnMessageDataChannel         (event: any)    { return event }
    _notifyOnMessageDeliveryProgress    (event: any)    { return event }
    _notifyOnAuthorization              ()              {}
    _notifyOnAuthorizationForbidden     ()              {}
    _notifyOnParticipantMissed          ()              {}
    _notifyOnSelfRoomEditError          (event: any)    { return event }
    _notifyOnSelfNameEditError          (event: any)    { return event }
    _notifyOnSelfLogoURIEditError       (event: any)    { return event }

    closeWebSocket() {
        //this.shouldBeReconnected = false
        if (this.websocket !== undefined) {
            this.websocket.close()
        }
        this.websocket = undefined
        this.contacts = []
        this.confRooms = []
        this.privRoom.ParticipantsCount = 0
        this.privRoom.Participants = []
    }

    async callbackWebSocket() {
        if (this.websocket !== undefined) {
            this.closeWebSocket()
        }
        
        //this.shouldBeReconnected = true
        
        let uri = '';
        if (location.protocol === 'https:') {
            if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
                uri 	= 'wss://' + location.host +'/' + this.ngmsUrl.Url + '/user/ws/ws';
            } else {
                uri 	= 'wss://' + location.host + '/ngmconf/user/ws/ws';
            }
        } else {
            if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
                uri 	= 'ws://' + location.host +'/' + this.ngmsUrl.Url + '/user/ws/ws';
            } else {
                uri 	= 'ws://' + location.host +'/ngmconf/user/ws/ws';
            }
        }
        /*try {
            console.log(":) TRYCATCH / WEB SOCKET CONNECTION SUCCESS :)")
            this.websocket = new WebSocket(uri);
        }
        catch (err) {
            console.log("/!\ TRYCATCH / WEB SOCKET CONNECTION ERROR /!\ ")
        }*/
        this.websocket = new WebSocket(uri);
        this.websocket.onopen = function(evt: any) {
            if (this.bWebsocketReconnecting) {
                this.bWebsocketReconnecting = false
                let myThis = this
                setTimeout(function(){if (myThis.bWebsocketReconnecting) {myThis.notifyOnWebSocketReconnecting(false)} }, 5000)
            }
            this.bWebsocketConnected = true
            console.log('WebSocket opened');
            let subscribeMsg = {
                method: "POST",
                path: "/subscriptions",
                body: { Events: "*phone me.register" }
            }
            if (this.websocket !== undefined) {
                this.websocket.send(JSON.stringify(subscribeMsg))
            }

            this.pingInterval = setInterval(() => {
                let pingMsg = {
                    method: "POST",
                    path: "/ping",
                    body: {}
                }
                if (this.websocket !== undefined) {
                    this.websocket.send(JSON.stringify(pingMsg))
                }
            }, 30000)

            this.notifyOnWebSocketConnected()

            if (localStorage.getItem("VizuLive\\Error") !== "null" && localStorage.getItem("VizuLive\\Error") !== null) {
                let getLastErrors = localStorage.getItem("VizuLive\\Error")
                if (getLastErrors.startsWith("[")) {
                    getLastErrors = JSON.parse(getLastErrors)
                    for (let i = 0; i < getLastErrors.length; i++) {
                        this.sendMessage(JSON.stringify(getLastErrors[i]))
                    }
                } else {
                    this.sendMessage(getLastErrors)
                }
                localStorage.removeItem("VizuLive\\Error")
            }
        }.bind(this);
        this.websocket.onerror = async function(evt: any) {
            console.log("/!\\ ONERROR / WEB SOCKET CONNECTION ERROR /!\\ " + evt)
            if (this.pingInterval !== undefined) {
                clearInterval(this.pingInterval)
            }
            /*console.log("/!\\ WEB SOCKET RECONNECT /!\\")
            setTimeout(() => {
                this.reconnect()
            }, 1000)*/

            //this.notifyOnWebSocketError(evt)
        }.bind(this);
        this.websocket.onclose = async function(evt: any) {
            console.log("/!\\ WEB SOCKET CLOSED /!\\", evt)
            this.bWebsocketConnected = false
            if (this.pingInterval !== undefined) {
                clearInterval(this.pingInterval)
            }
            
            //Manage reconnect websocket with websockets codes
            if (evt.code === 1011) {
                this.reconnect()
            } else if (evt.code === 1006) {
                if (!this.bWebsocketReconnecting) {
                    this.bWebsocketReconnecting = true
                    let myThis = this
                    setTimeout(function(){if (myThis.bWebsocketReconnecting) {myThis.notifyOnWebSocketReconnecting(false)} }, 5000)
                }
                await sleep(2000)
                this.callbackWebSocket()
            }
        }.bind(this)
        this.websocket.onmessage = async function(evt: any) {
            let msg = JSON.parse(evt.data);
            if (msg.path !== undefined) {
                if (msg.path.startsWith("/end_user_contacts") || msg.path.startsWith("/domain_contacts") || msg.path.startsWith("/end_users") || msg.path.startsWith("/conference_rooms")) {
                    var contact: any = {};
                    if (msg.body) {
                        contact.data = msg.body;
                    }
                    
                    if (msg.path.startsWith("/end_user_contacts")) {
                        contact.Type = 'UserContacts';
                        if (contact.data.PhoneNumber != undefined) {
                            contact.To = contact.data.PhoneNumber;
                            contact.DisplayName = contact.data.PhoneNumber; 
                        }
                        if ((contact.data.FirstName != '' && contact.data.FirstName != undefined)  || (contact.data.LastName != '' && contact.data.LastName != undefined)) {
                            contact.DisplayName = contact.data.FirstName + ' ' + contact.data.LastName ; 
                        }
                    } else if (msg.path.startsWith("/domain_contacts")) {
                        if (contact.data) {
                            contact.Type = 'DomainContacts';
                            contact.To = contact.data.PhoneNumber;
                            contact.DisplayName = contact.data.PhoneNumber; 
                            if (contact.data.PhoneNumber != undefined) {
                                contact.To = contact.data.PhoneNumber;
                                contact.DisplayName = contact.data.PhoneNumber;
                            }
                            if (contact.data.Name != '' && contact.data.Name != undefined) {
                                contact.DisplayName = contact.data.Name; 
                            }
                        }
                    } else if (msg.path.startsWith("/end_users")) {
                        contact.Type = 'User';

                        if (contact.data) {
                            contact.data.InCall = undefined;

                            if (contact.data.Username != undefined) {
                                contact.To = contact.data.Username;
                                contact.DisplayName = contact.data.Username;
                            }
                            if (contact.data.Name != '' && contact.data.Name != undefined) {
                                contact.DisplayName = contact.data.Name; 
                            }
                        }

                        let pathInfos = msg.path.split('/')
                        switch (msg.method) {
                        case "POST" :
                            if (pathInfos[3] === "participants") {
                                contact.Type = 'PublicInCallNotification';
                                //LINE ADDED BY MM / TOCHECK
                                if (pathInfos[2] === this.privRoom.id) {
                                    let userFound = false

                                    if (this.privRoom.Participants === undefined) {
                                        this.privRoom.Participants = []
                                    } else {
                                        for (let k = 0; k < this.privRoom.Participants.length; k++) {
                                            //if (this.privRoom.Participants[k].Data.User == msg.body.User) {
                                            if (this.privRoom.Participants[k].Data.Call == msg.body.Call) {
                                                userFound = true
                                                this.privRoom.Participants[k].State = msg.body.State
                                                this.notifyOnPrivateRoomChanged(this.privRoom)
                                                break;
                                            }
                                        }
                                    }
                                    if (!userFound) {
                                        this.privRoom.ParticipantsCount++
                                        this.privRoom.Participants.push({
                                            Data: msg.body,
                                            Path: msg.path,
                                            Name: msg.body.Name,
                                            State: msg.body.State
                                        })
                                        this.notifyOnPrivateRoomChanged(this.privRoom)
                                        break;
                                    }
                                }
                                //Parse contacts to add state of contact
                                for (let i = 0; i < this.contacts.length; i++) {
                                    if (this.contacts[i].Type === "User" && this.contacts[i].data.id === msg.body.User) {
                                        contact.Type = 'PublicInCallNotification'
                                        this.contacts[i].data.InCallId = msg.body.Call
                                        for (let j = 0; j < this.contacts.length; j++) {
                                            //MATCH PRIVATE ROOM ID WITH PRIVATE ROOM NAME
                                            if (pathInfos[2] === this.contacts[j].data.id) {
                                                this.contacts[i].data.InCall = this.contacts[j].data.Name
                                            }
                                        }
                                        this.notifyOnContactsChanged(this.contacts)
                                    }
                                }
                            } else if (pathInfos[3] === "participant_missed") {
                                this.notifyOnParticipantMissed(msg.body)
                            } else if (pathInfos[3] === undefined) {
                                // NEW CONTACT
                                this.contacts.sort(function compare(a: any, b: any) {
                                    if (a.DisplayName < b.DisplayName)
                                    return -1;
                                    if (a.DisplayName > b.DisplayName )
                                    return 1;
                                    return 0;
                                });
                                this.contacts.push(contact);
                                this.notifyOnContactsChanged(this.contacts)
                            }
                            break
                        case "PUT" :
                            if (pathInfos[3] === "participants" && msg.body.State === "conferenced") {
                                // Update the conferenced participant State to "conferenced"
                                if (pathInfos[2] === this.privRoom.id) {
                                    for (let i = 0; i < this.privRoom.Participants.length; i++) {
                                        if (pathInfos[4] === this.privRoom.Participants[i].Data.Call) {
                                            this.privRoom.Participants[i].State = msg.body.State
                                            this.notifyOnPrivateRoomChanged(this.privRoom)
                                        }
                                    }
                                }
                            } else {
                                for (let i = 0; i < this.contacts.length; i++) {
                                    if (pathInfos[3] === "data") {
                                        //STATE CHANGED
                                        if (pathInfos[2] === this.contacts[i].data.id) {
                                            this.contacts[i].State = contact.data.State
                                        }
                                    } else if (pathInfos[3] === undefined) {
                                        if (this.contacts[i].data.id === pathInfos[2]) {
                                        //USER UPDATED
                                            if (contact.dataUsername != undefined) {
                                                this.contacts[i].To = contact.data.Username;
                                                this.contacts[i].data.Username = contact.data.Username;
                                            }
                                            if (contact.data.Name != '' && contact.data.Name != undefined) {
                                                this.contacts[i].data.DisplayName = contact.data.Name;
                                            }
                                            if (contact.data.FirstName != undefined) {
                                                this.contacts[i].data.FirstName = contact.data.FirstName;
                                            }
                                            if (contact.data.LastName != undefined) {
                                                this.contacts[i].data.LastName = contact.data.LastName;
                                            }
                                            if (contact.data.PhoneNumber != undefined) {
                                                this.contacts[i].data.PhoneNumber = contact.data.PhoneNumber;
                                            }
                                            if (contact.data.LogoURI != undefined) {
                                                this.contacts[i].data.LogoURI = contact.data.LogoURI;
                                            }
                                        }
                                    }
                                }
                                this.notifyOnContactsChanged(this.contacts)
                            }
                            break
                        case "PATCH" :
                            for (let i = 0; i < this.contacts.length; i++) {
                                if (pathInfos[3] === undefined) {
                                    if (this.contacts[i].data.id === pathInfos[2]) {
                                    //USER UPDATED
                                        if (contact.dataUsername != undefined) {
                                            this.contacts[i].To = contact.data.Username;
                                            this.contacts[i].data.Username = contact.data.Username;
                                        }
                                        if (contact.data.Name != '' && contact.data.Name != undefined) {
                                            this.contacts[i].data.DisplayName = contact.data.Name;
                                        }
                                        if (contact.data.FirstName != undefined) {
                                            this.contacts[i].data.FirstName = contact.data.FirstName;
                                        }
                                        if (contact.data.LastName != undefined) {
                                            this.contacts[i].data.LastName = contact.data.LastName;
                                        }
                                        if (contact.data.PhoneNumber != undefined) {
                                            this.contacts[i].data.PhoneNumber = contact.data.PhoneNumber;
                                        }
                                        if (contact.data.LogoURI != undefined) {
                                            this.contacts[i].data.LogoURI = contact.data.LogoURI;
                                        }
                                    }
                                }
                                this.notifyOnContactsChanged(this.contacts)
                            }
                            break
                        case "DELETE":
                            if (pathInfos[3] === "participants") {
                                if (this.privRoom.id !== pathInfos[2]) {
                                    for (let i = 0; i < this.contacts.length; i++) {
                                        if (this.contacts[i].Type === "User" && this.contacts[i].data.InCallId === pathInfos[4]) {
                                            this.contacts[i].data.InCall = undefined
                                            this.contacts[i].data.InCallId = undefined
                                        }
                                    }
                                } else {
                                    for (let i = 0; i < this.privRoom.Participants.length; i++) {
                                        let userPathInfos = this.privRoom.Participants[i].Path.split('/')
                                        if (pathInfos[4] === userPathInfos[4]) {
                                            this.privRoom.Participants.splice(i, 1)
                                            this.privRoom.ParticipantsCount--
                                            this.notifyOnPrivateRoomChanged(this.privRoom)
                                        }
                                    }
                                }
                            } else {
                                if (pathInfos[3] === undefined) {
                                    for (let i = 0; i < this.contacts.length; i++) {
                                        if (this.contacts[i].data.id === pathInfos[2]) {
                                            this.contacts.splice(i, 1)
                                            this.notifyOnContactsChanged(this.contacts)
                                        }
                                    }
                                }
                            }
                            break
                        }
                    } else if (msg.path.startsWith("/conference_rooms")) {
                        if (contact.data) {
                            contact.To = contact.data.Room;
                            contact.DisplayName = contact.data.Room;
                            
                            if (contact.data.Room != undefined) {
                                contact.To = contact.data.Room;
                                contact.DisplayName = contact.data.Room; 
                            }
                            if (contact.data.Name != '' && contact.data.Name != undefined) {
                                contact.DisplayName = contact.data.Name;
                            }
                        }

                        let pathInfos = msg.path.split('/')
                        switch (msg.method) {
                        case "POST" :
                            if (pathInfos[3] === "participants") {
                                contact.Type = 'PublicInCallNotification';
                                for (let j = 0; j < this.confRooms.length; j++) {
                                    if (this.confRooms[j].data.id === pathInfos[2]) {
                                        let userFound = false
                                        if (this.confRooms[j].Participants === undefined) {
                                            this.confRooms[j].Participants = []
                                        } else {
                                            for (let k = 0; k < this.confRooms[j].Participants.length; k++) {
                                                //if (this.confRooms[j].Participants[k].Data.User == msg.body.User) {
                                                if (this.confRooms[j].Participants[k].Data.Call == msg.body.Call) {
                                                    userFound = true
                                                    this.confRooms[j].Participants[k].Data = msg.body
                                                    this.confRooms[j].Participants[k].State = msg.body.State
                                                    this.notifyOnConfRoomsChanged(this.confRooms)
                                                }
                                            }
                                        }
                                        if (!userFound) {
                                            this.confRooms[j].ParticipantsCount++
                                            this.confRooms[j].Participants.push({
                                                Data: msg.body,
                                                Path: msg.path,
                                                Name: msg.body.Name,
                                                State: msg.body.State
                                            })
                                            this.notifyOnConfRoomsChanged(this.confRooms)
                                        }
                                    }
                                }
                                //Parse contacts to add incall room of this contact
                                for (let i = 0; i < this.contacts.length; i++) {
                                    if (this.contacts[i].Type === "User" && this.contacts[i].data.id === msg.body.User) {
                                        for (let j = 0; j < this.confRooms.length; j++) {
                                            if (this.confRooms[j].data.id === pathInfos[2]) {
                                                contact.Type = 'InCallNotification'
                                                this.contacts[i].data.InCallId = pathInfos[4]
                                                this.contacts[i].data.InCall = this.confRooms[j].DisplayName
                                                this.notifyOnContactsChanged(this.contacts)
                                            }
                                        }
                                    }
                                }
                                //Case new people not contact
                                if (!msg.body.Trusted && msg.body.State === "accepted") {
                                    for (let i = 0; i < this.confRooms.length; i++) {
                                        if (this.confRooms[i].data.id === pathInfos[2]) {
                                            this.notifyOnConfRoomsChanged(this.confRooms)
                                        }
                                    }
                                }
                            } else if (pathInfos[3] === undefined) {
                                contact.ParticipantsCount = 0
                                contact.Participants = []
                                this.confRooms.push(contact)
                                this.notifyOnConfRoomsChanged(this.confRooms)
                            }
                            break
                        case "PUT" :
                            if (pathInfos[3] === "participants" && msg.body.State === "conferenced") {
                                for (let i = 0; i < this.confRooms.length; i++) {
                                    if (this.confRooms[i].data.id === pathInfos[2]) {
                                        for (let j = 0; j < this.confRooms[i].Participants.length; j++) {
                                            // participant conferenced, participant existe, status update
                                            if (this.confRooms[i].Participants[j].Data.User === msg.body.User) {
                                                this.confRooms[i].Participants[j].State = msg.body.State
                                                this.notifyOnConfRoomsChanged(this.confRooms)
                                            }
                                        }
                                    }
                                }
                            } else {
                                for (let i = 0; i < this.confRooms.length; i++) {
                                    if (this.confRooms[i].data.id === pathInfos[2]) {
                                        if (pathInfos[3] === undefined) {
                                            if (contact.data.Room != undefined) {
                                                this.confRooms[i].To = contact.data.Room;
                                            }
                                            if (contact.data.Name != '' && contact.data.Name != undefined) {
                                                this.confRooms[i].DisplayName = contact.data.Name; 
                                            }
                                            if (contact.data.Locked != undefined) {
                                                this.confRooms[i].Locked = contact.data.Locked; 
                                            }
                                            if (contact.data.Enabled != undefined) {
                                                this.confRooms[i].Enabled = contact.data.Enabled;
                                            }
                                        }
                                    }
                                }
                                this.notifyOnConfRoomsChanged(this.confRooms)
                            }
                            break
                        case "DELETE" :
                            if (pathInfos[3] === "participants") {
                                for (let i = 0; i < this.contacts.length; i++) {
                                    if (this.contacts[i].Type === "User" && this.contacts[i].data.InCallId === pathInfos[4]) {
                                        this.contacts[i].data.InCall = undefined
                                        this.contacts[i].data.InCallId = undefined
                                    }
                                }
                                for (let j = 0; j < this.confRooms.length; j++) {
                                    if (this.confRooms[j].data.id === pathInfos[2]) {
                                        for (let k = 0; k < this.confRooms[j].Participants.length; k++) {
                                            if (this.confRooms[j].Participants[k].Data.Call == pathInfos[4]) {
                                                this.confRooms[j].Participants.splice(k, 1)
                                                this.confRooms[j].ParticipantsCount--
                                                this.notifyOnConfRoomsChanged(this.confRooms)
                                                break
                                            }
                                        }
                                    }
                                }
                            } else if (pathInfos[3] === undefined) {
                                for (let i = 0; i < this.confRooms.length; i++) {
                                    if (this.confRooms[i].data.id === pathInfos[2]) {
                                        this.confRooms.splice(i, 1);
                                        this.notifyOnConfRoomsChanged(this.confRooms)
                                        break
                                    }
                                }
                            }
                            break
                        }
                    }
                }
                if (msg.path.startsWith("/my_domain")) {
                    let DomainData: DomainData = {
                        Name: msg.body.Name,
                        SharedUsers: msg.body.SharedUsers,
                        CustomData: JSON.parse(msg.body.CustomData)
                    };
                    this.notifyOnDomainData(DomainData)
                } else if (msg.path.startsWith("/me")) {
                    let CustomerData: Customer = msg.body
                    switch (msg.method) {
                        case "POST" :
                            this.notifyOnMeData(CustomerData)
                            break;
                        case "PATCH" :
                            this.notifyOnMeData(CustomerData)
                            break;
                    }
                } else if (msg.path.startsWith("/product")) {
                    let ProductData: Customer = msg.body
                    this.notifyOnProductData(ProductData)
                } else if (msg.path.startsWith("/cdrs")) {
                    var cdr: any = {};
                    if (msg.body) {
                        cdr = msg.body;
                        this.notifyOnNewCdr(cdr)
                    }
                    if (msg.method === "POST") {
                        this.cdrs.push(cdr);
                        //this.getHistory();
                    }
                } else if (msg.path.startsWith("/end_user_messages")) {
                    if (msg.sentByVizu === true) {
                        console.log("MESSAGE SENT BY VIZU, DON'T PROCESS")
                    } else {
                        var message: any = {};
                        var index = undefined;
                        if (msg.body) {
                            message = msg.body;
                        }
                        switch (msg.method) {
                        case "POST" :
                            this.notifyOnMessage(message);
                            this.end_user_messages.push(message)
                            break;
                            //this.messages.push(message);
                            //this.getHistory();
                        case "PATCH":
                            this.notifyOnMessage(message);
                            index = this.end_user_messages.findIndex((msg: any) => msg.id === message.id);
                            this.end_user_messages[index] = message;
                            break;

                        case "DELETE":
                            let id = msg.path.split('/')[2];
                            index = this.end_user_messages.findIndex((msg: any) => msg.id === id);
                            this.end_user_messages.splice(index, 1);
                            this.notifyOnMessageRemoved(id);
                            break;
                        }

                    }
                }
            } else {
                if (msg.code !== undefined) {
                    if (msg.code === 200 || msg.code === 201) {
                        if (this.requestId !== undefined) {
                            if (msg.id === this.requestId.toString()) {
                                this.callAuthorization = msg.body.authorization
                                this.notifyOnAuthorization();
                            }
                        }
                    } else if (msg.code === 409) {
                        if (msg.body?.parameters[0]?.name !== undefined) {
                            if (msg.body.parameters[0]?.name === "Room") {
                                this.notifyOnSelfRoomEditError(msg.body);
                            }
                        }
                    }
                }
            }
        }.bind(this);
    }

    async GetUserMediaLow(lowWidth: any, lowHeight: any): Promise<any> {
        if (this.LocalStream === undefined || this.LocalStream === null) {
            let media = {
                audio: true,
                video: true,
            }
            ngmediaRTC.config.video = {
                constraints              : {
                    width                    : lowWidth  ,
                    height                   : lowHeight  ,
                },
            }
            let myThis = this
            //ngmediaRTC.getLocalMedia(media, null)
            ngmediaRTC.getLocalMedia(media)
            .then(function() {
                //GO TO STEP 1: GET AUTH TOKEN
                console.log("-------GETUSERMEDIA OK--------")
                if (myThis.bPrivate) {
                    if (!myThis.bPrivateOutboundCall) {
                        myThis.answerCall()
                    }
                }
                myThis.LocalStream = ngmediaRTC.localStream
                myThis.setLocalStreamCapabilities()
                return 
            })
            .catch(function(error: any) {
                console.log("-------GETUSERMEDIA ERROR-------- " + error)
            });
        } else {
            console.log("-------GETUSERMEDIA NOT NEEDED-------- ")
            return
        }
    }

    async reconnect() {
        let myThis = this
        if (!this.bWebsocketReconnecting) {
            this.bWebsocketReconnecting = true
            setTimeout(function(){if (myThis.bWebsocketReconnecting) {myThis.notifyOnWebSocketReconnecting(false)} }, 5000)
        }
        
        await sleep(2000)
        let localStorageValues = {
            RememberMe: false,
            Version: "",
            Login: "",
            Password: "",
        }
        if (localStorage.getItem("VizuLive\\RememberMe") !== "null" && localStorage.getItem("VizuLive\\RememberMe") !== null) {
            if (localStorage.getItem("VizuLive\\Version") !== "null" && localStorage.getItem("VizuLive\\Version") !== null) {
                localStorageValues.Version = localStorage.getItem("VizuLive\\Version");
                if (localStorage.getItem("VizuLive\\Login") !== "null" && localStorage.getItem("VizuLive\\Login") !== null) {
                    if (localStorage.getItem("VizuLive\\Password") !== "null" && localStorage.getItem("VizuLive\\Password") !== null) {
                        let tempRememberMe = localStorage.getItem("VizuLive\\RememberMe");
                        let rememberMeValue = (tempRememberMe === "true" ? true : false)
                        localStorageValues.RememberMe = rememberMeValue
                        localStorageValues.Login = localStorage.getItem("VizuLive\\Login");
                        localStorageValues.Password = localStorage.getItem("VizuLive\\Password");
                        if (localStorageValues.Version === "3,02") {
                        }
                        if (localStorageValues.Version === "3,03") {
                            let decryptedLogin = CryptoJS.AES.decrypt(localStorageValues.Login, "hr=br.ac.ge+a.f")
                            let decryptedPassword = CryptoJS.AES.decrypt(localStorageValues.Password, "hr=br.ac.ge+a.f")
                            localStorageValues.Login = decryptedLogin.toString(CryptoJS.enc.Utf8)
                            localStorageValues.Password = decryptedPassword.toString(CryptoJS.enc.Utf8)
                        }
                    }
                }
            }
        }

        /*this.auth.Authenticated()
        .subscribe({
            next: () => {
                this.callbackWebSocket()
            },
            error: (error) => {
                console.log(error)
                // If other error, reconnect because server not there
                if (error.status === 0) {
                    this.callbackWebSocket()
                } else {

                // If error 401
                    if (localStorageValues.RememberMe) {
                        if (localStorageValues.Login !== "" && localStorageValues.Password !== "") {
                            this.auth.Authenticate(localStorageValues.Login, localStorageValues.Password)
                            .subscribe({
                                next: () => {
                                    //Reconnect the websocket after the relogin
                                    this.callbackWebSocket()
                                },
                                error: (err) => {
                                    //Can't relogin, logout
                                    this.notifyOnWebSocketError(err)
                                }
                            })
                        } else {
                            this.callbackWebSocket()
                        }
                    } else {
                        this.callbackWebSocket()
                    }
                }
            }
        });*/

        /*try {
            if (localStorageValues.Login !== "" && localStorageValues.Password !== "") {
                await this.auth.Authenticate(localStorageValues.Login, localStorageValues.Password)
                this.callbackWebSocket()
            }
        } catch (error) {
            this.reconnect()
        }*/

        this.auth.Authenticated()
        .subscribe({
            next: () => {
                this.callbackWebSocket()
            },
            error: (error) => {
                if (error.status === 0) {
                    this.callbackWebSocket()
                } else {
                    if (localStorageValues.RememberMe) {
                        if (localStorageValues.Login !== "" && localStorageValues.Password !== "") {
                            this.auth.Authenticate(localStorageValues.Login, localStorageValues.Password)
                            .subscribe({
                                next: () => {
                                    //Reconnect the websocket after the relogin
                                    this.callbackWebSocket()
                                },
                                error: (err) => {
                                    //Can't relogin, login dead, logout
                                    if (this.bWebsocketReconnecting) {
                                        this.bWebsocketReconnecting = false
                                        setTimeout(function(){if (myThis.bWebsocketReconnecting) {myThis.notifyOnWebSocketReconnecting(false)} }, 5000)
                                    }
                                    this.notifyOnWebSocketError(err)
                                }
                            })
                        } else {
                            //Can't relogin, credentials dead, logout
                            if (this.bWebsocketReconnecting) {
                                this.bWebsocketReconnecting = false
                                setTimeout(function(){if (myThis.bWebsocketReconnecting) {myThis.notifyOnWebSocketReconnecting(false)} }, 5000)
                            }
                            this.notifyOnWebSocketError(error)
                        }
                    } else {
                        if (this.bWebsocketReconnecting) {
                            this.bWebsocketReconnecting = false
                            setTimeout(function(){if (myThis.bWebsocketReconnecting) {myThis.notifyOnWebSocketReconnecting(false)} }, 5000)
                        }
                        this.notifyOnWebSocketError(error)
                        //OLD this.callbackWebSocket()
                    }
                }
            }
        })
    }

    addParticipant(participantToAdd: string) {
        let options = {
            to: participantToAdd,
            from: this.privRoom.Username,
        }
        this.call.addParticipant(options)
    }

    setAttendeesAccepted(attendees: any) {
        if (Array.isArray(attendees)) {
            this.AttendeesAccepted = attendees
        } else {
            this.AttendeesAccepted.push(attendees)
        }
    }

    unsetAttendeesAccepted() {
        this.AttendeesAccepted = []
    }

    getReconnectId() {
        return this.call.reconnectId
    }

    async GetUserMedia(video: boolean, constraints: any): Promise<any> {
        if (this.LocalStream === undefined || this.LocalStream === null) {
            let media = {
                audio: true,
                video: video,
            }
            if (constraints !== null) {
                media = constraints
            }
            let myThis = this
            if (media.video) {
                return new Promise((resolve: any, reject: any) => {
                    //ngmediaRTC.getLocalMedia(media, constraints)
                    ngmediaRTC.getLocalMedia(media)
                    .then(
                        function() {
                        //GO TO STEP 1: GET AUTH TOKEN
                            myThis.setMediaGranted(true)
                            console.log("-------GETUSERMEDIA OK--------")
                            myThis.LocalStream = ngmediaRTC.localStream
                            myThis.LocalStream.screenSharingRemote = false
                            myThis.LocalStream.local = true
                            myThis.setLocalStreamCapabilities()
                            myThis.notifyOnGetUserMedia()
                            resolve()
                        },
                        function(error: any) {
                            myThis.setMediaGranted(false)
                            console.log("-------GETUSERMEDIA ERROR! " + error)
                            reject(error)
                        }
                    )
                })
            } else {
                return new Promise((resolve: any, reject: any) => {
                    //ngmediaRTC.getLocalMedia(media, null)
                    ngmediaRTC.getLocalMedia(media)
                    .then(
                        function() {
                            //GO TO STEP 1: GET AUTH TOKEN
                            console.log("-------GETUSERMEDIA OK--------")
                            if (myThis.bPrivate) {
                                if (!myThis.bPrivateOutboundCall) {
                                    myThis.answerCall()
                                }
                            }
                            myThis.LocalStream = ngmediaRTC.localStream
                            myThis.LocalStream.screenSharingRemote = false
                            myThis.LocalStream.local = true
                            myThis.setLocalStreamCapabilities()
                            myThis.notifyOnGetUserMedia()
                            resolve()
                        },
                        function(error: any) {
                            console.log("-------GETUSERMEDIA ERROR! " + error)
                            reject(error)
                        }
                    )
                })
            }
        } else {
            console.log("-------GETUSERMEDIA NOT NEEDED-------- ")
            return
        }
    }

    async GetPermissions() : Promise<void> {
        let myNgmediaRTC = ngmediaRTC
        return new Promise((resolve: any, reject: any) => {
            myNgmediaRTC.getLocalMediaPermissions()
            .then(
                function(success: any) {
                    resolve(success);
                },
                function(error: any) {
                    reject(error);
                }
            );
        })
    }

    async GetLocalMediaPermissions_Video() : Promise<void> {
        let myNgmediaRTC = ngmediaRTC
        return new Promise((resolve: any, reject: any) => {
            myNgmediaRTC._getLocalMediaGlobalPermissions_Video()
            .then(
                function(response: any) {
                    if (response.state == "denied") {
                        //Video devices ERROR
                        reject(response);
                    } else {
                        //Video devices OK
                        resolve(response);
                    }
                },
                function(error: any) {
                    //Video devices ERROR
                    reject(error);
                }
            );
        })
    }

    async GetLocalMediaPermissions_Audio() : Promise<void> {
        let myNgmediaRTC = ngmediaRTC
        return new Promise((resolve: any, reject: any) => {
            myNgmediaRTC._getLocalMediaGlobalPermissions_Audio()
            .then(
                function(response: any) {
                    if (response.state == "denied") {
                        //Audio devices ERROR
                        reject(response);
                    } else {
                        //Audio devices OK
                        resolve(response);
                    }
                },
                function(error: any) {
                    //Audio devices ERROR
                    reject(error);
                }
            );
        })
    }

    //TODO
    async SwapCamera() : Promise<void> {
    //async SwapCamera(constraints: any) : Promise<void> {
        /*this.currentcamera = this.currentcamera == 'user' ? 'environment' : 'user'

        const newStream = await navigator.mediaDevices.getUserMedia(constraints)

        if (ngmediaRTC.localStream != undefined) {
            ngmediaRTC.localStream.getTracks().forEach((track: any): void => {
                track.stop()
            })
        }
        ngmediaRTC.localStream = newStream*/
        
        //ngmediaRTC.localStream.swapCamera()
        return new Promise<void>((resolve, reject) => {
            this.call.swapCamera()
            resolve()
        })
    }

    getOSBrowser(): any {
        this.deviceInfos = {
            OS: platform.os.toString(),
            Browser: platform.name,
        }
        const getOS = platform.os.toString();
        const getBrowser = platform.name;

        return { Browser: getBrowser, OS: getOS }
    }

    setDevices(devices: any) {
        this.deviceInfos.AudioDevices = 0
        this.deviceInfos.VideoDevices = 0
        if (this.options.fromName !== undefined) {
            this.deviceInfos.FriendlyName = this.options.fromName
        } else {
            this.deviceInfos.FriendlyName = this.options.from
        }
        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind === 'audioinput') {
                this.deviceInfos.AudioDevices++
            }
            if (devices[i].kind === 'videoinput') {
                this.deviceInfos.VideoDevices++ 
            }
        }
    }

    setMediaGranted(isGranted: boolean) {
        this.deviceInfos.hasPermissions = isGranted
    }

    SetLocalMedia() {
        ngmediaRTC.localStream = this.LocalStream
        this.setLocalStreamCapabilities()
    }

    CloseUserMedia() {
        if (ngmediaRTC.localStream !== undefined) {
            ngmediaRTC.closeLocalMedia()
        }
            
        if (this.LocalStream !== undefined) {
            this.LocalStream.getTracks().forEach((track: any) => track.stop())
            this.LocalStream = undefined
        }
    }

    ToggleMic(on: boolean): void {
        if (ngmediaRTC.localStream != undefined) {
            if (on) {
                ngmediaRTC.enableLocalAudio()
            } else {
                ngmediaRTC.disableLocalAudio()
            }
        }
    }

    ToggleVid(on: boolean): void {
        if (ngmediaRTC.localStream != undefined) {
            if (on) {
                ngmediaRTC.enableLocalVideo()
            } else {
                ngmediaRTC.disableLocalVideo()
            }
        }
    }

    async IsChromeCaptureScreenExtensionInstalled(): Promise<boolean> {
        if (platform.name == 'Chrome') {
            return new Promise<boolean>((resolve, reject) => {
                const request = {
                    type: 'is_installed',
                }
                chrome.runtime.sendMessage(extensionID, request, (response) => {
                    resolve(response != undefined)
                })
            })
        }
        return true
    }

    private chromeCaptureScreen(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const request = {
                type: 'capture_screen',
                sources: ['screen', 'window', 'tab'],
            }
            chrome.runtime.sendMessage(extensionID, request, (response) => {
                if (response == undefined) {
                    reject(new Error('Extension not installed'))
                }

                switch (response.type) {
                case 'error':
                    reject(new Error(response.message))
                    break
                case 'success':
                    resolve(response.streamID)
                    break
                }
            })
        })
    }

    InitRegisterCall(callTo: string): void {
        if (this.call !== undefined) {
            this.call = undefined
        }
        this.setPrivate(true)
        this.bPrivateOutboundCall = false
        this.options.to = callTo
        if (this.isRegistered) {
            this._unregisterCall();
            this._registerCall();
        } else {
            this._registerCall();
        }
    }

    private _registerCall() {
        //REGISTER TO NG MEDIA SERVER
        console.log('REGISTERED ! Waiting for call..')
        let myThis = this
        ngmediaRTC.registerCall({ to: this.options.to, domain: "*", authorization: this.callAuthorization }, function(incomingCall: any) {
            myThis.call = incomingCall
            myThis.notifyOnNewIncomingCall(incomingCall.remoteName)
            myThis.call.oncallstatechanged = myThis.notifyOnCallStateChanged.bind(myThis)
            myThis.call.onconnected = myThis.notifyOnCallConnected.bind(myThis)
            myThis.call.ondisconnected = myThis.notifyOnCallDisconnected.bind(myThis)
            myThis.call.onmediastreamadded = myThis.notifyOnMediaStreamAdded.bind(myThis)
            myThis.call.onstreamadded = myThis.notifyOnStreamAdded.bind(myThis)
            myThis.call.onstreamremoved = myThis.notifyOnStreamRemoved.bind(myThis)
            myThis.call.onparticipantadded = myThis.notifyOnParticipantAdded.bind(myThis)
            myThis.call.onparticipantupdated = myThis.notifyOnParticipantUpdated.bind(myThis)
            myThis.call.onparticipantremoved = myThis.notifyOnParticipantRemoved.bind(myThis)
            myThis.call.onmessage = myThis.notifyOnMessage.bind(myThis)
            myThis.call.onmessagedeliveryprogress = myThis.notifyOnMessageDeliveryProgress.bind(myThis)
            myThis.call.onmessagedatachannel = myThis.notifyOnMessageDataChannel.bind(myThis)

        })
        .then(function(new_registration: any) {
            myThis.isRegistered = true;
            //document.getElementById("makeRegister").innerHTML = "UNREGISTER"
            myThis.registration = new_registration;
        });
    }

    _unregisterCall() {
        //REGISTER TO NG MEDIA SERVER
        this.registration.unregister();
        this.isRegistered = false;
    }

    private _makeCall(): void {
        this.call.makeCall()
    }

    answerCall() {
        this.call.answerCall()
    }

    clearCall() {
        if (this.call !== undefined) {
            this.call.clearCall()
        }
        this.bPrivateOutboundCall = null
    }

    hmacSha1(password: any, data :any): any {
        return new Promise((resolve, reject) => {
            const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA1, password);
            hmac.update(data);
            let hash = hmac.finalize();
            if (hash) {
                resolve(hash.toString(CryptoJS.enc.Base64));
                return;
            } else {
                return 'hmac error';
            }
        });
    }

    setPrivate(bPrivate: boolean): void {
        this.bPrivate = bPrivate
    }

    getAuthorization(Room: string, bPrivate: boolean, fromName: string, reconnectId: string) {
        // NOTE POUR PLUS TARD:
        // QUAND NGMSDATA EN PROD, VIRER LES LOGIN ET PASSWORD QUI SONT GERER COTE NGMSDATA
        if (this.requestId === undefined) {
            this.requestId = 0
        } else {
            this.requestId++
        }
        this.options.token = this.requestId.toString()

        this.options.fromName = fromName
        this.options.to = Room
        this.options.domain = "*"
        if (!bPrivate) {
            this.options.credentialUsername = 'anonymous'
            this.options.credentialPassword = 'anonymous'
            this.options.from = 'anonymous'
        } else {
            this.options.credentialUsername = ''
            this.options.credentialPassword = ''
            this.options.from = ''
        }
        if (reconnectId !== "") {
            this.options.reconnectId = reconnectId
        }

        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            if (bPrivate) {
                // Websocket
                let params = {
                    method: "GET",
                    id: this.options.token.toString(),
                    path: "/rtc_authorization/",
                    body: { token: this.options.token.toString(), domain: this.options.domain, to: this.options.to, fromName: this.options.fromName, toName: this.options.toName },
                }
                this.sendMsg(params)
            } else {
                //XHR
                let params = {
                    token: this.options.token.toString(),
                    domain: this.options.domain,
                    to: this.options.to,
                    fromName: this.options.fromName,
                    toName: this.options.toName
                }
                let url = '/ngmsdata/public/ws-noauth/rtc_authorization'

                this.http.post<any>(url, params)
                .subscribe({
                    next: (response) => {
                        this.callAuthorization = response.authorization
                        this.notifyOnAuthorization()
                    },
                    error: (e) => {
                        if (e.status === 403) {
                            this.notifyOnAuthorizationForbidden()
                        }
                    },
                })
            }
        } else {
            if (this.options.to != undefined) {
                let data = this.options.token + '\n' + this.options.domain + '\n' +  this.options.to + '\n' + this.options.toName + '\n' + ((this.options.from === undefined) ? "" : this.options.from) + '\n' + this.options.fromName + '\n' + this.options.subject + '\n' + this.options.uui + '\n';
                let expiry = Math.floor(new Date().getTime() / 1000) + 10000;
                let tmpUsername = expiry + ':' + this.options.credentialUsername;
                const token = this.hmacSha1(this.options.credentialPassword, data + tmpUsername).__zone_symbol__value + ':' + tmpUsername;
                this.callAuthorization = token;
            } else {			
                const err = {
                    cause: 1,
                    display: "Unallocated (unassigned) number",
                    type: "release",
                };
                this.callAuthorization = err;		
            }
        }
    }

    InitMakeCall(callTo: string, bPrivate: boolean, from: string, reconnectId: string, useCallAuthorization: boolean): void {
        if (callTo !== null) {
            this.options.to = callTo
        }
        if (reconnectId != "") {
            this.options.to = "x-reconnect:" + reconnectId
        }
        if (from !== null) {
            this.options.from = from
        }
        this.setPrivate(bPrivate)
        if (bPrivate) {
            this.bPrivateOutboundCall = true
            for (let i = 0; i < this.confRooms.length; i++) {
                if (this.confRooms[i].Active) {
                    this.confRooms[i].Active = false
                }
                if (this.confRooms[i].data.Room === callTo) {
                    this.confRooms[i].Active = true
                }
            }
            if (this.isRegistered) {
                this._unregisterCall();
                this.shouldBeRegistered = true;
            }
        }
        //ngmediaRTC.config.iceServers.turnServers = "turn.ngmedia.live;transport=udp"
        /*if (reconnectId !== "") {
            this.call = ngmediaRTC.createCall({ to: this.options.to, domain: "*", audio: this.options.audio, video: this.options.video, reconnectId: reconnectId})
        } else {
            this.call = ngmediaRTC.createCall({ to: this.options.to, domain: "*", audio: this.options.audio, video: this.options.video, })
        }*/
        let callAuth = (useCallAuthorization == true ? this.callAuthorization : undefined)
        let callToken = ""
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            callToken = this.options.token.toString()
        }
        this.call = ngmediaRTC.createCall({ to: this.options.to, from: this.options.from, domain: "*", audio: this.options.audio, video: this.options.video, token: callToken, authorization: callAuth})
        
        this.call.oncallstatechanged         = this.notifyOnCallStateChanged.bind(this)
        this.call.onconnected                = this.notifyOnCallConnected.bind(this)
        this.call.ondisconnected             = this.notifyOnCallDisconnected.bind(this)
        this.call.onmediastreamadded         = this.notifyOnMediaStreamAdded.bind(this)
        this.call.onstreamadded              = this.notifyOnStreamAdded.bind(this)
        this.call.onstreamremoved            = this.notifyOnStreamRemoved.bind(this)
        this.call.onparticipantadded         = this.notifyOnParticipantAdded.bind(this)
        this.call.onparticipantupdated       = this.notifyOnParticipantUpdated.bind(this)
        this.call.onparticipantremoved       = this.notifyOnParticipantRemoved.bind(this)
        this.call.onmessage                  = this.notifyOnMessage.bind(this)
        this.call.onmessagedeliveryprogress  = this.notifyOnMessageDeliveryProgress.bind(this)
        this.call.onmessagedatachannel       = this.notifyOnMessageDataChannel.bind(this)

        this.call.localUrl              = (this.bPrivate === true ? this.options.from : "anonymous")
        this.call.localName             = this.options.fromName;
        this.call.remoteUrl             = this.options.to;
        this.call.remoteName            = this.options.toName;
        this.call.subject               = this.options.subject
        this.call.uui                   = this.options.uui;
        this.call.authorization         = this.callAuthorization;


        this._makeCall()
        return this.call
    }

    readOfflineMessage(id: string): void {
        /*let params = {
            isRead: true
        }
        
        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/user/ws/end_user_messages/' + id
        } else {
            url = '/ngmconf/user/ws/end_user_messages' + id
        }

        return this.http.patch<any>(url, params)*/
        
        let baseUrl = "/end_user_messages/"
        
        if (this.websocket !== undefined) {
            let params = {
                method: "PATCH",
                path: baseUrl + id,
                body: {
                    IsRead: true
                },
                sentByVizu: true
            }
            this.sendMsg(params)
        }
    }

    async ChangeUserSetting(userId: string, data: any) {
        /*
        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/user/ws-noauth/end_users/'
        } else {
            url = '/ngmconf/user/ws-noauth/end_users/'
        }
        return this.http.put(url + user.id, user)
        */

        let baseUrl = "/end_users"
        
        if (this.websocket !== undefined) {
            let params = {
                method: "PATCH",
                path: baseUrl + "/" + userId,
                body: data,
            }
            this.sendMsg(params)
        }
    }

    sendOfflineMessageHttp(url: string, params: any): Observable<any> {
        return this.http.post<any>(url, params,)
    }

    deleteOfflineMessageHttp(url: string, params: any): Observable<any> {
        return this.http.delete<any>(url, params)
    }

    sendOfflineMessage(toMessage: string, contentMessage: string, contact: string): void {
        let params: any = {
            To: toMessage,
            Content: contentMessage,
            Contact: contact
        }
        let baseUrl = "/end_user_messages"
        let url = undefined

        if (this.options.fromName && this.options.fromName != "") {
            params.FromName = this.options.fromName;
        } else if (this.privRoom.Name && this.privRoom.Name != "") {
            params.FromName = this.privRoom.Name;
        }

        if (this.websocket !== undefined) {
            let paramsBackup = params
            params = {
                method: "POST",
                path: baseUrl,
                body: paramsBackup,
                sentByVizu: true
            }
            this.sendMsg(params)
        } else {
            if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
                url = '/' + this.ngmsUrl.Url + '/user/ws-noauth' + baseUrl + '?userinfo=anonymous:anonymous'
            } else {
                url = '/ngmconf/user/ws-noauth' + baseUrl + '?userinfo=anonymous:anonymous'
            }
            this.sendOfflineMessageHttp(url, params)
            .subscribe({
                next: (success) => {
                    console.log("SUCCESS sending message: " + contentMessage + " / " + contact)
                },
                error: (e) => {
                    console.log("ERROR sending message: " + contentMessage + " / " + contact)
                },
            })
        }
    }

    deleteOfflineMessage(messageId: string) {
        let baseUrl = "/end_user_messages/" + messageId
        let url = undefined
        let params: any = {}
        if (this.websocket !== undefined) {
            params = {
                method: "DELETE",
                path: baseUrl,
                body: {},
                sentByVizu: true
            }
            this.sendMsg(params)
        } else {
            if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
                url = '/' + this.ngmsUrl.Url + '/user/ws' + baseUrl
            } else {
                url = '/ngmconf/user/ws' + baseUrl
            }
            this.deleteOfflineMessageHttp(url, params)
            .subscribe({
                next: (success) => {
                    console.log("SUCCESS delete message: " + messageId)
                },
                error: (e) => {
                    console.log("ERROR delete message: " + messageId)
                },
            })
        }
    }

    deleteOfflineMessages(toMessage: string, displayNameMessage: string): void {
        if (toMessage === "Anonymous" || toMessage === "anonymous") {
            for (let message of this.end_user_messages) {
                if ((message.From == toMessage && message.FromName == displayNameMessage && message.To == this.privRoom.Username)) {
                    this.deleteOfflineMessage(message.id);
                }
            }
        } else {
            for (let message of this.end_user_messages) {
                if ((message.From == toMessage && message.To == this.privRoom.Username) || (message.From == this.privRoom.Username && message.To == toMessage)) {
                    this.deleteOfflineMessage(message.id);
                }
            }
        }
    }

    sendMessage(options: any): void {
        if (this.options.from === 'anonymous') {
            this.call.sendMessage(options, this.bUseDataChannel)
        } else {
            if (this.bWebsocketConnected) {
                this.call.sendMessage(options, this.bUseDataChannel)
            }
        }
    }

    sendPingMessage(): void {
        if (this.bWebsocketConnected) {
            this.sendMessage({type: "ping", content: "ping"})
        }
    }

    sendErrorMessage(error: any): Observable<any> {
        //console.log("_____SEND ERROR " + error)
        let message = {
            FriendlyName: "",
            Type: "Error",
            Content: error.toString()
        }
        if (this.options.from === 'anonymous') {
            message.FriendlyName = this.options.fromName.charAt(0).toUpperCase() + this.options.fromName.slice(1)
        } else {
            message.FriendlyName = this.options.from.charAt(0).toUpperCase() + this.options.from.slice(1)
        }
        let options = { Severity: 3, AppName: "VizuLive", Message: message }

        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/public/ws-noauth/client_logs'
        } else {
            url = '/ngmconf/public/ws-noauth/client_logs'
        }

        return this.http.post<any>(url, options)
    }

    sendFeedbackMessage(feedback: any): Observable<any> {
        let message = {
            FriendlyName: "",
            Type: "Feedback",
            Content: feedback
        }
        if (this.options.from === 'anonymous') {
            message.FriendlyName = this.options.fromName.charAt(0).toUpperCase() + this.options.fromName.slice(1)
        } else {
            message.FriendlyName = this.options.from.charAt(0).toUpperCase() + this.options.from.slice(1)
        }
        let options = { Severity: 3, AppName: "VizuLive", Message: message }

        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/public/ws-noauth/client_logs'
        } else {
            url = '/ngmconf/public/ws-noauth/client_logs'
        }

        return this.http.post<any>(url, options)
    }

    sendClickMessage(info: any): Observable<any> {
        //console.log("_____SEND ERROR " + error)
        let message = {
            FriendlyName: "",
            Type: "Click",
            Content: info.toString()
        }
        if (this.options.from === 'anonymous') {
            message.FriendlyName = this.options.fromName.charAt(0).toUpperCase() + this.options.fromName.slice(1)
        } else {
            message.FriendlyName = this.options.from.charAt(0).toUpperCase() + this.options.from.slice(1)
        }
        let options = { Severity: 6, AppName: "VizuLive", Message: message }

        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/public/ws-noauth/client_logs'
        } else {
            url = '/ngmconf/public/ws-noauth/client_logs'
        }

        return this.http.post<any>(url, options)
    }

    sendRatingMessage(ratingMessage: any): Observable<any> {
        let message = {
            FriendlyName: "",
            Type: "Rating",
            Content: ratingMessage
        }
        if (this.options.from === 'anonymous') {
            message.FriendlyName = this.options.fromName.charAt(0).toUpperCase() + this.options.fromName.slice(1)
        } else {
            message.FriendlyName = this.options.from.charAt(0).toUpperCase() + this.options.from.slice(1)
        }
        let options = { Severity: 5, AppName: "VizuLive", Message: message }

        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/public/ws-noauth/client_logs'
        } else {
            url = '/ngmconf/public/ws-noauth/client_logs'
        }

        return this.http.post<any>(url, options)
    }

    //return this.http.get<any>('https://jsonplaceholder.typicode.com/users');
    //return this.http.post<any>("/ngmconf/public/ws-noauth/client_logs", options)

    sendLogMessage(): Observable<any> {
        let message = {
            FriendlyName: "",
            Type: "Devices",
            Content: this.deviceInfos
        }
        if (this.options.from === 'anonymous') {
            message.FriendlyName = this.options.fromName
        } else {
            message.FriendlyName = this.options.from
        }
        let options = { Severity: 7, AppName: "VizuLive", Message: message }

        let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/public/ws-noauth/client_logs'
        } else {
            url = '/ngmconf/public/ws-noauth/client_logs'
        }

        return this.http.post<any>(url, options)
    }
    
    sendMsg(msg: any): void {
        if (this.websocket !== undefined) {
            console.log("WORKING SEND MESSAGE")
            this.websocket.send(JSON.stringify(msg))
        } else {
            console.log("WARNING: Trying to send a message while the websocket isn't connected")
        }
    }

    AnswerLockedAttendee(attendee: any): boolean {
        /*let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/user/ws'
        } else {
            url = '/ngmconf/user/ws'
        }

        if (attendee.Path !== undefined) {
            return this.http.post<any>(url + attendee.Path + "/answer", {})
        }*/
       
        let bSuccess = false
        if (attendee.Path !== undefined) {
            let params = {
                method: "POST",
                path: attendee.Path + "/answer",
                body: {},
            }
            this.sendMsg(params)
            bSuccess = true
        } else {
            bSuccess = false
        }
        return bSuccess
    }

    RejectLockedAttendeeWithMessage(attendee: any, message: string): boolean { //Observable<any>
        let bSuccess = false
        if (attendee.Path !== undefined) {
            let params = {
                method: "POST",
                path: attendee.Path + "/clear",
                body: { subject: message },
            }
            this.sendMsg(params)
            bSuccess = true
        } else {
            bSuccess = false
        }
        return bSuccess
    }

    RejectLockedAttendee(attendee: any): boolean { //Observable<any>
        /*let url = undefined
        if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
            url = '/' + this.ngmsUrl.Url + '/user/ws'
        } else {
            url = '/ngmconf/user/ws'
        }

        if (attendee.Path !== undefined) {
            return this.http.post<any>(url + attendee.Path + "/clear", {})
        }*/
        let bSuccess = false
        if (attendee.Path !== undefined) {
            let params = {
                method: "POST",
                path: attendee.Path + "/clear",
                body: {},
            }
            this.sendMsg(params)
            bSuccess = true
        } else {
            bSuccess = false
        }
        return bSuccess
    }
    
    sendLaserState(options: any): void {
        this.call.sendMessage(options, this.bUseDataChannel)
    }

    /*SendPDFZoom(scale: number): void {
        this.send('pdf_zoom', {
            Scale: scale,
        })
    }*/

    /*SendPDFScroll(x: number, y: number, w: number, h: number): void {
        this.send('pdf_scroll', {
            X: x,
            Y: y,
            W: w,
            H: h,
        })
    }*/

    setLocalStreamCapabilities() {
        if (this.LocalStream !== undefined) {
            if (this.LocalStream.getAudioTracks().length > 0) {
                this.LocalStreamAudio = true
            }
            if (this.LocalStream.getVideoTracks().length > 0) {
                this.LocalStreamVideo = true
            }
        }
    }

    async StartScreenShare(tagId: any): Promise<any> {
        ngmediaRTC.setLocalScreenSharingMediaTag(tagId)
        let myThis = this
        return new Promise((resolve: any, reject: any) => {
             myThis.call.startScreenSharing()
            .then(
                function(success: any) {
                    console.log("-------SCREENSHARING SUCCCESS RTC.SERVICE-------- ")
                    myThis.ScreenStream = ngmediaRTC.screenStream
                    resolve();
                    //return myThis.ScreenStream
                },
                function(error: any) {
                    console.log("-------SCREENSHARING ERROR RTC.SERVICE: "+ error +"-------- ")
                    reject(error);
                    //return myThis.ScreenStream
                }
            );
        })
    }

    StopScreenShare(): void {
        this.call.stopScreenSharing()
    }

    IsWebRTCSupported(): boolean {
        if (navigator.mediaDevices == undefined) {
            return false
        }

        return true
    }

    getConfidenceLevel(token: string, recaptchaAction: string): Observable<number> {
        const url = '/ngmsdata/public/ws-noauth/captcha';
        const options = {
            token: token,
            recaptchaAction: recaptchaAction
        }
        return this.http.post<number>(url, options)
    }
}

async function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
            resolve(undefined)
        }, duration)
    })
}