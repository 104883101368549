import { Component, Input, OnInit } from '@angular/core'
import { EmitterService } from '../../services/emitter.service';
import { DomainData } from '../../domain/domain'
import { CustomerService } from '../../services/customer.service'

@Component({
    selector: 'app-vizu-header',
    templateUrl: './vizu-header.component.html',
    styleUrls: ['./vizu-header.component.scss'],
})
export class VizuHeaderComponent implements OnInit {
    @Input() Public: boolean
    DomainData: DomainData
    constructor(
        private NgmsService: EmitterService,
        private customerService: CustomerService,
    )
    {
        this.NgmsService.EmitNgmsSettings$.subscribe({
            next: this.handleNewEmittedMsg.bind(this)
        })
    }

    domain = {
        name: "",
    }

    bLogoFullPath = false

    handleNewEmittedMsg(data: any){
        let messageRcv = data.message;
        let dataRcv = data.data;
        if (messageRcv == "OnDomainData") {
            this.ondomaindata(dataRcv)
        }
    }

    ngOnInit() {
        if (this.Public) {
            this.domain.name = window.location.host

            let pathname = window.location.pathname.split( '/' );
            let Room = ""
            if (pathname[1] !== undefined) {
                Room = pathname[1]
            }
            
            this.customerService.GetDomainPublicTemplate(Room)
            .subscribe(data => {
                let domainData: any = data
                this.DomainData = {
                    Name: data.Name,
                    SharedUsers: data.SharedUsers,
                    CustomData: (domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData))
                };
                if ((this.DomainData.CustomData.LogoURI.startsWith("https://")) || (this.DomainData.CustomData.LogoURI.startsWith("http://")) || this.DomainData.CustomData.LogoURI.startsWith("/assets/")) {
                    this.bLogoFullPath = true;
                }
            });
        }
    }

    ondomaindata(DomainData: any) {
        this.DomainData = DomainData
        let fullDomain = window.location.host.split('.');
        this.DomainData.Name = fullDomain[0]
        if ((this.DomainData.CustomData.LogoURI.startsWith("https://")) || (this.DomainData.CustomData.LogoURI.startsWith("http://")) || this.DomainData.CustomData.LogoURI.startsWith("/assets/")) {
            this.bLogoFullPath = true;
        }
    }

    setDomainHeader() {
        let style = undefined;
        if (this.DomainData !== undefined) {
            style = {
                //x"background": "url('/static/assets/companies/" + this.domainTemplate.Name + "/"+this.domainTemplate.ImgBackground+"') no-repeat center 75%",
                "background-color": this.DomainData.CustomData.ColorRoomHeader,
            };
        } else {
            style=""
        }
        return style;
    }
}
