import { Component, OnInit, Input, ViewChild } from '@angular/core'

import { AuthService } from '../../services/auth.service'
import { RtcService } from '../../services/rtc.service'
import { CustomerService } from '../../services/customer.service'

import { RoomConfig, Appointment } from '../../domain/domain'
import { EmitterService } from 'src/app/services/emitter.service'
import { NotificationComponent } from '../notification/notification.component'

@Component({
    selector: 'app-vizu-account-config',
    templateUrl: './vizu-account-config.component.html',
    styleUrls: ['./vizu-account-config.component.scss'],
})
export class VizuAccountConfigComponent implements OnInit {
    @Input() errorMonitor: object
    @ViewChild('notificationComponent') notificationComponent!: NotificationComponent;
    Loading = true
    Error = false

    Room: Appointment

    LogoURIInput: string = ""
    bLogoURIFailed: boolean = false
    bFirstLetterTyped = true
    Params = {
        ID: '',
        Name: '',
        Room: '',
        LogoURI: '',
        Email: {
            Password: '',
            Email: '',
        },
        Password: {
            Strength: 0,
            Old: '',
            New: '',
        },
        Config: new RoomConfig(),
    }

    SubmittingName = false
    SubmittingRoom = false
    SubmittingLogoURI = false
    SmallRoom = false
    SubmittingEmail = false
    ErrorEmail = false
    SubmittingPassword = false
    ErrorPassword = false
    ErrorConfig = false
    SubmittingConfig = false

    specialRegex = new RegExp("[^A-Za-z0-9]"); 
    upperRegex = new RegExp("[A-Z]");
    lowerRegex = new RegExp("[a-z]"); 
    numberRegex = new RegExp("[0-9]"); 
    lengthRegex = new RegExp(".{8,}");

    notifications: any = {
        showSuccessResult: false,
        showFailedResult: false
    }

    constructor(
        private authService: AuthService,
        private rtcService: RtcService,
        private customerService: CustomerService,
        private NgmsService: EmitterService,
    ) {
        this.NgmsService.EmitNgmsSettings$.subscribe({
            next: this.handleNewEmittedMsg.bind(this)
        })
    }

    handleNewEmittedMsg(data: any) {
        let messageRcv = data.message;
        if (messageRcv == "OnMeData") {
            this.notificationComponent.displayNotification('showSuccessResult');
        }
    }

    ngOnInit(): void {
        //(success)="successLoading()" (error)="errorLoading()"
        this.Load()

        this.rtcService.notifyOnSelfRoomEditError = this.notifyonselfroomediterror.bind(this)
        this.rtcService.notifyOnSelfNameEditError = this.notifyonselfnameediterror.bind(this)
        this.rtcService.notifyOnSelfLogoURIEditError = this.notifyonselflogouriediterror.bind(this)
    }

    Load(): void {
        this.Loading = true
        this.customerService.GetCustomer()
        .subscribe({
            next: (customerData) => {
                const customer = customerData
                this.Params.ID = customerData.id
                this.Params.Name = customer.Name
                this.Params.Room = customer.Room
                this.LogoURIInput = customer.LogoURI
                this.Params.LogoURI = customer.LogoURI
            },
            error: () => {
                this.Error = true
            }
        })
        this.Loading = false
    }

    userTyping = function() {
        var timer: any = undefined;
        return function(callback: any, ms: number){
            if (timer !== undefined) {
                clearTimeout (timer);
            }
            timer = setTimeout(callback, ms);
        } 
    }

    PreviewLogoURI() {
        let myThis = this
        let img: any = document.getElementById("trackedImg")
        if (img !== undefined) {
            myThis.Params.LogoURI = this.LogoURIInput
            img.addEventListener("load", function() {
                myThis.bLogoURIFailed = false
            })
            img.addEventListener("error", function() {
                myThis.bLogoURIFailed = true
                myThis.Params.LogoURI = ""
            })
        }
    }

    async ChangeLogoURI(): Promise<void> {
        this.SubmittingLogoURI = true
        let data = {
            LogoURI: this.Params.LogoURI
        }
        await this.rtcService.ChangeUserSetting(this.Params.ID, data)
        this.SubmittingLogoURI = false
    }

    async ChangeName(): Promise<void> {
        this.SubmittingName = true
        let data = {
            Name: this.Params.Name,
        }
        await this.rtcService.ChangeUserSetting(this.Params.ID, data)
        this.SubmittingName = false
    }

    async ChangeRoom(): Promise<void> {
        this.SmallRoom = false
        let data = {
            Room: this.Params.Room.toLowerCase(),
        }
        if (this.Params.Room.length >= 3) {
            this.SubmittingRoom = true
            await this.rtcService.ChangeUserSetting(this.Params.ID, data)
            this.SubmittingRoom = false
        } else {
            this.SmallRoom = true
        }
    }

    /*async ChangeEmail(): Promise<void> {
        let data = {
            Email: this.Params.Email.Email,
        }
        this.SubmittingEmail = true
        try {
            await this.rtcService.ChangeUserSetting(this.Params.ID, data)
            this.Params.Email.Email = ''
            this.Params.Email.Password = ''
            this.ErrorEmail = false
        } catch (error) {
            this.ErrorEmail = true
        }
        this.SubmittingEmail = false
    }*/

    CheckStrength(newPwd: any): number {
        let currentStrength = 0
  
        if (this.hasRegex(newPwd, this.specialRegex)) {
            currentStrength += 20
        }
        if (this.hasRegex(newPwd, this.upperRegex)) {
            currentStrength += 20
        }
        if (this.hasRegex(newPwd, this.lowerRegex)) {
            currentStrength += 20
        }
        if (this.hasRegex(newPwd, this.numberRegex)) {
            currentStrength += 20
        }
        if (this.hasRegex(newPwd, this.lengthRegex)) {
            currentStrength += 20
        }
  
        this.Params.Password.Strength = currentStrength
        return currentStrength;
    }
  
    hasRegex(str: string, regex: RegExp): boolean {
      return regex.test(str);
    }

    async ChangePassword(): Promise<void> {
        let data = {
            OldPassword: this.Params.Password.Old,
            NewPassword: this.Params.Password.New
        }
        this.SubmittingPassword = true
        try {
            await this.rtcService.ChangeUserSetting(this.Params.ID, data)
            this.Params.Password.Old = ''
            this.Params.Password.New = ''
            this.ErrorPassword = false
        } catch (error) {
            this.ErrorPassword = true
        }
        this.SubmittingPassword = false
    }

    /*async ChangeConfig(): Promise<void> {
        this.SubmittingConfig = true
        try {
            await this.customerService.SetConfig(this.Params.Config.String())
            this.ErrorPassword = false
        } catch (error) {
            this.ErrorConfig = true
        }
        this.SubmittingConfig = false
    }*/

    notifyonselfroomediterror(event: any) {
        this.notificationComponent.displayNotification('showFailedResult');
    }

    notifyonselfnameediterror(event: any) {
        this.notificationComponent.displayNotification('showFailedResult');
    }

    notifyonselflogouriediterror(event: any) {
        this.notificationComponent.displayNotification('showFailedResult');
    }
}
