<h1
  *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig"
  class="oranienbaum-regular has-text-left is-size-3-fullhd is-size-4-desktop is-size-5-touch mb-4 text-custom-color"
>
  {{ "shell.room.title" | translate }}
</h1>
<div *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig">
  <div class="shared-room-display">
    <div *ngFor="let Room of Rooms; index as i" class="panel mr-5 mb-5">
      <h1 class="title has-text-centered poppins-semibold is-size-5-fullhd is-size-6">
        {{ "shell.room.room" | translate }} {{ Room.data.Name }}
      </h1>
      <div>
        <div class="flex-column-container room-element">
          <div class="conf-icon-wrapper">
            <app-people-icon [On]="Room.ParticipantsCount > 0" [isTouch]="false"></app-people-icon>
          </div>
          <span *ngIf="Room.ParticipantsCount > 0" class="enter-button-out stack-count-to-image">{{ Room.ParticipantsCount }}</span>
          <p *ngIf="Room.ParticipantsCount == 0" class="subtitle has-text-left is-7 mt-2 text-light">{{ "shell.room.free" | translate }}</p>
          <p *ngIf="Room.ParticipantsCount > 0" class="subtitle has-text-left is-7 mt-2 text-light">{{ "shell.room.busy" | translate }}</p>
        </div>
        <input *ngIf="Room != undefined" type="text" id="{{ Room.data.Room }}" class="link-content" />
        <div class="flex-row-container" style="place-content: center">
          <button
            class="button is-normal is-blue tooltip"
            (click)="sendClickInfoMessage('CopyLinkPublic'); CopyLink(Room.data.Room)"
            [ngStyle]="setDomainMainColor()"
          >
            <span class="icon">
              <svg width="30" height="30" class="pointer svg-white">
                <image xlink:href="/assets/icons/copy.svg" width="30" height="30" />
              </svg>
              <span class="tooltiptext">{{ "shell.room.copy_link" | translate }}</span>
            </span>
          </button>

          <div
            *ngIf="Room !== undefined && !mobileDevice"
            id="{{ Room.data.Room }}-invite"
            class="room-element dropdown is-hoverable"
            [href]="Room.Uri"
            target="_blank"
          >
            <button
              *ngIf="Room !== undefined && !mobileDevice"
              class="button is-normal is-blue"
              (click)="sendClickInfoMessage('SmsPrivateLink')"
            >
              <span class="icon">
                <svg width="30" height="30" class="pointer svg-white">
                  <image xlink:href="/assets/icons/share-nodes.svg" width="30" height="30" />
                </svg>
              </span>
            </button>

            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                  *ngIf="Room !== undefined && mobileDevice"
                  id="{{ Customer.Room }}-invite"
                  class="dropdown-item"
                  [href]="Room.Uri"
                  target="_blank"
                >
                  {{ "shell.room.send_sms" | translate }}
                </a>
                <a
                  *ngIf="Room !== undefined && !mobileDevice"
                  class="dropdown-item"
                  (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeLink(Room)"
                >
                  {{ "shell.room.qrcode_link" | translate }}
                </a>
                <a
                  *ngIf="Room !== undefined && !mobileDevice"
                  class="dropdown-item"
                  (click)="sendClickInfoMessage('QrCodeSmsPrivateLink'); displayQrCodeSms(Room)"
                >
                  {{ "shell.room.qrcode_sms" | translate }}
                </a>
                <a
                  *ngIf="Room !== undefined && !mobileDevice"
                  id="{{ Customer.Room }}-invite"
                  class="dropdown-item"
                  [href]="Room.Uri"
                  target="_blank"
                >
                  {{ "shell.room.send_email" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <button
          *ngIf="!bTelephonyMode"
          class="button is-medium is-danger is-outlined login-button"
          (click)="sendClickInfoMessage('EnterPublicRoom'); EnterOwnRoom(Room.data.Room)"
        >
          <div class="enter-button-base pointer" *ngIf="!waitingToEnter">
            <div class="enter-button-out pointer">
              <div class="enter-button-in pointer">
                <span class="enter-button pointer"></span>
              </div>
            </div>
          </div>
          <b class="button-text pointer" *ngIf="!waitingToEnter">{{ "shell.room.join" | translate }} ?</b>
          <div *ngIf="waitingToEnter">
            <app-loader-points></app-loader-points>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
