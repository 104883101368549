<div *ngIf="messagesRecepient != ''" class="classic-text centered marged-bordered-bottom mb-0">
  <br />
  <i class="fas fa-xl fa-arrow-left pointer" (click)="backMessages()"></i>
  <p class="messages-name" style="display: flex; align-items: center">
    <b class="messages-name">{{ messagesRecepient }}</b>
    <span class="tag is-dark ml-3" *ngIf="messagesTo === 'anonymous'"> ⚠️({{ "shell.room.messages.not_verified" | translate }}) </span>
  </p>
  <i class="fas fa-xl fa-ban pointer" (click)="deleteChatMessages(messagesTo, messagesRecepient)"></i>
</div>

<div *ngIf="messagesRecepient != '' && messagesTo === 'anonymous'" class="is-size-7 has-text-centered" style="background-color: bisque">
  {{ "shell.room.messages.unknown_user_verbose" | translate }}
</div>

<div #messageContainer class="main-container">
  <ng-container *ngIf="messagesRecepient == ''">
    <div
      class="contact-card-row pointer"
      *ngFor="let Contact of Contacts_and_Anonymous() | collaborator : SearchField"
      (click)="displayMessages(Contact.To, Contact.DisplayName)"
      [ngClass]="!DomainData.SharedUsers && Contact.Type !== 'Anonymous' ? 'hidden' : ''"
    >
      <!-- Hide if not SharedUsers && not anonymous message -->
      <div class="contact-info">
        <div *ngIf="DomainData.SharedUsers && Contact.Type === 'User'">
          <img
            *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''"
            height="80"
            width="80"
            src="{{ Contact.data.LogoURI }}"
            class="profile-custom"
          />
          <img
            *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''"
            height="80"
            width="80"
            src="/assets/icons/profile-default.svg"
          />
        </div>
        <div *ngIf="DomainData.SharedUsers && Contact.Type === 'UserContacts'">
          <img height="80" width="80" src="/assets/icons/profile-default.svg" />
        </div>
        <div *ngIf="Contact.Type === 'Anonymous' && Contact.To != 'anonymous'">
          <img
            *ngIf="Contact.LogoURI != undefined && Contact.LogoURI != ''"
            height="80"
            width="80"
            src="{{ Contact.LogoURI }}"
            class="profile-custom"
          />
          <img
            *ngIf="Contact.LogoURI == undefined || Contact.LogoURI == ''"
            height="80"
            width="80"
            src="/assets/icons/profile-default.svg"
          />
        </div>
        <div *ngIf="Contact.Type === 'Anonymous' && Contact.To == 'anonymous'">
          <img height="80" width="80" src="/assets/icons/profile-anonymous-default.svg" />
        </div>

        <app-vizu-presence [bMessageView]="true" [Contact]="Contact" [DomainData]="DomainData"></app-vizu-presence>

        <div>
          <p style="display: flex; align-items: center">
            <b class="classic-text">{{ Contact.DisplayName }}</b>
            <span class="tag is-dark ml-3" *ngIf="Contact.Type === 'Anonymous' && Contact.To == 'anonymous'">
              ⚠️({{ "shell.room.messages.not_verified" | translate }})
            </span>
          </p>
          <p *ngIf="!Limitations?.MessagePanelHidden" class="message-preview">
            {{ Contact?.Messages[Contact.Messages.length - 1]?.Content }}
          </p>
          <p *ngIf="Limitations?.MessagePanelHidden" class="message-preview">
            {{ Contact?.Messages[Contact.Messages.length - 1]?.Content | translate }}
          </p>
        </div>
      </div>

      <div *ngIf="Contact?.Messages?.length > 0" style="width: 100%; display: contents">
        <div
          *ngIf="DomainData.SharedUsers || (!DomainData.SharedUsers && Contact.Type === 'Anonymous')"
          class="conf-icon-wrapper contact-messages-count-circle"
          [ngStyle]="{ visibility: Contact.UnreadMessagesNumber !== undefined && Contact.UnreadMessagesNumber > 0 ? 'visible' : 'hidden' }"
        >
          <div class="conf-participant-count">{{ Contact.UnreadMessagesNumber }}</div>
        </div>
        <p class="subtitle has-text-right is-7 text-light" style="min-width: 50px">
          {{ formatTimestamp(Contact?.Messages[Contact?.Messages?.length - 1]?.Timestamp) }}
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="messagesRecepient != ''">
    <ng-container *ngFor="let message of messagesDisplayed; first as isFirst">
      <div *ngIf="isFirst" class="message-timestamp">{{ getHours(message.Timestamp) }}</div>
      <div [class.message-sent]="message.To == messagesTo" [class.message-received]="message.To != messagesTo">
        <div class="from-name">{{ message.FromName }}</div>
        <div style="display: inline-flex">
          <img *ngIf="message.To == messagesTo" src="{{ Customer.LogoURI }}" class="mr-1 chat-logo" />
          <div style="display: block">
            <div *ngIf="!Limitations?.MessagePanelHidden" class="message-text">
              {{ message.Content }}
            </div>

            <div *ngIf="Limitations?.MessagePanelHidden" class="message-text">
              {{ message.Content | translate }}
            </div>
            <div *ngIf="message.ImageData != undefined" class="message-text">
              <img [src]="message.ImageData" />
            </div>
          </div>
          <img *ngIf="message.To != messagesTo" src="{{ getLogoURIContact(message.From) }}" class="ml-1 chat-logo" />
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="chat-input" *ngIf="messagesRecepient != '' && !isAnonymeDisplay">
  <input
    type="text"
    name="ChatText"
    (keydown)="OnKeyDown($event)"
    [(ngModel)]="messageData"
    class="input input-text-bar border-20"
    placeholder="Aa"
  />
  <i class="fa-regular fa-xl fa-face-smile pointer" style="align-content: center; margin-left: 5px" [matMenuTriggerFor]="menu"></i>
  <button class="no-button pointer" (click)="sendClickInfoMessage('Chat-SendMessage'); sendChatMessage()" id="sendMessageSubmit">
    <svg width="30" height="30" class="pointer">
      <image xlink:href="/assets/icons/send.svg" width="30" height="30" />
    </svg>
  </button>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <div class="skin-picker" (click)="$event.stopPropagation()">
        <div></div>
        <div
          *ngFor="let skin of skinTonesKeys"
          class="emoji-item"
          style="place-self: center"
          [ngStyle]="{ border: skinTones[skin] == skinTone ? 'solid 2px hsl(153, 53%, 53%)' : 'none' }"
        >
          <div
            class="square"
            (click)="setSkinTone(skin)"
            [ngStyle]="{
              'background-color':
                skin === 'Light'
                  ? '#FFD7C2'
                  : skin === 'MediumLight'
                  ? '#EEBFAA'
                  : skin === 'Medium'
                  ? '#C68D7B'
                  : skin === 'MediumDark'
                  ? '#966661'
                  : skin === 'Dark'
                  ? '#573739'
                  : '#FFCC00'
            }"
          ></div>
        </div>
      </div>
      <div class="emoji-picker">
        <div *ngFor="let emoji of emojis" (click)="selectEmoji(emoji)" class="emoji-item">
          {{ emoji }}
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>
