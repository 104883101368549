import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { RtcService } from 'src/app/services/rtc.service';

const cookieName = "cookie_settings";
const cookieDays = 365;

@Component({
  selector: 'app-vizu-cookie',
  templateUrl: './vizu-cookie.component.html',
  styleUrl: './vizu-cookie.component.css'
})
export class VizuCookieComponent implements OnInit {

  @Input() showCookieSettings: boolean;
  @Output() showCookieSettingsChange = new EventEmitter<boolean>();

  @Input() isWhite: boolean = false;

  cookies: any = undefined;

  constructor(private rtc: RtcService) {
    setTimeout(() => {
      this.cookies = {
        necessary: true,
        performance: true
      }
      const str = this.getCookie(cookieName);
      if (str) {
        const params: any = new URLSearchParams(str);
        const obj: any = {};
        for (const [key, value] of params) {
          obj[key] = value;
        }
        this.cookies = obj;
        this.rtc.cookies = this.cookies;
      }
    });
  }

  ngOnInit() {}

  saveCookie() {
    if (this.cookies.performance === false) {
      this.clearVizuLiveData();
    }
    let str = "";
    for (const [key, value] of Object.entries(this.cookies)) {
      str += `&${key}=${value}`;
    }
    str = str.substring(1);
    this.setCookie(cookieName, str, cookieDays);
    this.rtc.cookies = this.cookies;
  }

  cancelCookie() {
    this.showCookieSettings = false;
    this.showCookieSettingsChange.emit(this.showCookieSettings);
    this.cookies = {
      necessary: true,
      performance: true
    }
  }

  getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  setCookie(name: string, value: string, days: number): void {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  clearVizuLiveData() {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.startsWith('VizuLive\\')) {
        localStorage.removeItem(key);
      }
    });
  }
}
