import { Injectable } from '@angular/core'

import { Subject } from 'rxjs'

export type AppMouseEventType = 'up' | 'down' | 'move'

export interface AppMouseEvent {
    Type: AppMouseEventType
}

@Injectable({
    providedIn: 'root'
})
export class MouseService {
    MouseEvents = new Subject<AppMouseEvent>()
    X = 0
    Y = 0

    constructor() {
        document.body.addEventListener('mousedown', (event) => {
            this.MouseEvents.next({
                Type: 'down',
            })
        })
        document.body.addEventListener('touchstart', (event) => {
            const touch = event.touches[0]
            this.X = touch.clientX
            this.Y = touch.clientY

            this.MouseEvents.next({
                Type: 'down',
            })
        })

        document.body.addEventListener('mouseup', (event) => {
            this.MouseEvents.next({
                Type: 'up',
            })
        })
        document.body.addEventListener('touchend', (event) => {
            this.MouseEvents.next({
                Type: 'up',
            })
        })

        document.body.addEventListener('mousemove', (event) => {
            this.X = event.clientX
            this.Y = event.clientY

            this.MouseEvents.next({
                Type: 'move',
            })
        })
        document.body.addEventListener('touchmove', (event) => {
            const touch = event.touches[0]
            this.X = touch.clientX
            this.Y = touch.clientY

            this.MouseEvents.next({
                Type: 'move',
            })
        })
    }
}
