<div class="header-container" [ngStyle]="setDomainHeader()">
    <!--<a href="/" *ngIf="DomainData != undefined">
        <img class="logo" src='{{ DomainData.CustomData.LogoURI }}' *ngIf="DomainData.CustomData != undefined && bLogoFullPath" />
        <img class="logo" src='{{ "/assets/companies/" + DomainData.Name + "/" + DomainData.CustomData.LogoURI }}' *ngIf="DomainData.CustomData != undefined && !bLogoFullPath" />
    </a>-->
    <div *ngIf="DomainData != undefined" class="flex-reverse-container">
        <p   *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI === undefined || DomainData.CustomData.LogoURI === '')" class="text-logo">{{ DomainData.CustomData.FriendlyName }}</p>
        <img *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI !== undefined && DomainData.CustomData.LogoURI !== '') && bLogoFullPath" class="logo" src='{{ DomainData.CustomData.LogoURI }}' />
        <img *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI !== undefined && DomainData.CustomData.LogoURI !== '') && !bLogoFullPath" class="logo" src='{{ "/assets/companies/" + DomainData.Name + "/" + DomainData.CustomData.LogoURI }}' />
    </div>
    <ng-content></ng-content>
</div>
