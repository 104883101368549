<div class="full-height landing-container" *ngIf="DomainData !== undefined" [ngStyle]="setImageBackground()">
    <div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>
    <div class="full-height">
        <div class="full-height landing-content">
            <div class="landing-center">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
<app-loading-screen *ngIf="DomainData === undefined">
</app-loading-screen>