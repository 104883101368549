<app-loading-menu *ngIf="Loading"></app-loading-menu>
<div *ngIf="Error && !Loading" class="error-reload">
    <div class="error-reload-text">{{ 'common.error' | translate }}</div>
    <button type="button" (click)="reload()" class="button is-danger">{{ 'common.reload' | translate }}</button>
</div>

<!--<nav *ngIf="!Loading && !Error" class="navbar" role="navigation" aria-label="dropdown navigation" style="border-radius: 5px; padding: 1em;">
    <div class="navbar-brand">
        <a (click)="toggleNavbar()" role="button" #navBurger class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
    </div>
    <div class="navbar-menu" #navMenu>
      <div class="navbar-start">
        <div class="buttons">
            <button type="button" class="button is-danger" (click)="bDisplay = 'domains'; Cancel();" [ngClass]="{'is-outlined': bDisplay != 'domains'}">Companies</button>
            <button type="button" class="button is-danger" (click)="bDisplay = 'users'; Cancel();" [ngClass]="{'is-outlined': bDisplay != 'users'}">Users</button>
            <button type="button" class="button is-danger" (click)="bDisplay = 'rooms'; Cancel();" [ngClass]="{'is-outlined': bDisplay != 'rooms'}">Rooms</button>
        </div>
      </div>
  
      <div class="navbar-end">
        <div [class.is_hidden]="(bDisplay == 'domains' && domainsViewState != 'view') || (bDisplay == 'users' && usersViewState != 'view') || (bDisplay == 'rooms' && roomsViewState != 'view')" style="display: contents;">
            <label style="align-self: center; margin: 0 1em;">Company :</label>
            <p class="control has-icons-left" style="align-self: center;">
                <select class="input" [(ngModel)]="domainFilter" name="RoomDomain" id="SelectRoomDomain">
                    <option value="" selected>All</option>
                    <option *ngFor="let Domain of Domains;let i=index" value="{{Domain.id}}">
                        {{ Domain.Name }}
                    </option>
                </select>
                <span class="icon is-small is-left">
                    <i class="fas fa-building"></i>
                </span>

                <span class="has-text-danger" *ngIf="required['DomainId']">
                    error
                </span>
            </p>
        </div>
      </div>
    </div>
</nav>-->

<nav *ngIf="!Loading && !Error" class="navbar border-top is-hidden-desktop" role="navigation" aria-label="dropdown navigation">
    <div class="navbar-brand">
        <a (click)="toggleNavbar()" role="button" #navBurger class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
    </div>
    <div class="navbar-menu" #navMenu>
      <div class="navbar-start">
        <div class="buttons" style="justify-content: center !important;">
            <button type="button" class="button is-danger" (click)="changeAdminView('domains')" [ngClass]="{'is-outlined': bDisplay != 'domains'}">Companies</button>
            <button type="button" class="button is-danger" (click)="changeAdminView('users')" [ngClass]="{'is-outlined': bDisplay != 'users'}">Users</button>
            <button type="button" class="button is-danger" (click)="changeAdminView('domains')" [ngClass]="{'is-outlined': bDisplay != 'rooms'}">Rooms</button>
        </div>
      </div>
    </div>
</nav>
<div class="columns box border-top" id="admin-box-container" [ngStyle]="setHeight()" (window:resize)="onResize($event)">
    <div class="column is-one-fifth left-menu is-hidden-touch">
        <aside *ngIf="!Loading && !Error" class="menu" style="border-radius: 5px; padding: 1em;">
            <!--<p class="menu-label">
                Account
            </p>
            <ul class="menu-list">
                <li><a>Settings</a></li>
            </ul>-->
            <p class="menu-label">
                Administration
            </p>
            <ul class="menu-list">
                <li (click)="changeAdminView('domains')"><a [ngClass]="{'is-active': bDisplay == 'domains'}">Companies</a></li>
                <li (click)="changeAdminView('users')"><a [ngClass]="{'is-active': bDisplay == 'users'}">Users</a></li>
                <li (click)="changeAdminView('rooms')"><a [ngClass]="{'is-active': bDisplay == 'rooms'}">Rooms</a></li>
                <!--<li><a>Close</a></li>-->
            </ul>
        </aside>
    </div>

    <div class="column">
        <ng-container *ngIf="!Loading && !Error">
            <div *ngIf="(bDisplay == 'domains' && domainsViewState == 'view' && isServiceProvider) || (bDisplay == 'users' && usersViewState == 'view' && isServiceProvider) || (bDisplay == 'rooms' && roomsViewState == 'view' && isServiceProvider)">
                <label style="align-self: center; margin: 0 1em;">Company filter:</label>
                <p class="control has-icons-left" style="align-self: center;">
                    <select class="input" [(ngModel)]="domainFilter" (change)="domainFilterChanged()" name="RoomDomain" id="SelectRoomDomain">
                        <option *ngFor="let Domain of Domains;let i=index" value="{{Domain.id}}" [class.option-red]="!Domain.SharedUsers" [class.option-green]="Domain.SharedUsers">
                            {{ Domain.Name }}
                        </option>
                    </select>
                    <span class="icon is-small is-left">
                        <i class="fas fa-building"></i>
                    </span>
                    
                    <span class="has-text-danger" *ngIf="required['DomainId']">
                        error
                    </span>
                </p>
            </div>
            <br>
            <span style="color: red;">{{error}}</span>

            <!-- NEW ROOMS BOX-->

            <div class="form-box" *ngIf="roomsViewState == 'view' && bDisplay == 'rooms' && isServiceProvider && Rooms != undefined">
                <div class="title">
                    Rooms
                </div>
                <div class="field">
                    <div class="control">
                        <button type="button" (click)="roomsViewState = 'add'; roomInfos.DomainId = domainFilter;" [class.is-loading]="CreatingNewUser" class="button is-danger">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="field" *ngIf="Rooms.length == 0">
                    No Rooms
                </div>
                <div class="field" *ngIf="Rooms.length > 0" style="align-items: center">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Room</th>
                                <th>Company</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let Room of Rooms" class="adminTab" (click)="roomInfos = Room; roomsViewState = 'edit'; domainFilter = roomInfos.DomainId;">
                                <ng-container *ngIf="Room.DomainId === domainFilter || (domainFilter == undefined || domainFilter == '')">
                                    <td>{{Room.Name}}</td>
                                    <td>{{Room.Room}}</td>
                                    <td>{{Room.DomainName}}</td>
                                </ng-container>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- NEW ROOMS BOX-->

            <!-- NEW EDIT/ADD ROOMS BOX-->

            <div class="form-box" *ngIf="roomsViewState != 'view'">
                <form class="has-icons-left has-icons-right">
                    <div class="title" *ngIf="roomsViewState == 'edit'">
                        Edit Room
                    </div>
                    <div class="title" *ngIf="roomsViewState == 'add'">
                        New Room
                    </div>
                    <button type="submit" (click)="AddRoom()" *ngIf="roomsViewState == 'add'" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                    <button type="submit" (click)="EditRoom()" *ngIf="roomsViewState == 'edit'" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                    <button (click)="Cancel()" class="button is-danger is-float-right">{{ 'account_config.cancel' | translate }}</button>
                    <br><br>

                    <div class="field">
                        <label>Enabled</label>
                        <p class="control has-icons-left">

                            <label class="on-off">         
                                <input type="checkbox" [(ngModel)]="roomInfos.Enabled" name="enabled">
                                <i></i>
                            </label>
                        </p>
                    </div>

                    <div class="field">
                        <label>Display Name</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input" [(ngModel)]="roomInfos.Name" name="name" placeholder="Name">
                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>

                            <span class="has-text-danger" *ngIf="required['Name']">
                                error
                            </span>

                        </p>
                    </div>

                    <div class="field">
                        <label>Room</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input lower-case" [(ngModel)]="roomInfos.Room" (ngModelChange)="RoomToLowerCase()" name="room" placeholder="Room">
                            <span class="icon is-small is-left">
                                <i class="fas fa-circle"></i>
                            </span>
        
                            <span class="has-text-danger" *ngIf="required['Room']">
                                error
                            </span>
                            
                        </p>
                    </div>

                    <div *ngIf="roomsViewState == 'add'" class="field">
                        <label>Company</label>
                        <p class="control has-icons-left">
                            <select class="input" *ngIf="domainFilter != ''" [(ngModel)]="roomInfos.DomainId" name="RoomDomain" id="SelectRoomDomain" disabled>
                                <option value="" selected>Select...</option>
                                <option *ngFor="let Domain of Domains;let i=index" value="{{Domain.id}}">
                                    {{ Domain.Name }}
                                </option>
                            </select>
                            <select class="input" *ngIf="domainFilter == ''" [(ngModel)]="roomInfos.DomainId" name="RoomDomain" id="SelectRoomDomain">
                                <option value="" selected>Select...</option>
                                <option *ngFor="let Domain of Domains;let i=index" value="{{Domain.id}}">
                                    {{ Domain.Name }}
                                </option>
                            </select>
                            <span class="icon is-small is-left">
                                <i class="fas fa-building"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['DomainId']">
                                error
                            </span>
                        </p>
                    </div>

                    <br>
                    <button (click)="bModalConfirmDelete = true" class="button is-danger is-left bottom-page" *ngIf="roomsViewState == 'edit'">{{ 'account_config.delete' | translate }}</button>
                </form>
            </div>
            <!-- NEW EDIT/ADD ROOMS BOX-->

            <!-- NEW USERS BOX-->

            <div class="form-box" *ngIf="usersViewState == 'view' && bDisplay == 'users' && Users != undefined">
                <div class="title">
                    Users
                </div>
                <div class="field">
                    <div class="control">
                        <button type="button" (click)="usersViewState = 'add'; userInfos.DomainId = domainFilter;" [class.is-loading]="CreatingNewUser" class="button is-danger">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="field" *ngIf="Users.length == 0">
                    No Users
                </div>
                <div class="field" *ngIf="Users.length > 0" style="align-items: center">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Admin</th>
                                <th>Room</th>
                                <th>Email</th>
                                <th>Company</th>
                                <th>FirstName</th>
                                <th>LastName</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let User of Users" class="adminTab" (click)="userInfos = User; usersViewState = 'edit'; domainFilter = userInfos.DomainId; OnEmailChange(userInfos.Email);">
                                <ng-container *ngIf="User.DomainId === domainFilter || (domainFilter == undefined || domainFilter == '')">
                                    <td>{{User.Name}}</td>
                                    <td>
                                        <i *ngIf="User.Administrator" class="fas fa-check"></i>
                                    </td>
                                    <td>{{User.Room}}</td>
                                    <td>{{User.Email}}</td>
                                    <td>{{User.DomainName}}</td>
                                    <td>{{User.FirstName}}</td>
                                    <td>{{User.LastName}}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- NEW USERS BOX-->

            <!-- NEW EDIT/ADD USERS BOX-->

            <div class="form-box" *ngIf="usersViewState != 'view'">
                <form class="has-icons-left has-icons-right">
                    <div class="title" *ngIf="usersViewState == 'edit'">
                        Edit User
                    </div>
                    <div class="title" *ngIf="usersViewState == 'add'">
                        New User
                    </div>
                    <button type="submit" (click)="AddUser()" *ngIf="usersViewState == 'add'" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                    <button type="submit" (click)="EditUser()" *ngIf="usersViewState == 'edit'" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                    <button (click)="Cancel()" class="button is-danger is-float-right">{{ 'account_config.cancel' | translate }}</button>
                    <br><br>

                    <!--<div class="field">
                        <label>Username ({{ 'shell.menu.admin_no_uppercase' | translate }})</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input lower-case" [(ngModel)]="userInfos.Username" (ngModelChange)="OnNameChange('username')" name="username" placeholder="Username">
                            <span class="icon is-small is-left">
                                <i class="fas fa-id-badge"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Username']">
                                error
                            </span>
                        </p>
                    </div>-->

                    <div *ngIf="this.domainSelected?.SharedUsers === false && isServiceProvider" class="field">
                        <label>Subscription Type</label>
                        <!--<mat-radio-group [(ngModel)]="userInfos.SubscriptionType">
                            @for (SubscriptionType of SubscriptionTypes; track SubscriptionType) {
                                <mat-radio-button [value]="SubscriptionType">
                                    {{ SubscriptionType }}
                                </mat-radio-button>                                
                            }
                        </mat-radio-group>-->
                        <br>
                        <div class="form-group">
                            <label *ngFor="let SubscriptionType of SubscriptionTypes; let j = index" class="margin-right-20">
                                <input type="radio" id="subscription-radio-{{SubscriptionType.value}}" [value]="SubscriptionType.value" (change)="onSubscriptionTypeChange(SubscriptionType.value)" [checked]="SubscriptionType.value == userInfos.SubscriptionType">
                                <span>{{SubscriptionType.value}}</span>
                            </label>
                        </div>

                        <span class="has-text-danger" *ngIf="required['SubscriptionType']">
                            error
                        </span>
                    </div>

                    <div *ngIf="isServiceProvider" class="field">
                        <label>Administrator
                            <input type="checkbox" [value]="userInfos.Administrator" (change)="onAdministratorChange()" [checked]="userInfos.Administrator" name="admin-box">
                        </label>
                            
                        <span class="has-text-danger" *ngIf="required['Administrator']">
                            error
                        </span>
                    </div>

                    <div class="field">
                        <label>Email</label>
                        <p class="control has-icons-left">
                            <input *ngIf="validEmail" type="text" class="input lower-case is-success is-outlined" [(ngModel)]="userInfos.Email" (ngModelChange)="OnEmailChange($event)" name="email" placeholder="jhon@doe.com">
                            <input *ngIf="!validEmail" type="text" class="input lower-case is-danger is-outlined" [(ngModel)]="userInfos.Email" (ngModelChange)="OnEmailChange($event)" name="email" placeholder="jhon@doe.com">
                            <span class="icon is-small is-left">
                                <i class="fas fa-envelope"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Email']">
                                error
                            </span>
                        </p>
                    </div>

                    <div class="field">
                        <label>Password</label>
                        <p class="control has-icons-left">
                            <input type="password" class="input" [(ngModel)]="userInfos.Password" name="password" placeholder="Password">
                            <span class="icon is-small is-left">
                                <i class="fas fa-key"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Password']">
                                error
                            </span>
                        </p>
                    </div>

                    <div class="field">
                        <label>Room</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input lower-case" [(ngModel)]="userInfos.Room" (ngModelChange)="UserRoomToLowerCase()" name="room" placeholder="jhon">
                            <span class="icon is-small is-left">
                                <i class="fa-solid fa-person-shelter"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Room']">
                                error
                            </span>
                        </p>
                    </div>

                    <!--<div class="field">
                        <label>First Name</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input" [(ngModel)]="userInfos.FirstName" (ngModelChange)="OnNameChange('first')" name="firstName" placeholder="FirstName">
                            <span class="icon is-small is-left">
                                <i class="fas fa-id-card"></i>
                            </span>
                        
                            <span class="has-text-danger" *ngIf="required['FirstName']">
                                error
                            </span>

                        </p>
                    </div>

                    <div class="field">
                        <label>Last Name</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input" [(ngModel)]="userInfos.LastName" (ngModelChange)="OnNameChange('last')" name="lastName" placeholder="LastName">
                            <span class="icon is-small is-left">
                                <i class="fas fa-id-card"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['LastName']">
                                error
                            </span>
                        </p>
                    </div>-->

                    <div class="field">
                        <label>Display Name</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input" [(ngModel)]="userInfos.Name" (ngModelChange)="OnNameChange('name')"name="name" placeholder="Name">
                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Name']">
                                error
                            </span>
                            
                        </p>
                    </div>

                    <div *ngIf="usersViewState == 'add'" class="field">
                        <label>Company</label>
                        <p class="control has-icons-left">
                            <select class="input" *ngIf="domainFilter != ''"  [(ngModel)]="userInfos.DomainId" name="RoomDomain" id="SelectRoomDomain" disabled>
                                <option value="" selected>Select...</option>
                                <option *ngFor="let Domain of Domains;let i=index" value="{{Domain.id}}">
                                    {{ Domain.Name }}
                                </option>
                            </select>
                            <select class="input" *ngIf="domainFilter == ''"  [(ngModel)]="userInfos.DomainId" name="RoomDomain" id="SelectRoomDomain">
                                <option value="" selected>Select...</option>
                                <option *ngFor="let Domain of Domains;let i=index" value="{{Domain.id}}">
                                    {{ Domain.Name }}
                                </option>
                            </select>
                            <span class="icon is-small is-left">
                                <i class="fas fa-building"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['DomainId']">
                                error
                            </span>
                        </p>
                    </div>
                    <span style="color: red;">{{error}}</span>
        <!--
                    <div class="field">
                        <label>Phone Number</label>
                        <p class="control has-icons-left">
                            <input class="input" type="text" [(ngModel)]="userInfos.PhoneNumber" name="phoneNumber" placeholder="PhoneNumber">
                            <span class="icon is-small is-left">
                                <i class="fas fa-phone"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['PhoneNumber']">
                                error
                            </span>
                            
                        </p>
                    </div>
        -->

                    <!--<div class="field">
                        <p class="control has-icons-left">
                            <select class="input" id="profileId" [(ngModel)]="userInfos.ProfileId" [ngModelOptions]="{standalone: true}">
                                <option value="undefined" disabled selected hidden>Select...</option>
                                <ng-container *ngFor="let end_user_profile of end_user_profiles; index as i">
                                <option *ngIf="end_user_profile.DomainId == userInfos.DomainId" value="{{end_user_profile.id}}">{{end_user_profile.Name}}</option>
                                </ng-container>
                            </select>

                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['ProfileId']">
                                error
                            </span>
                            
                        </p>
                    </div>-->

                    <br>
                    <button (click)="bModalConfirmDelete = true" class="button is-danger is-left bottom-page" *ngIf="usersViewState == 'edit'">{{ 'account_config.delete' | translate }}</button>
                </form>
            </div>
        <!-- NEW EDIT/ADD USERS BOX-->


            <!-- NEW COMPANIES BOX-->

            <div class="form-box" *ngIf="domainsViewState == 'view' && bDisplay == 'domains' && Domains != undefined">
                <div class="title">
                    Companies
                </div>
                <div class="field" *ngIf="isServiceProvider">
                    <div class="control">
                        <button type="button" (click)="domainsViewState = 'add'" [class.is-loading]="CreatingNewUser" class="button is-danger">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="field" *ngIf="Domains.length == 0">
                    No Company
                </div>
                <div class="field" *ngIf="Domains.length > 0" style="align-items: center">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Company URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let Domain of Domains;let i=index" class="adminTab" (click)="domainInfos = Domain; domainsViewState = 'edit'; domainFilter = domainInfos.id;">
                                <ng-container *ngIf="Domain.id === domainFilter || (domainFilter == undefined || domainFilter == '')">
                                    <td>{{Domain.Name}}</td>
                                    <td>{{Domain.Domain}}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- NEW COMPANIES BOX-->



            <!-- NEW EDIT/ADD COMPANIES BOX-->

            <div class="form-box" *ngIf="domainsViewState != 'view'">
                <form class="has-icons-left has-icons-right">
                    <div class="title" *ngIf="domainsViewState == 'edit'">
                        Edit Company
                    </div>
                    <div class="title" *ngIf="domainsViewState == 'add'">
                        New Company
                    </div>
                    <!--<button (click)="DeleteDomain()" class="button is-danger is-left bottom-page" *ngIf="domainsViewState == 'edit'">{{ 'account_config.delete' | translate }}</button>-->
                    <button type="submit" (click)="AddDomain()" *ngIf="domainsViewState == 'add'" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                    <button type="submit" (click)="EditDomain()" *ngIf="domainsViewState == 'edit'" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                    <button (click)="Cancel()" class="button is-danger is-float-right">{{ 'account_config.cancel' | translate }}</button>
                    <br><br>

                    <div class="field">
                        <label>Display Name</label>
                        <p class="control has-icons-left">
                            <input type="text" class="input" [(ngModel)]="domainInfos.Name" name="name" placeholder="Name"> <!-- (ngModelChange)="OnDomainChange('domainname')" -->
                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Name']">
                                error
                            </span>
                            
                        </p>
                    </div>

                    <div class="field">
                        <label>Company URL</label>
                        
                            <!--<input type="text" class="input lower-case" [(ngModel)]="domainInfos.Domain" (ngModelChange)="OnDomainChange('domainurl')" name="domain" placeholder="Domain">-->
                            
                            <div class="field has-addons">
                                <b class="control has-icons-left">
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-link"></i>
                                    </span>
                                    <div class="control">
                                        <input type="text" class="input lower-case" [(ngModel)]="domainInfos.subDomain" (ngModelChange)="OnDomainChange()" name="subDomain" placeholder="subdomain" />
                                    </div>
                                </b>
                                <div class="control">
                                    <input type="text" class="input lower-case" [value]="fullDomain" name="baseDomain" disabled />
                                </div>
                                <span class="has-text-danger" *ngIf="required['Domain']">
                                    error
                                </span>
                            </div>

                        <!--<p class="control has-icons-left">
                            <input type="text" class="input lower-case" [(ngModel)]="domainInfos.Domain" (ngModelChange)="OnDomainChange('domainurl')" name="domain" placeholder="Domain">
                            
                            <span class="icon is-small is-left">
                                <i class="fas fa-link"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['Domain']">
                                error
                            </span>
                        </p>-->
                    </div>

                    <div class="field">
                        <label>LogoURI</label>
                        <p class="control has-icons-left" *ngIf="domainInfos.CustomData != undefined">
                            <input type="text" class="input" [(ngModel)]="domainInfos.CustomData.LogoURI" name="logo-uri" placeholder="Logo URI">
                            <span class="icon is-small is-left">
                                <i class="fas fa-image"></i>
                            </span>
                        </p>
                        <p class="control has-icons-left" *ngIf="domainInfos.CustomData == undefined">
                            <input type="text" class="input" (ngModel)="domainInfos.CustomData.LogoURI" name="logo-uri" placeholder="Logo URI">
                            <span class="icon is-small is-left">
                                <i class="fas fa-image"></i>
                            </span>
                        </p>
                        <img class="logo" [src]="domainInfos.CustomData.LogoURI"/>
                    </div>

                    <!--<div class="field">
                        <label>Login Image</label>
                        <p class="control has-icons-left" *ngIf="domainInfos.CustomData != undefined">
                            <input class="input" type="text" [(ngModel)]="domainInfos.CustomData.LoginBackground" name="login-background" placeholder="Login Image">
                            <span class="icon is-small is-left">
                                <i class="fas fa-image"></i>
                            </span>
                        </p>
                        <p class="control has-icons-left" *ngIf="domainInfos.CustomData == undefined">
                            <input type="text" class="input" (ngModel)="domainInfos.CustomData.LoginBackground" name="login-background" placeholder="Login Image">
                            <span class="icon is-small is-left">
                                <i class="fas fa-image"></i>
                            </span>
                        </p>
                        <img class="logo" [src]="domainInfos.CustomData.LoginBackground" style="max-width:300px;"/>
                    </div>-->

                    <div class="field">
                        <label>Background Image</label>
                        <p class="control has-icons-left" *ngIf="domainInfos.CustomData != undefined">
                            <input class="input" type="text" [(ngModel)]="domainInfos.CustomData.ImgBackground" name="img-background" placeholder="Background Image">
                            <span class="icon is-small is-left">
                                <i class="fas fa-image"></i>
                            </span>
                        </p>
                        <p class="control has-icons-left" *ngIf="domainInfos.CustomData == undefined">
                            <input type="text" class="input" (ngModel)="domainInfos.CustomData.ImgBackground" name="img-background" placeholder="Background Image">
                            <span class="icon is-small is-left">
                                <i class="fas fa-image"></i>
                            </span>
                        </p>
                        <img class="logo" [src]="domainInfos.CustomData.ImgBackground" style="max-width:300px;"/>
                    </div>

                    <div class="field">
                        <label>Colors</label>
                        <div class="columns">
                            <div class="column">
                                <button class="rounded pointer" [style.background]="(domainInfos.CustomData.ColorHomeMain == '#343640' && domainInfos.CustomData.ColorHomeAlt == '#c4283d') ? 'white' : 'dimgrey'" *ngIf="domainInfos.CustomData != undefined" (click)="ChangeDomainColors('#343640', '#c4283d')">
                                    <div class="square_first" style="background-color: #343640;">
                                        <div class="square_second" style="background-color: #c4283d;">
                                        </div>
                                    </div>
                                    Vizu.Live
                                </button>
                            </div>
                            <div class="column">
                                <button class="rounded pointer" [style.background]="(domainInfos.CustomData.ColorHomeMain == '#222222' && domainInfos.CustomData.ColorHomeAlt == 'BurlyWood') ? 'white' : 'dimgrey'" *ngIf="domainInfos.CustomData != undefined" (click)="ChangeDomainColors('#222222', 'BurlyWood')">
                                    <div class="square_first" style="background-color: #222222">
                                        <div class="square_second" style="background-color: BurlyWood">
                                        </div>
                                    </div>
                                    Noir/Marron
                                </button>
                            </div>
                            <div class="column">
                                <button class="rounded pointer" [style.background]="(domainInfos.CustomData.ColorHomeMain == '#222222' && domainInfos.CustomData.ColorHomeAlt == '#FFDD00') ? 'white' : 'dimgrey'" *ngIf="domainInfos.CustomData != undefined" (click)="ChangeDomainColors('#222222', '#FFDD00')">
                                    <div class="square_first" style="background-color: #222222">
                                        <div class="square_second" style="background-color: #FFDD00">
                                        </div>
                                    </div>
                                    Noir/Jaune
                                </button>
                            </div>
                            <div class="column">
                                <button class="rounded pointer" [style.background]="(domainInfos.CustomData.ColorHomeMain == '#FF9900' && domainInfos.CustomData.ColorHomeAlt == '#55BB00') ? 'white' : 'dimgrey'" *ngIf="domainInfos.CustomData != undefined" (click)="ChangeDomainColors('#FF9900', '#55BB00')">
                                    <div class="square_first" style="background-color: #FF9900">
                                        <div class="square_second" style="background-color: #55BB00">
                                        </div>
                                    </div>
                                    Orange/Vert
                                </button>
                            </div>
                        </div>
                        <div *ngIf="domainInfos.CustomData.ColorHomeMain == undefined">
                            Personnalisé (entrez le code HTML de votre couleur):
                            <br>
                            <p class="control has-icons-left">
                                <input type="text" class="input" (ngModel)="domainInfos.CustomData.ColorHomeMain" name="color1" placeholder="Couleur Principale">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-palette"></i>
                                </span>
                            </p>

                            <p class="control has-icons-left">
                                <input type="text" class="input" (ngModel)="domainInfos.CustomData.ColorHomeAlt" name="color2" placeholder="Couleur Secondaire">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-palette"></i>
                                </span>
                            </p>
                        </div>
                        <div *ngIf="domainInfos.CustomData.ColorHomeMain != undefined">
                            Personnalisé (entrez le code HTML de votre couleur):
                            <br>
                            <div class="columns">
                                <div class="column">
                                    <p class="control has-icons-left">
                                        <input type="text" class="input" [(ngModel)]="domainInfos.CustomData.ColorHomeMain" name="color1" placeholder="Couleur Principale">
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-palette"></i>
                                        </span>
                                    </p>
                                </div>
                                <div class="column">
                                    <div class="square" [style.background]="domainInfos.CustomData.ColorHomeMain"></div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <p class="control has-icons-left">
                                        <input type="text" class="input" [(ngModel)]="domainInfos.CustomData.ColorHomeAlt" name="color2" placeholder="Couleur Secondaire">
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-palette"></i>
                                        </span>
                                    </p>
                                </div>
                                <div class="column">
                                    <div class="square" [style.background]="domainInfos.CustomData.ColorHomeAlt"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--<div class="field">
                        <p class="control has-icons-left">
                            <select class="input" id="sipProfileId" [(ngModel)]="domainInfos.SIPProfileId" [ngModelOptions]="{standalone: true}">
                                <option value="undefined" disabled selected hidden>Select...</option>
                                <option *ngFor="let sip_profile of sip_profiles; index as i" value="{{sip_profile.id}}">{{sip_profile.Name}}</option>
                            </select>

                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['SIPProfileId']">
                                error
                            </span>
                            
                        </p>
                    </div>

                    <div class="field">
                        <p class="control has-icons-left">
                            <select class="input" id="mediaProfileId" [(ngModel)]="domainInfos.MediaProfileId" [ngModelOptions]="{standalone: true}">
                                <option value="undefined" disabled selected hidden>Select...</option>
                                <option *ngFor="let media_profile of media_profiles; index as i" value="{{media_profile.id}}">{{media_profile.Name}}</option>
                            </select>

                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                            
                            <span class="has-text-danger" *ngIf="required['MediaProfileId']">
                                error
                            </span>
                            
                        </p>
                    </div>-->

                    <br>
                </form>
            </div>
<!-- NEW EDIT/ADD COMPANIES BOX-->
        </ng-container>
    </div>

    <app-notification #notificationComponent></app-notification>
    
</div>
<div [class.is-active]="bModalConfirmDelete" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class=""modal-card-title>Confirmation</p>
        </header>
        <section class="modal-card-body">
            <div class="incoming-call-title has-text-primary">{{ 'account_config.confirm_delete' | translate }}</div>
        </section>
        
        <footer class="modal-card-foot">
            <button type="button" (click)="DeleteItem()" class="button is-danger">
                <span>{{ 'account_config.delete' | translate }}</span>
            </button>
            <button type="button" (click)="bModalConfirmDelete = false" class="button is-danger">
                <span>{{ 'account_config.cancel' | translate }}</span>
            </button>
        </footer>
    </div>
</div>