<app-loading-menu *ngIf="Loading"></app-loading-menu>
<div *ngIf="Error && !Loading" class="error-reload">
    <div class="error-reload-text">{{ 'common.error' | translate }}</div>
    <button type="button" (click)="Load()" class="button is-danger">{{ 'common.reload' | translate }}</button>
</div>
<ng-container *ngIf="!Loading && !Error">
    <br>
    <div class="box form-box">
        <form>
            <div class="field">
                <label class="label">{{ 'add_user.name' | translate }}</label>
                <div class="control">
                    <input type="text" [(ngModel)]="Params.Name" name="Name" class="input">
                </div>
            </div>
            <div *ngIf="errorMonitor.Name" class="error-text has-text-danger">{{ 'common.error' | translate }}</div>
            <div class="field">
                <div class="control">
                    <button type="submit" (click)="ChangeName()" [class.is-loading]="SubmittingName" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                </div>
            </div>
        </form>
    </div>
    <div class="box form-box">
        <form>
            <div class="field">
                <label class="label">{{ 'account_config.room_name' | translate }}</label>
                <div class="control">
                    <input type="text" [(ngModel)]="Params.Room" name="Name" class="input room-input">
                </div>
            </div>
            <div *ngIf="errorMonitor.Room" class="error-text has-text-danger">{{ 'common.already_used' | translate }}</div>
            <div *ngIf="SmallRoom" class="error-text has-text-danger">{{ 'common.too_small' | translate }}</div>
            <div class="field">
                <div class="control">
                    <button type="submit" (click)="ChangeRoom()" [class.is-loading]="SubmittingName" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                </div>
            </div>
        </form>
    </div>
    <div class="box form-box">
        <form>
            <div class="field">
                <label class="label">{{ 'account_config.profile_picture_uri' | translate }}</label>
                <div class="control">
                    <input type="text" [(ngModel)]="LogoURIInput" name="Name" class="input"> <!-- (keyup)="checkUserTyping()" -->
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <button type="button" (click)="PreviewLogoURI()" class="button is-danger">{{ 'account_config.preview' | translate }}</button>
                </div>
            </div>
            <div class="field">
                <label class="label">{{ 'account_config.profile_preview' | translate }}</label>
                <div class="control">
                    <img id="trackedImg" *ngIf="Params.LogoURI !== '' || Params.LogoURI === undefined" class="logo" height="80" width="80" style="border-radius: 50%; padding: 9px;" [src]="Params.LogoURI"/>
                    <svg *ngIf="Params.LogoURI === ''" class="logo" height="80" width="80">
                        <image xlink:href="/assets/icons/profile-default.svg" height="80" width="80"/>
                    </svg>
                </div>
            </div>

            <div *ngIf="errorMonitor.LogoURI" class="error-text has-text-danger">{{ 'common.error' | translate }}</div>
            <div class="field">
                <div class="control">
                    <button type="submit" (click)="ChangeLogoURI()" [class.is-loading]="SubmittingLogoURI" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                </div>
            </div>
        </form>
    </div>

    <!--<div class="box form-box">
        <label class="label">{{ 'account_config.change_password' | translate }}</label>
        <form>
            <div class="field">
                {{ 'account_config.old_password' | translate }}
                <div class="control">
                    <input type="password" [(ngModel)]="Params.Password.Old" name="OldPassword" class="input">
                </div>
            </div>
            <div class="field">
                {{ 'account_config.new_password' | translate }}
                <div class="control">
                    <input type="password" (ngModelChange)="CheckStrength($event)" [(ngModel)]="Params.Password.New" name="NewPassword" class="input">
                </div>
            </div>
            
            <progress *ngIf="Params.Password.New.length > 0" class="progress is-small my-4" value="{{ Params.Password.Strength }}" max="100" [ngClass]="{'is-danger': Params.Password.Strength <= 40, 'is-warning' : Params.Password.Strength < 100 && Params.Password.Strength > 40, 'is-success' : Params.Password.Strength >= 100 }">{{ Params.Password.Strength }}%</progress>

            <ul *ngIf="Params.Password.New.length > 0" class="strength">
                <li class="strength">
                    <span *ngIf="Params.Password.New.length >= 8" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                        <i class="fas fa-check"></i>
                    </span>
                    <span *ngIf="Params.Password.New.length < 8" class="icon is-small is-right" style="color: #888">
                        <i class="fas fa-xmark"></i>
                    </span>
                    <span style="margin-left: 5px;" [ngStyle]="Params.Password.New.length >= 8 ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength2' | translate }}</span>
                </li>
                <li class="strength">
                    <span *ngIf="hasRegex(Params.Password.New, lowerRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                        <i class="fas fa-check"></i>
                    </span>
                    <span *ngIf="!hasRegex(Params.Password.New, lowerRegex)" class="icon is-small is-right" style="color: #888">
                        <i class="fas fa-xmark"></i>
                    </span>
                    <span style="margin-left: 5px;" [ngStyle]="hasRegex(Params.Password.New, lowerRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength3' | translate }}</span>
                </li>
                <li class="strength">
                    <span *ngIf="hasRegex(Params.Password.New, upperRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                        <i class="fas fa-check"></i>
                    </span>
                    <span *ngIf="!hasRegex(Params.Password.New, upperRegex)" class="icon is-small is-right" style="color: #888">
                        <i class="fas fa-xmark"></i>
                    </span>
                    <span style="margin-left: 5px;" [ngStyle]="hasRegex(Params.Password.New, upperRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength4' | translate }}</span>
                </li>
                <li class="strength">
                    <span *ngIf="hasRegex(Params.Password.New, numberRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                        <i class="fas fa-check"></i>
                    </span>
                    <span *ngIf="!hasRegex(Params.Password.New, numberRegex)" class="icon is-small is-right" style="color: #888">
                        <i class="fas fa-xmark"></i>
                    </span>
                    <span style="margin-left: 5px;" [ngStyle]="hasRegex(Params.Password.New, numberRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength5' | translate }}</span>
                </li>
                <li class="strength">
                    <span *ngIf="hasRegex(Params.Password.New, specialRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                        <i class="fas fa-check"></i>
                    </span>
                    <span *ngIf="!hasRegex(Params.Password.New, specialRegex)" class="icon is-small is-right" style="color: #888">
                        <i class="fas fa-xmark"></i>
                    </span>
                    <span style="margin-left: 5px;" [ngStyle]="hasRegex(Params.Password.New, specialRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength6' | translate }}</span>
                </li>
            </ul>

            <div *ngIf="ErrorPassword" class="error-text has-text-danger">{{ 'common.error' | translate }}</div>
            <div class="field">
                <div class="control">
                    <button type="submit" *ngIf="Params.Password.Strength !== 100" [class.is-loading]="SubmittingPassword" class="button is-danger" disabled>{{ 'account_config.save' | translate }}</button>
                    <button type="submit" *ngIf="Params.Password.Strength === 100" (click)="ChangePassword()" [class.is-loading]="SubmittingPassword" class="button is-danger">{{ 'account_config.save' | translate }}</button>
                </div>
            </div>
        </form>
    </div>-->

    <!--
    <a href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_DNEcSGFP76PBoBD0PvP7wsHYJHptxqmX&scope=read_write" class="button is-link">Connect stripe</a>
    -->

    <app-notification #notificationComponent></app-notification>
    
</ng-container>
