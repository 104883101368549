import { Component } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import * as moment from 'moment'
import 'moment-timezone'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        translate: TranslateService,
    ) {
        translate.setDefaultLang('en')
        translate.use(translate.getBrowserLang())
        moment.locale('fr')
    }
}
