<app-landing-container>
    <h1 class="landing-title">{{ 'landing_page.main_text' | translate }}</h1>
    <div class="landing-icons">
        <div class="landing-icon-container">
            <img src="/static/assets/communication.svg" class="landing-icon">
            <div class="landing-icon-text">{{ 'landing_page.video' | translate }}</div>
        </div>
        <div class="landing-icon-container">
            <img src="/static/assets/monitor.svg" class="landing-icon">
            <div class="landing-icon-text">{{ 'landing_page.screensharing' | translate }}</div>
        </div>
        <div class="landing-icon-container">
            <img src="/static/assets/folder.svg" class="landing-icon">
            <div class="landing-icon-text">{{ 'landing_page.file_sharing' | translate }}</div>
        </div>
    </div>
    <div class="landing-buttons">
        <a routerLink="/signup" class="button is-medium is-danger landing-button">{{ 'landing_page.try' | translate }}</a>
        <button (click)="LoginButton()" class="button is-medium is-danger landing-button">{{ 'landing_page.login' | translate }}</button>
    </div>
</app-landing-container>
