import { Component, Input } from '@angular/core';
import { DomainData } from '../../domain/domain'

@Component({
  selector: 'app-vizu-presence',
  templateUrl: './vizu-presence.component.html',
  styleUrl: './vizu-presence.component.scss'
})
export class VizuPresenceComponent {
  @Input() Contact: any
  @Input() bMessageView: boolean
  @Input() DomainData: DomainData
}
