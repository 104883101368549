import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-loading-menu',
    templateUrl: './loading-menu.component.html',
    styleUrls: ['./loading-menu.component.scss'],
})
export class LoadingMenuComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
    }
}
