import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-vizu-upgrade-plan',
  templateUrl: './vizu-upgrade-plan.component.html',
  styleUrls: ['./vizu-upgrade-plan.component.scss']
})
export class VizuUpgradePlanComponent {
  @Input() bUpgradeModalOpened: boolean
  @Output() CloseUpgradeModal = new EventEmitter<void>()

  Plans: any = {
    Free: "free",
    Pro: "pro",
    Enterprise: "enterprise",
    Custom: "custom"
  }

  activeTab = 'monthly';
  planSelected: string = this.Plans.Pro;
  isFeatureClicked = true;

  openModal() {
    this.bUpgradeModalOpened = true;
  }

  closeModal() {
    this.bUpgradeModalOpened = false;
    this.CloseUpgradeModal.emit()
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  upgradePlan() {
    console.log('Plan upgraded');
    this.openLink('https://welcome.vizu.live/contact'); // TEMP (delete this)
    this.closeModal();
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }
}