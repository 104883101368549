<div class="audio-perm-container">
  <div>
    <span class="mute-icon">
      <i class="fas fa-volume-mute"></i>
    </span>
    <span class="mute-text">
      Cliquez ici pour activer la sonnerie d'appel
    </span>
  </div>
</div>
