<app-landing-container>
    <ng-container *ngIf="isValidToken">
        <ng-container *ngIf="!isSuccess">
            <h1 class="login-title" style="color: whitesmoke;">{{ 'password_reset.title' | translate }}</h1>
            <form (ngSubmit)="onSubmit()" class="login-form">

                <div>
                    <label class="label">{{ 'password_reset_new.password' | translate }}</label>
                    <div class="control has-icons-right">
                      <input class="input login-input" type="password" placeholder="{{ 'password_reset_new.password' | translate }}" (ngModelChange)="CheckStrength($event)" [(ngModel)]="creds.password" name="password" style="height: 100%; margin-bottom: 0;" [ngClass]="{'is-danger': passwordStrength <= 40 && passwordStrength > 0, 'is-warning' : passwordStrength < 100 && passwordStrength > 40, 'is-success' : passwordStrength >= 100 }">
                      <span *ngIf="passwordStrength === 100" class="icon is-small is-right" style="height: 3.5em; color: #888;">
                        <i class="fas fa-check"></i>
                      </span>
                    </div>
                </div>

                <progress *ngIf="creds.password.length > 0" class="progress is-small my-4" value="{{passwordStrength}}" max="100" [ngClass]="{'is-danger': passwordStrength <= 40, 'is-warning' : passwordStrength < 100 && passwordStrength > 40, 'is-success' : passwordStrength >= 100 }">{{passwordStrength}}%</progress>

                <ul *ngIf="creds.password.length > 0" class="strength">
                    <li class="strength">
                        <span *ngIf="creds.password.length >= 8" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span *ngIf="creds.password.length < 8" class="icon is-small is-right" style="color: #888">
                            <i class="fas fa-xmark"></i>
                        </span>
                        <span style="margin-left: 5px;" [ngStyle]="creds.password.length >= 8 ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength2' | translate }}</span>
                    </li>
                    <li class="strength">
                        <span *ngIf="hasRegex(creds.password, lowerRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span *ngIf="!hasRegex(creds.password, lowerRegex)" class="icon is-small is-right" style="color: #888">
                            <i class="fas fa-xmark"></i>
                        </span>
                        <span style="margin-left: 5px;" [ngStyle]="hasRegex(creds.password, lowerRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength3' | translate }}</span>
                    </li>
                    <li class="strength">
                        <span *ngIf="hasRegex(creds.password, upperRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span *ngIf="!hasRegex(creds.password, upperRegex)" class="icon is-small is-right" style="color: #888">
                            <i class="fas fa-xmark"></i>
                        </span>
                        <span style="margin-left: 5px;" [ngStyle]="hasRegex(creds.password, upperRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength4' | translate }}</span>
                    </li>
                    <li class="strength">
                        <span *ngIf="hasRegex(creds.password, numberRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span *ngIf="!hasRegex(creds.password, numberRegex)" class="icon is-small is-right" style="color: #888">
                            <i class="fas fa-xmark"></i>
                        </span>
                        <span style="margin-left: 5px;" [ngStyle]="hasRegex(creds.password, numberRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength5' | translate }}</span>
                    </li>
                    <li class="strength">
                        <span *ngIf="hasRegex(creds.password, specialRegex)" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span *ngIf="!hasRegex(creds.password, specialRegex)" class="icon is-small is-right" style="color: #888">
                            <i class="fas fa-xmark"></i>
                        </span>
                        <span style="margin-left: 5px;" [ngStyle]="hasRegex(creds.password, specialRegex) ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'account_config.password_strength6' | translate }}</span>
                    </li>
                </ul>

                <!--<p [class.hidden]="passwordStrength !== 100" class="help is-success" style="padding-left: 1px; text-shadow: 1px 1px 2px black;">Password secured</p>-->

                <div class="field mt-2">
                    <label class="label">{{ 'password_reset_new.comfirm_password' | translate }}</label>
                    <div class="control has-icons-right">
                      <input class="input login-input" type="password" placeholder="{{ 'password_reset_new.comfirm_password' | translate }}" [(ngModel)]="creds.comfirmPassword" name="cpassword" style="height: 100%; margin-bottom: 0;" [ngClass]="{'is-success': creds.password == creds.comfirmPassword && creds.password.length > 0 }">
                      <span *ngIf="creds.password == creds.comfirmPassword" class="icon is-small is-right" style="height: 3.5em; color: #888;">
                        <i class="fas fa-check"></i>
                      </span>
                    </div>
                </div>

                <ul *ngIf="creds.comfirmPassword.length > 0" class="strength">
                    <li class="strength">
                        <span *ngIf="creds.password == creds.comfirmPassword" class="icon is-small is-right" style="color: hsl(153, 53%, 53%)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span *ngIf="creds.password != creds.comfirmPassword" class="icon is-small is-right" style="color: #888">
                            <i class="fas fa-xmark"></i>
                        </span>
                        <span style="margin-left: 5px;" [ngStyle]="creds.password == creds.comfirmPassword ? {'color': 'hsl(153, 53%, 53%)'} : {}">{{ 'password_reset_new.same_password' | translate }}</span>
                    </li>
                </ul>

                <button type="submit" [class.is-loading]="isProgress" class="button is-medium is-danger login-button" [disabled]="passwordStrength !== 100 || creds.password !== creds.comfirmPassword">{{ 'password_reset.submit' | translate }}</button>
            </form>


            <div *ngIf="error" class="login-error has-text-danger">
                {{ 'password_reset.error' | translate }}
            </div>
        </ng-container>
        <ng-container *ngIf="isSuccess">
            <h1 class="login-title" style="color: whitesmoke;">{{ 'password_reset_new.success' | translate }}</h1>
            <a href="/">
                <button type="button" class="button is-medium is-danger login-button">
                    <span class="icon is-small is-right" style="margin-right: 5px;">
                        <i class="fas fa-arrow-circle-left"></i>
                    </span> {{ 'login.submit' | translate }}
                </button>
            </a>
        </ng-container>
    </ng-container>
</app-landing-container>
