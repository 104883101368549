import { Component } from '@angular/core'

import { AuthService } from '../../../services/auth.service'

@Component({
    selector: 'app-password-reset-request',
    templateUrl: './password-reset-request.component.html',
    styleUrls: ['./password-reset-request.component.scss'],
})
export class PasswordResetRequestComponent {
    isSuccess = false
    isProgress = false
    error = false

    creds = {
        email: '',
    }

    constructor(private auth: AuthService) {}

    onSubmit(): void {
        this.isProgress = true
        try {
            this.auth.RequestPasswordReset(this.creds.email).subscribe({
              next: (success) => {
                console.log(success)
                this.isSuccess = true
              },
              error: (e) => {
                console.log(e)
                this.isSuccess = true
              }
            })
        } catch (e) {
            console.log(e)
            this.error = true
            this.isProgress = false
        }
    }
}
