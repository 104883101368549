import { Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EmitterService {
  EmitNgmsSettings$: Observable<any>;
  private EmitNgmsSettingsListener = new Subject<any>();

  constructor() {
    this.EmitNgmsSettings$ = this.EmitNgmsSettingsListener.asObservable();
  }

  EmitNgmsSettings(data: any, message: any) {
    let jsonData = {
      data: data,
      message: message
    }
    console.log("We have Data: "+ JSON.stringify(jsonData));
    this.EmitNgmsSettingsListener.next(jsonData);
  }
}