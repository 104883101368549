<div class="step-container" *ngIf="bRating">
<!--<div class="step-container">-->
  <header class="mb-6" style="padding-top: 2%;">
      <p *ngIf="!Feedback.Sent" class="step-title">{{ 'shell.feedback.title' | translate }}</p>
      <p *ngIf="Feedback.Sent" class="step-title">{{ 'shell.feedback.thanks_1' | translate }}</p>
      <p *ngIf="Feedback.Sent" class="step-title">{{ 'shell.feedback.thanks_2' | translate }}</p>
  </header>
  <section class="section-container">
      <ng-container *ngIf="!Feedback.Sent">
        <div class="stars">
            <span (mouseenter)="StarMouseover(1)" (mouseleave)="StarReset()" (click)="StarClick(1)" [class.yellow-star]="(Feedback.TempRating >= 1) || (Feedback.TempRating === 0 && Feedback.Rating >= 1)" [class.white-star]="(Feedback.TempRating < 1) || (Feedback.TempRating === 0 || Feedback.Rating < 1)" class="icon pointer">
                <i class="fas fa-star fa-lg"></i>
            </span>
            <span (mouseenter)="StarMouseover(2)" (mouseleave)="StarReset()" (click)="StarClick(2)" [class.yellow-star]="(Feedback.TempRating >= 2) || (Feedback.TempRating === 0 && Feedback.Rating >= 2)" [class.white-star]="(Feedback.TempRating < 2) || (Feedback.TempRating === 0 || Feedback.Rating < 2)" class="icon pointer">
                <i class="fas fa-star fa-lg"></i>
            </span>
            <span (mouseenter)="StarMouseover(3)" (mouseleave)="StarReset()" (click)="StarClick(3)" [class.yellow-star]="(Feedback.TempRating >= 3) || (Feedback.TempRating === 0 && Feedback.Rating >= 3)" [class.white-star]="(Feedback.TempRating < 3) || (Feedback.TempRating === 0 || Feedback.Rating < 3)" class="icon pointer">
                <i class="fas fa-star fa-lg"></i>
            </span>
            <span (mouseenter)="StarMouseover(4)" (mouseleave)="StarReset()" (click)="StarClick(4)" [class.yellow-star]="(Feedback.TempRating >= 4) || (Feedback.TempRating === 0 && Feedback.Rating >= 4)" [class.white-star]="(Feedback.TempRating < 4) || (Feedback.TempRating === 0 || Feedback.Rating < 4)" class="icon pointer">
                <i class="fas fa-star fa-lg"></i>
            </span>
            <span (mouseenter)="StarMouseover(5)" (mouseleave)="StarReset()" (click)="StarClick(5)" [class.yellow-star]="(Feedback.TempRating >= 5) || (Feedback.TempRating === 0 && Feedback.Rating >= 5)" [class.white-star]="(Feedback.TempRating < 5) || (Feedback.TempRating === 0 || Feedback.Rating < 5)" class="icon pointer">
              <i class="fas fa-star fa-lg"></i>
          </span>
        </div>
        <div class="step-step" *ngIf="(Feedback.TempRating > 0) || (Feedback.TempRating === 0 && Feedback.Rating > 0)">
          <div class="step-active" *ngIf="Feedback.Rating > 0 && Feedback.Rating <= 3">
            {{ 'shell.feedback.question_1_2' | translate }}
          </div>
          <div class="step-active" *ngIf="Feedback.Rating > 3 && Feedback.Rating <= 5">
            {{ 'shell.feedback.question_3_4' | translate }}
          </div>
        </div>
        <textarea name="Feedback" *ngIf="Feedback.Rating > 0" [(ngModel)]="Feedback.Message" placeholder="{{ 'shell.feedback.placeholder' | translate }}" class="textarea step-input"></textarea>
      </ng-container>
      
      <ng-container *ngIf="Feedback.Sent">
        <div class="step-step flex-h">
          <div>
            <!--<img src="/assets/comp.png" class="img-new-account">-->
            <div id="section_5_form" class="aligned-text-center">
              <form #form="ngForm" (ngSubmit)="onSubmit(form)" novalidate>
                  <input id="email" type="email" name="email" ngModel placeholder="Email" class="step-input">
                  <button id="submit-button button"class="button is-danger">{{ 'room_rating.register' | translate }}</button>
              </form>
            </div>
          </div>
        </div>
  
        <div class=" step-step flex-h" style="margin-top: 10px;">
          <div style="width: 400px;">
            <div class="feature-header">
              <div style="display: flex; place-content: center;">
                <h1 class="title is-3" style="color: white;">Vizu.Live</h1>
                <span class="title is-6 tag is-success ml-4">Free</span>
              </div>
            </div>
  
            <div class="feature-list" style="background-color: whitesmoke; height: 100%;">
              <div class="list">
                  <div class="list-item">
                      <span>{{ 'upgrade_plan.free.advantages.list.first' | translate }}</span>
                      <span class="icon has-text-success">
                          <i class="fas fa-check-circle"></i>
                      </span>
                  </div>
                  <div class="list-item">
                    <span>{{ 'upgrade_plan.free.advantages.list.second' | translate }}</span>
                      <span class="icon has-text-success">
                          <i class="fas fa-check-circle"></i>
                      </span>
                  </div>
                  <div class="list-item">
                    <span>{{ 'upgrade_plan.free.advantages.list.third' | translate }}</span>
                      <span class="icon has-text-success">
                          <i class="fas fa-check-circle"></i>
                      </span>
                  </div>
                  <div class="list-item">
                    <span>{{ 'upgrade_plan.free.advantages.list.fourth' | translate }}</span>
                      <span class="icon has-text-success">
                          <i class="fas fa-check-circle"></i>
                      </span>
                  </div>
                  <div class="list-item">
                    <span>{{ 'upgrade_plan.free.advantages.list.fifth' | translate }}</span>
                      <span class="icon has-text-success">
                          <i class="fas fa-check-circle"></i>
                      </span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

  </section>
  <footer class="button-submit" *ngIf="Feedback.Rating > 0">
      <a *ngIf="!Feedback.Sent" (click)="SendFeedback()" [class.is-loading]="Feedback.Submitting" class="button is-danger">{{ 'shell.feedback.send' | translate }}</a>
      <span *ngIf="Feedback.Error">Error</span>
  </footer>
</div>

<!-- ----------------------- IF NOT CONNECTED / SEND MESSAGE --------------------------------------- -->

<div class="step-container" *ngIf="!bRating && !messageSent">
  <header>
      <p class="step-title1">{{ 'shell.feedback.unavailable1' | translate }}</p>
      <p class="step-title2">{{ 'shell.feedback.unavailable2' | translate }}</p>
  </header>
  <section class="section-container">
    <p class="step-active">{{ 'shell.feedback.content_message' | translate }}:</p>
    <textarea class="textarea step-input" name="Feedback" [(ngModel)]="Message.Content"></textarea>
    <p class="step-active">{{ 'shell.feedback.coordinates_message' | translate }}:</p>
    <input type="text" class="step-input" [(ngModel)]="Message.Contact">
  </section>
  <footer class="button-submit">
      <a (click)="SendMessage()" class="button is-danger">{{ 'shell.feedback.send' | translate }}</a>
  </footer>
</div>

<!-- -------------------------- AFTER SENDING A MESSAGE ------------------------------------------- -->

<div class="step-container" *ngIf="!bRating && messageSent">
  <header class="mb-6" style="padding-top: 2%;">
    <p class="step-title">{{ 'shell.feedback.unavailableTitle1' | translate }}</p>
    <p class="step-title">{{ 'shell.feedback.unavailableTitle2' | translate }}</p>
  </header>
  <section>
    <ng-container>
      <div class="step-step flex-h">
        <div>
          <!--<img src="/assets/comp.png" class="img-new-account">-->
          <div id="section_5_form" class="aligned-text-center">
            <form #form="ngForm" (ngSubmit)="onSubmit(form)" novalidate>
                <input id="email" type="email" name="email" ngModel placeholder="Email" class="step-input">
                <button id="submit-button button"class="button is-danger">{{ 'room_rating.register' | translate }}</button>
            </form>
          </div>
        </div>
      </div>

      <div class=" step-step flex-h" style="margin-top: 10px;">
        <div style="width: 400px;">
          <div class="feature-header">
            <div style="display: flex; place-content: center;">
              <h1 class="title is-3" style="color: white;">Vizu.Live</h1>
              <span class="title is-6 tag is-success ml-4">Free</span>
            </div>
          </div>

          <div class="feature-list" style="background-color: whitesmoke; height: 100%;">
            <div class="list">
                <div class="list-item">
                    <span>{{ 'upgrade_plan.free.advantages.list.first' | translate }}</span>
                    <span class="icon has-text-success">
                        <i class="fas fa-check-circle"></i>
                    </span>
                </div>
                <div class="list-item">
                  <span>{{ 'upgrade_plan.free.advantages.list.second' | translate }}</span>
                    <span class="icon has-text-success">
                        <i class="fas fa-check-circle"></i>
                    </span>
                </div>
                <div class="list-item">
                  <span>{{ 'upgrade_plan.free.advantages.list.third' | translate }}</span>
                    <span class="icon has-text-success">
                        <i class="fas fa-check-circle"></i>
                    </span>
                </div>
                <div class="list-item">
                  <span>{{ 'upgrade_plan.free.advantages.list.fourth' | translate }}</span>
                    <span class="icon has-text-success">
                        <i class="fas fa-check-circle"></i>
                    </span>
                </div>
                <div class="list-item">
                  <span>{{ 'upgrade_plan.free.advantages.list.fifth' | translate }}</span>
                    <span class="icon has-text-success">
                        <i class="fas fa-check-circle"></i>
                    </span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
  <footer></footer>
</div>