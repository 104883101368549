import { Component } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {

  notifications: any = {
    showSuccessResult: false,
    showFailedResult: false,
    showSendFilePending: false,
    copyLink: false
  }

  displayNotification(notificationKey: string) {
    this.notifications[notificationKey] = true;
    setTimeout(() => {
        this.notifications[notificationKey] = false;
    }, 3000);
  }

}
