<ng-container *ngIf="!Uploading">
    <label *ngIf="Limitations === undefined || (!Limitations.InConfRestriction || (Limitations.InConfRestriction && NumberFilesSent <= 2))" for="file-uploader">
        <!--<span class="icon">
            <i class="fas fa-upload"></i>
        </span>-->
        <svg width="30" height="30" class="pointer">
            <image xlink:href="/assets/icons/attach.svg" width="30" height="30"/>
        </svg>
    </label>
    <input *ngIf="Limitations !== undefined && (!Limitations.InConfRestriction || (Limitations.InConfRestriction && NumberFilesSent <= 2))" id="file-uploader" type="file" (change)="onFileChange($event)" class="hidden">
    
    <label *ngIf="Limitations !== undefined && (Limitations.InConfRestriction && NumberFilesSent > 1)">
        <svg (click)="OpenUpgradePanel()" width="30" height="30" class="pointer">
            <image xlink:href="/assets/icons/attach.svg" width="30" height="30"/>
        </svg>
    </label>
</ng-container>
<div *ngIf="Uploading" class="progress-container">
    <b class="progress">{{ Progress }} %</b>
    <svg height="36" width="36">
        <circle
            stroke="#c6bdbf"
            stroke-width="3"
            fill="transparent"
            r="16"
            cx="18"
            cy="18"
            class="progress_ring_circle"
            [ngStyle]="FixedCircleStyle"
        />
        <circle
            stroke="#c4283d"
            stroke-width="3"
            fill="transparent"
            r="16"
            cx="18"
            cy="18"
            class="progress_ring_circle"
            [ngStyle]="CircleStyle"
        />
    </svg>
</div>
