import { EventEmitter, Component, Output } from '@angular/core'

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
    @Output() Login = new EventEmitter<void>()

    constructor() {}

    LoginButton(): void {
        this.Login.emit()
    }
}
