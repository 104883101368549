import { Component } from '@angular/core'
import { AuthService } from '../../services/auth.service'

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
    isSuccess = false
    isProgress = false
    error = false
    token: string = undefined;
    creds = {
        password: '',
        comfirmPassword: ''
    }
    isValidToken = false;
    passwordStrength = 0;
    
    specialRegex = new RegExp("[^A-Za-z0-9]"); 
    upperRegex = new RegExp("[A-Z]");
    lowerRegex = new RegExp("[a-z]"); 
    numberRegex = new RegExp("[0-9]"); 
    lengthRegex = new RegExp(".{8,}");

    constructor(private auth: AuthService) {
      let pathname = window.location.pathname.split( '/' );
      this.token = pathname[2];
      if (this.token) {
        this.auth.CheckPasswordResetToken(this.token).subscribe({
          next: (success) => {
            console.log(success)
            this.isValidToken = true;
          },
          error: (e) => {
            console.log(e)
            window.location.href = "/password-reset-request";
          }
        })
      } else {
        window.location.href = "/password-reset-request";
      }
    }

    async onSubmit(): Promise<void> {
      if (this.creds.password == this.creds.comfirmPassword) {
        this.isProgress = true
        try {
          
          this.auth.ResetPassword(this.token, this.creds.password).subscribe({
            next: (success) => {
              console.log(success)
              this.isSuccess = true
            },
            error: (e) => {
              console.log(e)
              this.error = true
              this.isProgress = false
            }
          })
        } catch (e) {
          console.log(e)
          this.error = true
          this.isProgress = false
        }
      } else {
        this.error = true
      }
    }

    CheckStrength(newPwd: any): number {
      let currentStrength = 0

      if (this.hasRegex(newPwd, this.specialRegex)) {
          currentStrength += 20
      }
      if (this.hasRegex(newPwd, this.upperRegex)) {
          currentStrength += 20
      }
      if (this.hasRegex(newPwd, this.lowerRegex)) {
          currentStrength += 20
      }
      if (this.hasRegex(newPwd, this.numberRegex)) {
          currentStrength += 20
      }
      if (this.hasRegex(newPwd, this.lengthRegex)) {
          currentStrength += 20
      }

      this.passwordStrength = currentStrength
      return currentStrength;
  }

  hasRegex(str: string, regex: RegExp): boolean {
    return regex.test(str);
  }
}
