import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthService } from "./auth.service";

//import { Appointment, Feedback, Contact, ContactData, Customer, Record, UserData, RoomConfig, DomainData } from '../domain/domain'
import { DomainData } from "../domain/domain";
import { Observable, throwError } from "rxjs";

declare const DataServer: any;

class HttpClientWrapper {
  constructor(private http: HttpClient, private auth: AuthService) {}

  get(url: string): Observable<any> {
    try {
      return this.http.get<any>(url);
    } catch (error) {
      this.auth.CatchUnauthentication(error);
      throw error;
    }
  }

  post(url: string, body: any): Observable<any> {
    try {
      return this.http.post<any>(url, body);
    } catch (error) {
      this.auth.CatchUnauthentication(error);
      throw error;
    }
  }

  put(url: string, body: any): Observable<any> {
    try {
      return this.http.put<any>(url, body);
    } catch (error) {
      this.auth.CatchUnauthentication(error);
      throw error;
    }
  }

  patch(url: string, body: any): Observable<any> {
    try {
      return this.http.patch<any>(url, body);
    } catch (error) {
      this.auth.CatchUnauthentication(error);
      throw error;
    }
  }

  delete(url: string): Observable<any> {
    try {
      return this.http.delete<any>(url);
    } catch (error) {
      this.auth.CatchUnauthentication(error);
      throw error;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private http: HttpClientWrapper;
  ngmsUrl: any = "";

  // account creation
  CreateAccount = {
    Email: "",
    Password: "",
    ConfirmPassword: "",
    RoomName: "",
    DisplayName: "",
    Token: "",
    Code: "",
  };

  constructor(http: HttpClient, auth: AuthService) {
    this.http = new HttpClientWrapper(http, auth);
    this.ngmsUrl = DataServer;
  }
  //ngmsbcUrl = "https://" + location.host + ":5081"

  async translateDomain(ngDomain: any): Promise<DomainData> {
    let ngUserInfos = JSON.parse(ngDomain.User1);
    let domainData = {
      Name: ngDomain.Domain,
      FriendlyName: ngDomain.Name,
      IsWildcard: ngDomain.IsWildcard,
      ImgBackground: ngUserInfos.ImgBackground,
      LoginBackground: ngUserInfos.LoginBackground,
      ImgLogo: ngUserInfos.ImgLogo,
      ColorHomeMain: ngUserInfos.ColorHomeMain,
      ColorHomeAlt: ngUserInfos.ColorHomeAlt,
      ColorHomeText: ngUserInfos.ColorHomeText,
      ColorRoomHeader: ngUserInfos.ColorRoomHeader,
      ColorHomeAltText: ngUserInfos.ColorHomeAltText,
      ColorHomeMainText: ngUserInfos.ColorHomeMainText,
      ImgLogoRoom: ngUserInfos.ImgLogoRoom,
    };
    return new DomainData(domainData);
  }

  GetCustomer(): Observable<any> {
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/me";
    } else {
      url = "/ngmconf/user/ws-noauth/me";
    }
    return this.http.get(url);
  }

  GetRooms(admin = false): Observable<any[]> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/conference_rooms?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/conference_rooms?include=*";
    }

    //return this.http.get(this.ngmsUrl + ngmsPath + '/ws-noauth/conference_rooms?include=*')
    return this.http.get(url);
  }

  EditRoom(room: any, admin = false): Observable<any> {
    let ngmsPath = "user";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/conference_rooms/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/conference_rooms/";
    }
    return this.http.put(url + room.id, room);
  }

  AddRoom(room: any, admin = false): Observable<any> {
    let ngmsPath = "user";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/conference_rooms/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/conference_rooms/";
    }
    return this.http.post(url, room);
  }

  DeleteRoom(room: any, admin = false): Observable<any> {
    let ngmsPath = "user";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/conference_rooms/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/conference_rooms/";
    }
    return this.http.delete(url + room.id);
  }

  /*async GetDomainPublicTemplate() : Promise<DomainData> {
        let myDomainDataGet: any = undefined
        this.GetDomainPublicTemplateReq().subscribe(data => {
            myDomainDataGet = data;
        });
        let DomainData: DomainData = {
            Name: myDomainDataGet.Name,
            CustomData: JSON.parse(myDomainDataGet.CustomData)
        };
        return DomainData
    }*/

  GetDomainPublicTemplate(Room: string): Observable<any> {
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/public/ws-noauth/domain/room/" + Room;
    } else {
      url = "/ngmconf/public/ws-noauth/domain/room/" + Room;
    }
    return this.http.get(url);
  }

  importImage(blob: Blob, name: string): Observable<any> {
    if (blob) {
      const formData = new FormData();
      formData.append("file", blob, name);

      let url = undefined;
      if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
        url = "/" + this.ngmsUrl.Url + "/public/ws-noauth/image";
      } else {
        url = "/ngmconf/public/ws-noauth/image";
      }
      return this.http.post(url, formData);
    } else {
      return throwError(() => new Error("Blob is null or undefined."));
    }
  }

  EditUser(user: any, admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/end_users/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/end_users/";
    }
    return this.http.put(url + user.id, user);
  }

  /*EditUserCustomData(user: any, admin = false): Observable<any> {
        let ngmsPath = '/ngmconf/domainadmin';
        if (admin) {
            ngmsPath = '/ngmconf/admin'
        }
        return this.http.put(this.ngmsUrl + ngmsPath + '/ws-noauth/end_users/' + user.id, user)
    }*/

  AddUser(user: any, admin = false): Observable<any> {
    /*let result: any = {};
        this.http.get(this.ngmsUrl + ngmsPath + '/ws-noauth/end_user_profiles?include=*')
        .subscribe(successGetProfiles => {
            for (let i = 0; i < successGetProfiles.length; i++) {
                if (successGetProfiles[i].DomainId === user.DomainId && successGetProfiles[i].Name !== "Anonymous") {
                    user.ProfileId = successGetProfiles[i].id
                    if (user.Password !== undefined && user.Username !== undefined) {
                        this.http.post(this.ngmsUrl + ngmsPath + '/ws-noauth/end_users', user)
                    }
                }
            }
            result.success = true
        })
        
        return result;*/
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/end_users/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/end_users/";
    }

    return this.http.post(url, user);
  }

  RegisterUser(): Observable<any> {
    /*let result: any = {};
        this.http.get(this.ngmsUrl + ngmsPath + '/ws-noauth/end_user_profiles?include=*')
        .subscribe(successGetProfiles => {
            for (let i = 0; i < successGetProfiles.length; i++) {
                if (successGetProfiles[i].DomainId === user.DomainId && successGetProfiles[i].Name !== "Anonymous") {
                    user.ProfileId = successGetProfiles[i].id
                    if (user.Password !== undefined && user.Username !== undefined) {
                        this.http.post(this.ngmsUrl + ngmsPath + '/ws-noauth/end_users', user)
                    }
                }
            }
            result.success = true
        })
        
        return result;*/

    const user = {
      Email: this.CreateAccount.Email,
      Password: this.CreateAccount.Password,
      Name: this.CreateAccount.DisplayName,
    };
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/end_users/";
    } else {
      url = "/ngmconf/user/ws-noauth/end_users/";
    }

    return this.http.post(url, user);
  }

  ConfirmUser(): Observable<any> {
    let url = undefined;
    let body = {};
    if (this.CreateAccount.Code !== "") {
      body = {
        email: this.CreateAccount.Email,
        registerCode: Number(this.CreateAccount.Code),
      };
    } else if (this.CreateAccount.Token !== "") {
      body = {
        registerToken: this.CreateAccount.Token,
      };
    }

    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/confirm-registration/";
    } else {
      url = "/ngmconf/user/ws-noauth/confirm-registration/";
    }

    return this.http.post(url, body);
  }

  ResendEmailConfirmUser(): Observable<any> {
    let url = undefined;
    let body = {
      email: this.CreateAccount.Email,
      resend: true,
    };
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/confirm-registration/";
    } else {
      url = "/ngmconf/user/ws-noauth/confirm-registration/";
    }

    return this.http.post(url, body);
  }

  notifyOnConfOrUserExist = function (event: boolean) {
    this._notifyOnConfOrUserExist(event);
  };
  _notifyOnConfOrUserExist(event: any) {
    return event;
  }

  GetUserExist(): Observable<any> {
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/end_users?include=*";
    } else {
      url = "/ngmconf/user/ws-noauth/end_users?include=*";
    }
    return this.http.get(url);
  }

  GetConfExist(): Observable<any> {
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/conference_rooms?include=*";
    } else {
      url = "/ngmconf/user/ws-noauth/conference_rooms?include=*";
    }
    return this.http.get(url);
  }

  GetRoomExist(Room: string): void {
    let roomExist = false;
    this.GetUserExist().subscribe((dataUser) => {
      let usersList: any = dataUser;
      for (let i = 0; i < usersList.length; i++) {
        if (usersList[i].Username === Room) {
          roomExist = true;
          break;
        }
      }
      if (!roomExist) {
        this.GetConfExist().subscribe((dataConf) => {
          let confList: any = dataConf;
          for (let i = 0; i < confList.length; i++) {
            if (confList[i].Room === Room) {
              roomExist = true;
              this.notifyOnConfOrUserExist(roomExist);
              break;
            }
          }
          if (!roomExist) {
            this.notifyOnConfOrUserExist(roomExist);
          }
        });
      } else {
        this.notifyOnConfOrUserExist(roomExist);
      }
    });
  }

  DeleteUser(user: any, admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/end_users/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/end_users/";
    }
    return this.http.delete(url + user.id);
  }

  GetUsers(admin = false): Observable<any[]> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/end_users?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/end_users?include=*";
    }
    return this.http.get(url);
    /*const appointmentsUrls: any[] = []
        const appointments: any[] = []
        for (let i = 0; i < appointmentsTree.length; i++) {
            appointmentsUrls.push(appointmentsTree[i].links[0].href)
        }
        for (let i = 0; i < appointmentsUrls.length; i++) {
            let appointmentData = undefined
            this.http.get(this.ngmsUrl + appointmentsUrls[i])
            .subscribe(data => {
                appointmentData = data
                appointments.push(new Appointment(appointmentData))

            })
        }*/
    //return appointments
  }

  GetUsersProfiles(admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/end_user_profiles?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/end_user_profiles?include=*";
    }
    return this.http.get(url);
  }

  GetSIPProfiles(admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/sip_profiles?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/sip_profiles?include=*";
    }
    return this.http.get(url);
  }

  GetMediaProfiles(admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/media_profiles?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/media_profiles?include=*";
    }
    return this.http.get(url);
  }

  EditDomain(domain: any, admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    let ngmsEndPath = "domains";
    if (admin) {
      ngmsPath = "admin";
    }
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/" + ngmsEndPath + "/" + domain.id + "?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/" + ngmsEndPath + "/" + domain.id + "?include=*";
    }

    domain.CustomData.FriendlyName = domain.Name;
    domain.CustomData.Name = domain.Domain;
    let domainFormatted = domain;
    domainFormatted.CustomData = JSON.stringify(domain.CustomData);
    return this.http.put(url, domainFormatted);
  }

  AddDomain(domain: any, admin = false) {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    var urlDomains: any = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      urlDomains = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/domains";
      /*urlEndUserProfiles = '/' + this.ngmsUrl.Url + '/' + ngmsPath + '/ws-noauth/end_user_profiles'
            urlEndUsers = '/' + this.ngmsUrl.Url + '/' + ngmsPath + '/ws-noauth/end_users'*/
    } else {
      urlDomains = "/ngmconf/" + ngmsPath + "/ws-noauth/domains";
      /*urlEndUserProfiles = '/ngmconf/' + ngmsPath + '/ws-noauth/end_user_profiles'
            urlEndUsers = '/ngmconf/' + ngmsPath + '/ws-noauth/end_users'*/
    }

    domain.CustomData.FriendlyName = domain.Name;
    domain.CustomData.Name = domain.Domain;
    let domainFormatted = domain;
    domainFormatted.CustomData = JSON.stringify(domain.CustomData);
    return this.http.post(urlDomains, domainFormatted);
  }

  DeleteDomain(domain: any, admin = false): Observable<any> {
    let ngmsPath = "domainadmin";
    if (admin) {
      ngmsPath = "admin";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/domains/";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/domains/";
    }
    return this.http.delete(url + domain.id);
  }

  GetDomains(admin = false): Observable<any[]> {
    let ngmsPath = "domainadmin";
    let ngmsEndPath = "my_domain";
    if (admin) {
      ngmsPath = "admin";
      ngmsEndPath = "domains";
    }

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/" + ngmsPath + "/ws-noauth/" + ngmsEndPath + "?include=*";
    } else {
      url = "/ngmconf/" + ngmsPath + "/ws-noauth/" + ngmsEndPath + "?include=*";
    }
    return this.http.get(url);
  }

  setColors(backgroundColor: string, textColor: string) {
    if (backgroundColor) {
      document.documentElement.style.setProperty("--background-color", backgroundColor);
      document.documentElement.style.setProperty("--text-color", textColor);
    } else {
      const root = document.documentElement;

      const defaultBackgroundColor = getComputedStyle(root).getPropertyValue("--background-color");
      document.documentElement.style.setProperty("--background-color", defaultBackgroundColor);
      const defaultTextColor = getComputedStyle(root).getPropertyValue("--text-color");
      document.documentElement.style.setProperty("--text-color", defaultTextColor);
    }
  }
}
