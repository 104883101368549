import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-vizu-footer',
    templateUrl: './vizu-footer.component.html',
    styleUrls: ['./vizu-footer.component.scss'],
})
export class VizuFooterComponent implements OnInit {
    constructor() {}

    ngOnInit() {
    }
}
