import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CustomerService } from '../../services/customer.service'
import { RtcService } from '../../services/rtc.service'

import { DomainData, Limitations } from '../../domain/domain'
import { ActivatedRoute, createUrlTreeFromSnapshot, Router } from '@angular/router';

@Component({
  selector: 'app-vizu-rating',
  templateUrl: './vizu-rating.component.html',
  styleUrls: ['./vizu-rating.component.scss']
})
export class VizuRatingComponent implements OnInit {

  /*private appointmentRequests = new Map<string, Appointment>()
  AppointmentRequests: Appointment[] = []

  CurrentAppointment: Appointment = undefined
  WaitingRequest = false*/

  @Input() Limitations: Limitations
  @Input() DomainData: DomainData
  @Input() bRating: boolean

  Feedback = {
      Submitting: false,
      Error: false,
      Message: '',
      Sent: false,
      Rating: 0,
      TempRating: 0,
  }

  Message = {
    To: "",
    Title: "",
    Content: "",
    Contact: ""
  }

  localStorage = {
    AppointmentID: '',
    Rated: '',
  }

  messageSent: boolean = false

  Tag = 0

  constructor(
      private customerService: CustomerService,
      private rtc: RtcService,
      private router: Router
  ) {}

  ngOnInit() {
    //this.Feedback.Sent = true; // BYPASS RATING

    /*this.customerService.GetDomainPublicTemplate()
    .subscribe(data => {
      let domainData: any = data
      this.DomainData = {
          Name: data.Name,
          CustomData: (domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData))
      };
    });*/
    if (this.Limitations?.MessagePanelHidden) {
      this.messageSent = true
    }
    if (localStorage.getItem("VizuLive\\Rated") !== "null" && localStorage.getItem("VizuLive\\Rated") !== null) {
      this.Feedback.Sent = true
    }
  }

  setTextColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {  
      if (this.DomainData.CustomData.ColorHomeMain === "white" || this.DomainData.CustomData.ColorHomeMain === "#FFFFFF" || this.DomainData.CustomData.ColorHomeMain === "rgb(255,255,255)") {
        style = {
            "color": "black",
        };
      } else {
        style = {
            "color": "white",
        };  
      }
    }
    return style;
}

  onSubmit(form: NgForm) {
    this.customerService.CreateAccount.Email = form.value.email;
    //window.location.replace('/register')
    this.router.navigate(['/register']);
  }

  async SendFeedback(): Promise<void> {
    this.Feedback.Submitting = true
    const message = {
        RatingStars: this.Feedback.Rating,
        RatingMessage: this.Feedback.Message,
    }
    this.rtc.sendRatingMessage(message)
    .subscribe(answered => {
      this.Feedback.Submitting = false
      this.Feedback.Sent = true
      this.Feedback.Error = false
    })
    //localStorage.setItem("VizuLive\\Rated", this.Feedback.Sent.toString());
  }

  async SendMessage() {
    //this.rtc.sendOfflineMessage(this.Message.To, this.Message.Content)
    this.rtc.sendOfflineMessage(this.rtc.options.to, this.Message.Content, this.Message.Contact)
    this.messageSent = true
  }

  StarMouseover(i: number) {
    this.Feedback.TempRating = i
  }

  StarClick(i: number) {
    this.Feedback.Rating = i
  }

  StarReset() {
    this.Feedback.TempRating = 0
  }

  CloseFeedback() {
    this.Feedback = {
        Submitting: false,
        Error: false,
        Message: '',
        Sent: false,
        Rating: 0,
        TempRating: 0,
    }
  }
}
