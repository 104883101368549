<div class="is-offline presence-state" *ngIf="!bMessageView && (Contact.State !== undefined && Contact.State === 'closed')">
    <div class="is-vizu-look space-element">
        <div class="enter-button-out-offline">
            <div class="enter-button-in">
                <span class="enter-button-offline">
                </span>
            </div>
        </div>
    </div>
</div>

<div class="is-busy presence-state" *ngIf="!bMessageView && (Contact.State !== undefined && Contact.State === 'busy' && Contact.data.InCall !== undefined)">
    <div class="is-vizu-look space-element">
        <div class="enter-button-out">
            <div class="enter-button-in">
                <span class="enter-button">
                </span>
            </div>
        </div>
    </div>
    <b class="classic-text margin-left-5">{{ Contact.data.InCall }}</b>
</div>

<div class="is-online presence-state" *ngIf="!bMessageView && (Contact.State !== undefined && Contact.State === 'opened' && Contact.data.InCall === undefined)">
    <div class="is-vizu-look space-element">
        <div class="enter-button-out-online">
            <div class="enter-button-in">
                <span class="enter-button-online">
                </span>
            </div>
        </div>
    </div>
</div>

<div class="is-offline presence-state" *ngIf="bMessageView && (DomainData.SharedUsers && Contact.Type === 'User' && Contact.State !== undefined && Contact.State === 'closed')">
    <div class="is-vizu-look space-element">
        <div class="enter-button-out-offline">
            <div class="enter-button-in">
                <span class="enter-button-offline">
                </span>
            </div>
        </div>
    </div>
</div>

<div class="is-busy presence-state" *ngIf="bMessageView && (DomainData.SharedUsers && Contact.Type === 'User' &&Contact.State !== undefined && Contact.State === 'busy' && Contact.data.InCall !== undefined)">
    <div class="is-vizu-look space-element">
        <div class="enter-button-out">
            <div class="enter-button-in">
                <span class="enter-button">
                </span>
            </div>
        </div>
    </div>
</div>

<div class="is-online presence-state" *ngIf="bMessageView && (DomainData.SharedUsers && Contact.State !== undefined && Contact.State === 'opened' && Contact.data.InCall === undefined)">
    <div class="is-vizu-look space-element">
        <div class="enter-button-out-online">
            <div class="enter-button-in">
                <span class="enter-button-online">
                </span>
            </div>
        </div>
    </div>
</div>