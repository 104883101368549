import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RtcService } from 'src/app/services/rtc.service';

@Component({
  selector: 'app-vizu-recaptcha',
  templateUrl: './vizu-recaptcha.component.html',
  styleUrl: './vizu-recaptcha.component.css'
})
export class VizuRecaptchaComponent implements OnInit {
  // login / signup / [password_reset] / [get_price] / [payment_add] / checkout / transaction_confirmed
  @Input() userAction: string;
  @Output() authAction = new EventEmitter<boolean>();

  constructor(private recaptchaV3Service: ReCaptchaV3Service, private rtc: RtcService) {}

  public ngOnInit() {
    this.recaptchaV3Service.execute(this.userAction)
    .subscribe({
      next: (token) => {
        console.log("reCAPTCHAv3 token received: " + token)
        //Send token to server and get confidence index
        this.rtc.getConfidenceLevel(token, this.userAction).subscribe(
          (confidenceServer: any) => {
            //Then check the confidence we got from the server
            this.checkActionToken(confidenceServer.confidence)
          },
          (error: any) => {
            console.log(`error:`, error);
            this.authAction.emit(true)
        });
      },
      error: (e) => {
        console.log(`error:`, e);
        this.authAction.emit(true)
      }
    });
  }

  public checkActionToken(confidence: number) {
    if (confidence >= 0.5) {
      this.authAction.emit(true)  
    } else {
      this.authAction.emit(false)
    }
  }

  /*public executeRecaptchaV3() {
    //Recaptcha v3 execution requested
    this.recaptchaV3Service.execute('myAction').subscribe(
      (token) => {
        //We have a new token
        this.formatToken(token);
      },
      (error) => {
        //Recaptcha v3 error: see console
        console.log(`error:`, error);
      }
    );
  }

  public onError() {
    //this.log.push(`reCAHPTCHA errored;`);
  }

  public formatToken(token: string | null) {
    return token !== null
      ? `${token.substring(0, 7)}...${token.substring(token.length - 7)}`
      : 'null';
  }*/
}
