<div class="full-height landing-background" *ngIf="DomainData !== undefined" [ngStyle]="setLoginBackground()">
    <nav class="navbar-header" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <p   *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI === undefined || DomainData.CustomData.LogoURI === '')" class="text-logo">{{ DomainData.CustomData.FriendlyName }}</p>
            <img *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI !== undefined && DomainData.CustomData.LogoURI !== '') && bLogoFullPath" class="vizu-logo" alt="Logo" src='{{ DomainData.CustomData.LogoURI }}' />
            <img *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI !== undefined && DomainData.CustomData.LogoURI !== '') && !bLogoFullPath" class="vizu-logo" alt="Logo" src='{{ "/assets/companies/" + DomainData.Name + "/" + DomainData.CustomData.LogoURI }}' />
        </div>
    </nav>
    <div class="landing-content">
        <div class="landing-center">
            <ng-content></ng-content>
        </div>
        <div class="is-hidden-mobile separator"></div>
        <div class="landing-center is-hidden-mobile">
            <p class="oranienbaum-regular has-text-left is-size-2-desktop is-size-3-touch">{{ 'home.description' | translate }}</p>
            <div *ngIf="DomainData.CustomData?.LoginBackground === ''" style="width: 400px; height: 400px;"></div>
        </div>
    </div>
    <img *ngIf="DomainData.CustomData?.LoginBackground === ''" src="/assets/img/vizu_zen.png" class="is-hidden-mobile zen-logo">
</div>
<app-loading-screen *ngIf="DomainData === undefined">
</app-loading-screen>