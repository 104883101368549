
<div *ngIf="cookies" class="cookie-box">
  {{ 'cookie.description' | translate }}
  <div class="mt-4" style="display: block; text-align: center;">
      <button class="button is-outlined" style="width: 120px;" (click)="showCookieSettings = true; showCookieSettingsChange.emit(showCookieSettings);" [ngClass]="isWhite ? 'is-white' : 'is-primary'">{{ 'cookie.settings' | translate }}</button>
      <button class="button is-white ml-4" style="width: 120px;" (click)="saveCookie()">{{ 'cookie.allow' | translate }}</button>
  </div>
</div>


<div *ngIf="cookies" [class.is-active]="showCookieSettings" class="modal"  style="align-content: center;">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ 'cookie.cookie_settings' | translate }}</p>
        <button class="delete" aria-label="close" (click)="cancelCookie()"></button>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <h3>{{ 'cookie.necessary.title' | translate }}</h3>
          <p>{{ 'cookie.necessary.text' | translate }}</p>
          <div class="control">
            <label class="radio">
              <input type="radio" name="accept_necessary" checked disabled/>
              {{ 'cookie.accept' | translate }}
            </label>
            <label class="radio">
              <input type="radio" name="reject_necessary" disabled/>
              {{ 'cookie.reject' | translate }}
            </label>
          </div>

          <h3>{{ 'cookie.performance.title' | translate }}</h3>
          <p>{{ 'cookie.performance.text' | translate }}</p>
          <div class="control">
            <label class="radio">
              <input type="radio" [(ngModel)]="cookies.performance" name="accept_performance" [value]="true"/>
              {{ 'cookie.accept' | translate }}
            </label>
            <label class="radio">
              <input type="radio" [(ngModel)]="cookies.performance" name="reject_performance" [value]="false"/>
              {{ 'cookie.reject' | translate }}
            </label>
          </div>

          <h3>{{ 'cookie.advertising.title' | translate }}</h3>
          <p>{{ 'cookie.advertising.text' | translate }}</p>
          <div class="control">
            <label class="radio">
              <input type="radio" name="accept_advertising" disabled/>
              {{ 'cookie.accept' | translate }}
            </label>
            <label class="radio">
              <input type="radio" name="reject_advertising" checked disabled/>
              {{ 'cookie.reject' | translate }}
            </label>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="buttons">
          <button class="button is-danger" (click)="saveCookie()">{{ 'cookie.save' | translate }}</button>
          <button class="button" (click)="cancelCookie()">{{ 'cookie.cancel' | translate }}</button>
        </div>
      </footer>
    </div>
</div>