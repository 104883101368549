<app-landing-container>
    <ng-container *ngIf="!isSuccess">
        <h1 class="login-title" style="color: whitesmoke;">{{ 'password_reset.title' | translate }}</h1>
        <form (ngSubmit)="onSubmit()" class="login-form">
            <input type="email" placeholder="{{ 'password_reset.email' | translate }}" [(ngModel)]="creds.email" name="email" class="login-input">
            <button type="submit" [class.is-loading]="isProgress" class="button is-medium is-danger login-button">{{ 'password_reset.submit' | translate }}</button>
        </form>
        <div *ngIf="error" class="login-error has-text-danger">
            {{ 'password_reset.error' | translate }}
        </div>
    </ng-container>
    <ng-container *ngIf="isSuccess">
        <h1 class="login-title" style="color: whitesmoke; width: 80%;">{{ 'password_reset.success' | translate }}</h1>
    </ng-container>
</app-landing-container>
