<app-vizu-header [Public]="true" [Room]="Appointment">
  <nav class="is-hidden-desktop" *ngIf="State === 'started'" role="navigation" aria-label="main navigation">
    <div (click)="bNavBurgerDisplay = !bNavBurgerDisplay">
      <a
        role="button"
        [class.is-active]="bNavBurgerDisplay"
        class="navbar-burger burger"
        data-target="navMenu"
        style="width: auto !important; position: inherit !important"
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <div id="navMenu" [class.is-active]="bNavBurgerDisplay" class="navbar-menu">
      <div class="navbar-start">
        <span
          class="navbar-item"
          (click)="sendClickInfoMessage('SetConf-Touch'); SetConf()"
          [class.doc-selected]="
            MainAttendee == undefined &&
            ScreenSharingEnabled == false &&
            WhiteboardEnabled == false &&
            RemoteScreenStream == undefined &&
            !PDFVectSharingEnabled &&
            !bSharingImage
          "
        >
          {{ "room.conference" | translate }}
        </span>
        <span class="navbar-item">
          <label for="pdf-vect-uploader" class="add-doc-label-burger">
            <span>{{ "room.sharing_img_pdf" | translate }}</span>
          </label>
          <input
            id="pdf-vect-uploader"
            type="file"
            (change)="sendClickInfoMessage('PDFSharing-Browse'); SharePDFVect($event)"
            class="hidden"
            multiple
            accept=".png,.jpeg,.jpg,.svg,.gif,.pdf"
          />
        </span>
        <span
          class="navbar-item doc-item doc-cont"
          *ngFor="let doc of Docs; index as i"
          (click)="sendClickInfoMessage('SelectDocHeader-LeftPane'); DocumentSwitch(false, 1, doc.ID)"
          [class.doc-selected-red]="(PDFVectSharingEnabled || bSharingImage) && CurrentPDF.Name === doc.Name"
        >
          <span class="doc-item-cursor doc-indent">{{ i + 1 }}</span>
          <i
            (click)="sendClickInfoMessage('DeleteDoc-LeftPane'); DeleteDoc(false, i)"
            class="fas fa-trash doc-icon-indent doc-item-cursor"
          ></i>
        </span>
        <span class="page-selector navbar-item" *ngIf="PDFVectSharingEnabled">
          <a (click)="sendClickInfoMessage('SelectDoc-LeftPane'); SelectPDFPageVect(-1, false)" class="button is-danger pdf-page-button">
            <span class="icon">
              <i class="fas fa-angle-left"></i>
            </span>
          </a>
          <span class="doc-selected-red">{{ PageSelected }} / {{ PageMaxVect }}</span>
          <a (click)="sendClickInfoMessage('SelectDocPage-LeftPane'); SelectPDFPageVect(1, false)" class="button is-danger">
            <span class="icon">
              <i class="fas fa-angle-right"></i>
            </span>
          </a>
        </span>
      </div>
    </div>
  </nav>
  <div *ngIf="TimerStarted" class="session">
    <div class="live-out is-hidden-touch">
      <div class="live-in">
        <div class="live-disk"></div>
      </div>
    </div>
    <!--<div class="is-hidden-touch">{{ (TimerStarted ? 'shell.room.room' : 'public_room.help') | translate }}{{ TimerStarted ? " " + AppointmentID : "" }}</div>-->
    <div class="is-hidden-touch text-red">{{ "shell.room.room" | translate }}{{ " " + AppointmentID }}</div>
    <div class="timer ml-6">{{ TimerStarted ? SessionMinutes + ":" + SessionSeconds : "" }}</div>
  </div>
  <div class="header-right">
    <app-button-feedback></app-button-feedback>
  </div>

  <!--<button *ngIf="State === 'started'" (click)="Close()" class="button is-danger is-outlined close-button aligned-top">Quitter</button>-->
</app-vizu-header>
<app-loading-screen *ngIf="bRoomFound === undefined && DomainData == undefined"></app-loading-screen>

<app-vizu-recaptcha #recaptchaComponent [userAction]="userAction" (authAction)="handleRecaptcha($event)"></app-vizu-recaptcha>

<div *ngIf="bRoomFound === false" class="main-space-public-message">
  <!--[ngStyle]="setImageBackground()-->
  <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>-->
  <div *ngIf="bCompanyFound === false" class="name-form text-custom-color title is-size-1" style="margin-top: 15%; margin-bottom: 20px">
    {{ "public_room.not_found_url_title" | translate }}
  </div>
  <div *ngIf="bCompanyFound === true" class="name-form text-custom-color title is-size-1" style="margin-top: 15%; margin-bottom: 20px">
    {{ "public_room.not_found_title" | translate }}
  </div>
  <div class="name-form text-custom-color is-size-3">{{ "public_room.not_found_main" | translate }}</div>
</div>

<div *ngIf="State === 'closed' && Limitations?.InConfRestriction && bRoomFull === true" class="main-space-public-message">
  <!--[ngStyle]="setImageBackground()"-->
  <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>-->
  <div class="name-form name-text">{{ "public_room.room_full_title" | translate }}</div>
  <div class="name-form step-step-unknown">{{ "public_room.room_full_text1" | translate }}</div>
  <div class="name-form step-step-unknown">{{ "public_room.room_full_text2" | translate }}</div>
</div>

<ng-container *ngIf="!Public">
  <div *ngIf="State === 'media_access'" class="main-space">
    <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
        </div>-->
    <div class="name-form name-text" [ngStyle]="setDomainMainColor()" *ngIf="DomainData != undefined">
      {{ "public_room.media_access" | translate }}
    </div>
  </div>
</ng-container>
<app-loading-screen *ngIf="State === 'media_granted' && DomainData == undefined"></app-loading-screen>
<div *ngIf="State === 'media_granted' && DomainData != undefined" class="main-space-public-message">
  <!--[ngStyle]="setImageBackground()"-->
  <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>-->
  <div class="name-form name-text" *ngIf="DomainData != undefined">{{ "public_room.media_granted1" | translate }}</div>
  <div class="name-form name-text-after" *ngIf="DomainData != undefined">{{ "public_room.media_granted2" | translate }}</div>
  <div class="name-form name-text-after" *ngIf="DomainData != undefined">{{ "public_room.media_granted3" | translate }}</div>
</div>
<app-loading-screen *ngIf="State === 'waiting' && DomainData == undefined"></app-loading-screen>
<div *ngIf="State === 'waiting' && DomainData != undefined" class="full-height layout-background">
  <!--[ngStyle]="setImageBackground()"-->
  <div class="layout-content waiting-content">
    <div class="layout-main waiting-main" style="display: block">
      <div class="layout-center">
        <h1
          class="oranienbaum-regular has-text-left is-size-2-fullhd is-size-3-desktop is-size-4-touch mb-4 text-custom-color"
          *ngIf="DomainData != undefined"
        >
          {{ "public_room.waiting" | translate }}
        </h1>
        <!--<app-loader-points style="margin: 50px auto 0"></app-loader-points>-->

        <p class="has-text-centered is-size-5-fullhd is-size-6-desktop is-size-7-touch mb-4 text-custom-color">
          {{ "public_room.sub_waiting" | translate }}
        </p>
        <div class="video-container">
          <video
            id="{{ LocalStream.id }}"
            *ngIf="LocalStream != undefined"
            [appNgmediaVideo]="LocalStream"
            playsinline
            autoplay
            [muted]="true"
            class="video-preview video-view-participant"
            [ngStyle]="setDomainMainColor()"
          ></video>
          <!--While no video, replace by a black video tag-->
          <video
            *ngIf="LocalStream == undefined"
            [muted]="true"
            class="video-preview video-view-participant video-tag"
            [ngStyle]="setDomainMainColor()"
          ></video>
          <div class="controls">
            <!--
                        <button class="control-btn">
                          <i class="fas fa-phone"></i>
                        </button>
                        <button class="control-btn">
                          <i class="fas fa-video"></i>
                        </button>
                        <button class="control-btn">
                          <i class="fas fa-microphone"></i>
                        </button>
-->

            <button class="button clear-call-btn">
              <span class="icon">
                <svg width="30" height="30" class="pointer svg-white" (click)="cancelCall()">
                  <image xlink:href="/assets/icons/clear-call.svg" width="30" height="30" />
                </svg>
              </span>
            </button>

            <button class="button" [class.is-vizu-background]="LocalStreamVideoEnabled">
              <span class="icon">
                <svg
                  width="30"
                  height="30"
                  class="pointer"
                  *ngIf="LocalStreamVideo"
                  (click)="sendClickInfoMessage('ControlBar-ToggleVid-Desktop'); ToggleVid()"
                  style="position: absolute"
                  [class.svg-white]="LocalStreamVideoEnabled"
                >
                  <image xlink:href="/assets/icons/video.svg" width="30" height="30" *ngIf="LocalStreamVideoEnabled" />
                  <image xlink:href="/assets/icons/video-slash.svg" width="30" height="30" *ngIf="!LocalStreamVideoEnabled" />
                </svg>
              </span>
            </button>

            <button class="button" [class.is-vizu-background]="LocalStreamAudioEnabled">
              <span class="icon">
                <svg
                  width="30"
                  height="30"
                  class="pointer"
                  *ngIf="LocalStreamAudio"
                  (click)="sendClickInfoMessage('ControlBar-ToggleMic-Desktop'); ToggleMic()"
                  style="position: absolute"
                  [class.svg-white]="LocalStreamAudioEnabled"
                >
                  <image xlink:href="/assets/icons/microphone.svg" width="30" height="30" *ngIf="LocalStreamAudioEnabled" />
                  <image xlink:href="/assets/icons/mute.svg" width="30" height="30" *ngIf="!LocalStreamAudioEnabled" />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <br />

        <p
          [ngStyle]="{ visibility: DomainData != undefined && callTimerTooLong ? 'visible' : 'hidden' }"
          class="has-text-centered is-size-5-fullhd is-size-6-desktop is-size-7-touch text-custom-color"
        >
          {{ "public_room.no_answer" | translate }} ? {{ "public_room.let_message" | translate }}
        </p>

        <button
          [ngClass]="{ 'fade-in': DomainData != undefined && callTimerTooLong }"
          type="button"
          class="button hidden-button is-blue is-outlined login-button"
          (click)="skipCallAndLetMessage()"
        >
          <svg class="svg-blue" width="40" height="40" style="margin-left: -10px">
            <image xlink:href="/assets/icons/email.svg" width="40" height="40" />
          </svg>
          <span class="ml-1 text-custom-color">{{ "public_room.let_message_btn" | translate }}</span>
        </button>
        <div *ngIf="DomainData != undefined && callTimerTooLong && bSendMessageForbidden" class="error-text has-text-danger">
          {{ AppointmentID }} {{ "room.message_forbidden" | translate }}
        </div>
      </div>
      <div class="layout-center"></div>
    </div>
  </div>
  <app-vizu-bubbles class="bubbles-view is-hidden-mobile"></app-vizu-bubbles>
</div>
<app-loading-screen *ngIf="State === 'closed' && DomainData == undefined"></app-loading-screen>
<div *ngIf="State === 'closed' && DomainData != undefined" class="layout-background">
  <!--[ngStyle]="setImageBackground()"-->
  <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>-->
  <app-vizu-rating
    [Limitations]="Limitations"
    [bRating]="hasBeenConnected"
    [DomainData]="DomainData"
    [RejectMessage]="RejectMessage"
    [AppointmentID]="AppointmentID"
    [SubscriptionType]="SubscriptionType"
    class="fill"
    style="height: 100%; display: block"
  ></app-vizu-rating>
</div>
<app-loading-screen *ngIf="State === 'refused' && DomainData == undefined"></app-loading-screen>
<div *ngIf="State === 'refused' && DomainData != undefined" class="main-space-public-message">
  <!--[ngStyle]="setImageBackground()"-->
  <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>-->
  <div class="name-form name-text" *ngIf="DomainData != undefined">{{ "public_room.refused" | translate }}</div>
</div>

<app-loading-screen
  *ngIf="
    Public && (State === 'media_access' || State === 'media_access_success' || State === 'media_access_error') && DomainData == undefined
  "
></app-loading-screen>
<div
  *ngIf="
    Public && (State === 'media_access' || State === 'media_access_success' || State === 'media_access_error') && DomainData != undefined
  "
  class="main-space-public"
>
  <!--[ngStyle]="setImageBackground()"-->
  <!--<div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>-->
  <br class="is-hidden-desktop" />

  <div class="columns fill padded">
    <div *ngIf="bTextCall" class="panel-text is-hidden-touch">
      <div class="title has-text-centered poppins" *ngIf="DomainData != undefined">
        Discutez avec {{ DomainData.CustomData.FriendlyName }}
      </div>
      <div *ngIf="(State === 'media_access_success' || State === 'media_access_error') && AppointmentID != undefined" class="step-step">
        Nom
      </div>
      <form *ngIf="State === 'media_access_success' || State === 'media_access_error'">
        <div class="flex-inline">
          <input
            type="text"
            placeholder="{{ 'public_room.get_name_placeholder' | translate }}"
            name="AttendeeName"
            [(ngModel)]="UserInfo.Name"
            class="input name-input"
            [ngClass]="{ 'is-danger': isNameError }"
          />
          <p *ngIf="isNameError" class="help is-danger">This name is invalid</p>
        </div>
      </form>
      <div *ngIf="(State === 'media_access_success' || State === 'media_access_error') && AppointmentID != undefined" class="step-step">
        Message (facultatif)
      </div>
      <div>
        <textarea
          class="question-text"
          placeholder="{{ 'public_room.get_text' | translate }}"
          name="AttendeeText"
          [(ngModel)]="requestBeforeCall"
        ></textarea>
      </div>
      <button
        class="button is-outlined is-primary vizu-padding pointer"
        [class.is-loading]="this.State == 'waiting' ? true : false"
        (click)="sendClickInfoMessage('SetPublicName'); getcaptcha()"
      >
        <div class="enter-button-out">
          <div class="enter-button-in">
            <span class="enter-button"> </span>
          </div>
        </div>
        <b class="button-text">{{ "shell.room.join" | translate }}</b>
      </button>
    </div>

    <div *ngIf="!bTextCall" class="panel-left column is-hidden-touch">
      <div class="title has-text-centered poppins" *ngIf="DomainData != undefined">
        {{ "public_room.welcome" | translate }}{{ DomainData.CustomData.FriendlyName }}
      </div>
      <div
        *ngIf="(State === 'media_access_success' || State === 'media_access_error') && AppointmentID != undefined"
        class="step-step has-text-centered"
        style="display: block"
      >
        {{ "public_room.you_will_enter" | translate }} <strong style="color: #353648">{{ AppointmentID }}</strong>
      </div>
      <form *ngIf="State === 'media_access_success' || State === 'media_access_error'">
        <div class="columns" style="align-content: center">
          <div class="column is-7">
            <input
              type="text"
              placeholder="{{ 'public_room.get_name_placeholder' | translate }}"
              name="AttendeeName"
              [(ngModel)]="UserInfo.Name"
              class="input name-input"
              [ngClass]="{ 'is-danger': isNameError }"
            />
            <p *ngIf="isNameError" class="help is-danger">This name is invalid</p>
          </div>
          <div class="column is-5" style="text-align: end">
            <button
              class="button is-outlined is-primary vizu-padding pointer"
              (click)="sendClickInfoMessage('SetPublicName'); getcaptcha()"
            >
              <div class="enter-button-out">
                <div class="enter-button-in">
                  <span class="enter-button"> </span>
                </div>
              </div>
              <b class="button-text">{{ "shell.room.join" | translate }}</b>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="!bTextCall" class="panel-right column centered">
      <div *ngIf="bSupported">
        <div class="title is-hidden-touch oranienbaum-regular is-size-3-fullhd is-size-5-desktop is-size-5-touch mb-1">
          {{ "public_room.mirror_message" | translate }}
        </div>

        <div class="title has-text-centered poppins is-5 is-hidden-desktop" *ngIf="DomainData != undefined">
          {{ "public_room.welcome" | translate }}{{ DomainData.CustomData.FriendlyName }}
        </div>

        <div class="landscape-mobile">
          <div class="panel-size smaller-preview">
            <video
              id="{{ LocalStream.id }}"
              *ngIf="LocalStream != undefined"
              [appNgmediaVideo]="LocalStream"
              playsinline
              autoplay
              [muted]="true"
              class="video-preview video-view-participant"
              [ngStyle]="setDomainMainColor()"
            ></video>
            <!--While no video, replace by a black video tag-->
            <video
              *ngIf="LocalStream == undefined"
              [muted]="true"
              class="video-preview video-view-participant video-tag"
              [ngStyle]="setDomainMainColor()"
            ></video>

            <div class="control-bar" style="margin: 10px" *ngIf="State == 'media_access_success'">
              <div>
                <progress class="progress-bar" [value]="meterValue" max="1">{{ meterValue }}%</progress>
                <svg
                  width="60"
                  height="60"
                  class="is-hidden-touch relative-item pointer"
                  *ngIf="LocalStreamAudio"
                  (click)="sendClickInfoMessage('BeforeCall-ToggleMic-Desktop'); ToggleMic()"
                >
                  <image xlink:href="/assets/icons/micro-dark.svg" width="60" height="60" *ngIf="LocalStreamAudioEnabled" />
                  <image xlink:href="/assets/icons/micro-slash.svg" width="60" height="60" *ngIf="!LocalStreamAudioEnabled" />
                </svg>
              </div>
              <svg
                width="60"
                height="60"
                class="is-hidden-touch pointer"
                *ngIf="LocalStreamVideo"
                (click)="sendClickInfoMessage('BeforeCall-ToggleVid-Desktop'); ToggleVid()"
                style="margin-left: 10px"
              >
                <image xlink:href="/assets/icons/video-dark.svg" width="60" height="60" *ngIf="LocalStreamVideoEnabled" />
                <image xlink:href="/assets/icons/video-slash.svg" width="60" height="60" *ngIf="!LocalStreamVideoEnabled" />
              </svg>
              <svg
                width="40"
                height="40"
                class="is-hidden-desktop pointer"
                *ngIf="LocalStreamAudio"
                (click)="sendClickInfoMessage('BeforeCall-ToggleMic-Touch'); ToggleMic()"
              >
                <image xlink:href="/assets/icons/micro-dark.svg" width="40" height="40" *ngIf="LocalStreamAudioEnabled" />
                <image xlink:href="/assets/icons/micro-slash.svg" width="40" height="40" *ngIf="!LocalStreamAudioEnabled" />
              </svg>
              <svg
                width="40"
                height="40"
                class="is-hidden-desktop pointer"
                *ngIf="LocalStreamVideo"
                (click)="sendClickInfoMessage('BeforeCall-ToggleVid-Touch'); ToggleVid()"
                style="margin-left: 10px"
              >
                <image xlink:href="/assets/icons/video-dark.svg" width="40" height="40" *ngIf="LocalStreamVideoEnabled" />
                <image xlink:href="/assets/icons/video-slash.svg" width="40" height="40" *ngIf="!LocalStreamVideoEnabled" />
              </svg>
            </div>
            <div *ngIf="State == 'media_access_error'" class="media-error" style="margin-top: 20px">
              <b class="has-text-danger">
                <i class="fas fa-exclamation-triangle"></i>
                {{ "shell.room.error_notfound1" | translate }}<br />
              </b>
              <b class="has-text-danger">
                <i class="fas fa-exclamation-triangle"></i>
                {{ "shell.room.error_notfound2" | translate }}<br />
              </b>
            </div>
          </div>

          <div class="is-hidden-desktop panel-size">
            <form *ngIf="State === 'media_access_success' || State === 'media_access_error'">
              <div style="margin-bottom: 10px">
                <input
                  type="text"
                  placeholder="{{ 'public_room.get_name_placeholder' | translate }}"
                  name="AttendeeName"
                  [(ngModel)]="UserInfo.Name"
                  class="input name-input"
                  [ngClass]="{ 'is-danger': isNameError }"
                />
                <p *ngIf="isNameError" class="help is-danger">This name is invalid</p>
              </div>

              <button
                class="button is-outlined is-primary vizu-padding pointer"
                (click)="sendClickInfoMessage('SetPublicName'); getcaptcha()"
              >
                <div class="enter-button-out">
                  <div class="enter-button-in">
                    <span class="enter-button"> </span>
                  </div>
                </div>
                <b class="button-text">{{ "shell.room.join" | translate }}</b>
              </button>
            </form>
          </div>
        </div>

        <div *ngIf="getUserMediaDone">
          <b *ngIf="LocalStreamAudio == false" class="has-text-danger">
            <i class="fas fa-exclamation-triangle"></i>
            {{ "shell.room.error_audio" | translate }}<br />
          </b>
          <b *ngIf="LocalStreamVideo == false" class="has-text-danger">
            <i class="fas fa-exclamation-triangle"></i>
            {{ "shell.room.error_video" | translate }}<br />
          </b>
          <b *ngIf="LocalStreamVideo == false || LocalStreamAudio == false" class="has-text-danger">
            {{ "shell.room.error_solve" | translate }}
          </b>
        </div>

        <div class="is-hidden-touch" *ngIf="State == 'media_access_success'">
          <div class="field is-horizontal">
            <div class="label-media">
              <label class="label"> Audio: </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <select
                    *ngIf="audioDevices != undefined"
                    class="select video-view-participant"
                    (change)="updateAudioDevice($event.target.value)"
                  >
                    <option
                      *ngFor="let audioDevice of audioDevices; index as i"
                      value="{{ i }}"
                      [selected]="i == audioDeviceSelected ? true : false"
                    >
                      {{ audioDevice.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="label-media">
              <label class="label"> Video: </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <select class="select video-view-participant" (change)="updateVideoDevice($event.target.value)">
                    <option
                      *ngFor="let videoDevice of videoDevices; index as i"
                      value="{{ i }}"
                      [selected]="i == videoDeviceSelected ? true : false"
                    >
                      {{ videoDevice.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="bSupported === false" class="step-step">
        <div *ngIf="bIEReason === true && DomainData != undefined" [ngStyle]="setDomainMainColor()">
          {{ "public_room.media_access_not_supported_old1" | translate }}<br />
          {{ "public_room.media_access_not_supported_old2" | translate }}<br />
          {{ "public_room.media_access_not_supported3" | translate }}<br />
        </div>
        <div *ngIf="bIEReason === false && DomainData != undefined" [ngStyle]="setDomainMainColor()">
          {{ "public_room.media_access_not_supported_compat1" | translate }}<br />
          {{ "public_room.media_access_not_supported_compat2" | translate }}<br />
          {{ "public_room.media_access_not_supported3" | translate }}<br />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="State === 'started'" class="main-space">
  <!-- [ngStyle]="setDomainMainColor()" -->
  <div
    class="document-side rounded-top is-hidden-touch"
    style="background-color: white"
    [class.animation-leftpanel-right-to-left]="leftPanel.Display"
    [class.animation-leftpanel-left-to-right]="!leftPanel.Display"
  >
    <!--[ngStyle]="setDomainMainColor()"-->
    <div
      (click)="sendClickInfoMessage('SetConf-Desktop'); SetConf()"
      [class.doc-selected]="
        MainAttendee == undefined &&
        !ScreenSharingEnabled &&
        !WhiteboardEnabled &&
        RemoteScreenStream == undefined &&
        !PDFVectSharingEnabled &&
        !bSharingImage
      "
      class="doc-item doc-item-cursor"
    >
      <svg width="50" height="50" class="pointer">
        <image xlink:href="/assets/icons/room-conf.svg" width="50" height="50" />
      </svg>
      <span *ngIf="leftPanel.Display" class="is-hidden-mobile">{{ "room.conference" | translate }}</span>
    </div>
    <div
      (click)="sendClickInfoMessage('ToggleScreenSharing-Desktop'); ToggleScreenSharing()"
      [class.doc-selected]="ScreenSharingEnabled || RemoteScreenStream != undefined"
      class="doc-item doc-item-cursor"
    >
      <svg width="50" height="50" class="pointer">
        <image xlink:href="/assets/icons/share-media.svg" width="50" height="50" />
      </svg>
      <span *ngIf="leftPanel.Display" class="is-hidden-mobile">{{ "room.share_screen" | translate }}</span>
    </div>
    <div *ngIf="!leftPanel.Display" class="doc-item doc-item-cursor">
      <label for="pdf-vect-uploader">
        <svg width="50" height="50" class="pointer">
          <image xlink:href="/assets/icons/new-document.svg" width="50" height="50" />
        </svg>
      </label>
    </div>
    <div *ngIf="leftPanel.Display" class="doc-item-upload doc-upload">
      <label for="pdf-vect-uploader" class="doc-item-upload pointer">
        <svg width="40" height="40" class="pointer svg-black">
          <image xlink:href="/assets/icons/drag.svg" width="40" height="40" />
        </svg>
        <span _ngcontent-bku-c7="" class="is-hidden-mobileicon">{{ "public_room.drag_and_drop_1" | translate }}</span>
        <span _ngcontent-bku-c7="" class="is-hidden-mobileicon">{{ "public_room.drag_and_drop_2" | translate }}</span>
        <span _ngcontent-bku-c7="" class="is-hidden-mobileicon">{{ "public_room.drag_and_drop_3" | translate }}</span>
      </label>
      <input
        id="pdf-vect-uploader"
        name="file_sharing"
        type="file"
        accept=".png,.jpeg,.jpg,.svg,.gif,.pdf"
        (change)="SharePDFVect($event)"
        class="hidden"
        multiple
      />
    </div>
    <div *ngFor="let doc of Docs; index as i" class="name-tag doc-cont">
      <!--<i class="fas fa-file-pdf"></i>-->
      <div class="doc-name-container">
        <svg
          width="20"
          height="20"
          class="doc-item-cursor"
          [class.fa-rotate-180]="Docs[i].Display == true"
          (click)="Docs[i].Display = !Docs[i].Display"
        >
          <image xlink:href="/assets/icons/arrow.svg" width="20" height="20" />
        </svg>
        <span
          class="is-hidden-mobile doc-name"
          [class.doc-selected-white]="(PDFVectSharingEnabled || bSharingImage) && CurrentPDF.Name == doc.Name"
          (click)="sendClickInfoMessage('DeleteDoc-Desktop'); DocumentSwitch(false, 1, doc.ID)"
          >{{ doc.Name }}</span
        >
        <svg width="20" height="20" class="doc-item-cursor" (click)="sendClickInfoMessage('DeleteDoc-Desktop'); DeleteDoc(false, i)">
          <image xlink:href="/assets/icons/cancel.svg" width="20" height="20" />
        </svg>
      </div>
      <ng-container class="pdf-pages is-hidden-tablet" *ngIf="doc.Type == 'application/pdf'">
        <div *ngFor="let page of [].constructor(Docs[i].Pages); index as j">
          <div
            id="pdf-container{{ Docs[i].ID }}-page{{ j + 1 }}"
            class="pdf-container-document-side"
            [class.doc-selected]="PDFVectSharingEnabled && j + 1 == PageSelected && CurrentPDF.Name == doc.Name"
            [class.hide]="Docs[i].Display == false"
            (click)="sendClickInfoMessage('SwitchDocPage-Desktop'); DocumentSwitch(false, j + 1, doc.ID)"
          >
            <canvas id="doc{{ Docs[i].ID }}-page{{ j + 1 }}" class="grayed-border"></canvas>
            <div class="doc-page" [class.doc-selected]="PDFVectSharingEnabled && j + 1 == PageSelected && CurrentPDF.Name == doc.Name">
              {{ j + 1 }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container class="pdf-pages is-hidden-tablet" *ngIf="doc.Type == 'image/png' || doc.Type == 'image/jpeg'">
        <div
          id="pdf-container{{ Docs[i].ID }}"
          class="pdf-container-document-side"
          [class.doc-selected]="bSharingImage && CurrentPDF.Name == doc.Name"
          [class.hide]="Docs[i].Display == false"
          (click)="sendClickInfoMessage('SwitchDocPreview-Desktop'); DocumentSwitch(false, 1, doc.ID)"
        >
          <img [src]="doc.SrcData" />
        </div>
      </ng-container>
    </div>
  </div>
  <div
    [class.view-text]="bTextCall"
    [class.view]="!bTextCall"
    [class.is-hidden-touch]="rightPanel.Display == true && rightPanel.Chat == true"
  >
    <ng-container *ngFor="let audioAttendee of AudioParticipants">
      <audio
        id="{{ audioAttendee.id }}"
        *ngIf="audioAttendee.stream != undefined"
        [muted]="false"
        [srcObject]="audioAttendee.stream"
        playsinline
        autoplay
      ></audio>
    </ng-container>
    <div
      class="arrow-container left-side pointer is-hidden-touch"
      (click)="sendClickInfoMessage('ToggleLeftPanel-Desktop'); manageLeftPanelState()"
    >
      <svg
        width="20"
        height="20"
        class="pointer svg-black"
        [class.fa-rotate-90]="leftPanel.Display"
        [class.fa-rotate-270]="!leftPanel.Display"
      >
        <image xlink:href="/assets/icons/arrow.svg" width="20" height="20"></image>
      </svg>
    </div>
    <div *ngIf="smallAppointment && !ScreenSharingEnabled" class="video-side-container video-conf-container-local-small">
      <div class="img-view background-opacity video-tag">
        <img id="preview" src="/assets/user.svg" *ngIf="LocalStream == undefined || LocalStreamVideo == false" class="img-view flex-row-container" />
        <audio
          id="{{ LocalStream.id }}"
          *ngIf="LocalStream != undefined && !LocalStreamVideo"
          [appNgmediaVideo]="LocalStream"
          playsinline
          autoplay
          [muted]="true"
        ></audio>
        <video
          id="{{ LocalStream.id }}"
          *ngIf="LocalStream != undefined && LocalStreamVideo"
          [appNgmediaVideo]="LocalStream"
          playsinline
          autoplay
          [muted]="true"
          class="video-preview video-view video-tag"
          [ngStyle]="setDomainMainColor()"
        ></video>
        <!--(click)="sendClickInfoMessage('MainAttendee-SmallApp-Desktop'); SetMainVideo()"-->
      </div>
    </div>
    <div
      class="video-view-container"
      *ngIf="
        MainAttendee == undefined &&
        !ScreenSharingEnabled &&
        !WhiteboardEnabled &&
        RemoteScreenStream == undefined &&
        !PDFVectSharingEnabled &&
        !bSharingImage
      "
    >
      <div *ngIf="noAppointment" class="video-side-container video-conf-container-remote-small">
        <div class="img-view background-opacity video-tag">
          <img id="preview" src="/assets/user.svg" *ngIf="LocalStream == undefined || LocalStreamVideo == false" class="img-view flex-row-container" />
          <audio
            id="{{ LocalStream.id }}"
            *ngIf="LocalStream != undefined && !LocalStreamVideo"
            [appNgmediaVideo]="LocalStream"
            playsinline
            autoplay
            [muted]="true"
          ></audio>
          <video
            id="{{ LocalStream.id }}"
            *ngIf="LocalStream != undefined && LocalStreamVideo"
            [appNgmediaVideo]="LocalStream"
            playsinline
            autoplay
            [muted]="true"
            class="video-preview video-view video-tag"
            [ngStyle]="setDomainMainColor()"
          ></video>
          <!-- (click)="sendClickInfoMessage('MainAttendee-NoApp-Desktop'); SetMainVideo()" -->
        </div>
      </div>
      <div
        *ngIf="!smallAppointment && !noAppointment"
        class="video-side-container"
        [className]="
          mediumAppointment ? 'video-conf-container-4' : largeAppointment ? 'video-conf-container-large' : 'video-conf-container-huge'
        "
      >
        <div class="img-view background-opacity video-tag">
          <img id="preview" src="/assets/user.svg" *ngIf="LocalStream == undefined || LocalStreamVideo == false" class="img-view flex-row-container" />
          <audio
            id="{{ LocalStream.id }}"
            *ngIf="LocalStream != undefined && !LocalStreamVideo"
            [appNgmediaVideo]="LocalStream"
            playsinline
            autoplay
            [muted]="true"
          ></audio>
          <video
            id="{{ LocalStream.id }}"
            *ngIf="LocalStream != undefined && LocalStreamVideo"
            [appNgmediaVideo]="LocalStream"
            playsinline
            autoplay
            [muted]="true"
            class="video-preview video-view video-tag"
            [ngStyle]="setDomainMainColor()"
          ></video>
          <!-- (click)="sendClickInfoMessage('MainAttendee-MediumApp-Desktop');SetMainVideo()" [className]="(mediumAppointment ? 'video-4-mode' : '')" -->
        </div>
        <!-- OVERLAY
                <div class="buttons-overlay-container">
                    <div class="button is-rounded is-transparent buttons-overlay-item">
                        <i class="fas fa-video"></i>
                    </div>
                    <div class="button is-rounded is-transparent buttons-overlay-item">
                        <i class="fas fa-microphone"></i>
                    </div>
                </div>
                <br>-->
        <div class="buttons-overlay-container-name">
          <div
            class="button is-small name-color"
            [ngStyle]="{ 'background-color': generatePastelColor(Public ? UserInfo.Name : Customer.Name) }"
          >
            <b>{{ Public ? UserInfo.Name : Customer.Name }}</b>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let attendee of Participants; index as i">
        <div *ngIf="smallAppointment" class="video-side-container video-conf-container-remote-small">
          <div *ngIf="attendee.Streams.length == 0" class="img-view background-opacity video-tag">
            <img src="/assets/user.svg" class="img-view flex-row-container" />
            <span class="img-view">
              <i class="fas fa-spinner fa-spin fa-3x spinner-stream"></i>
            </span>
          </div>
          <div *ngIf="attendee.Streams.length > 0" class="img-view background-opacity video-tag">
            <img src="/assets/user.svg" *ngIf="attendee.Streams[0].stream == undefined || !attendee.Streams[0].isVideo" class="img-view flex-row-container" />
            <audio
              id="{{ attendee.Streams[0].id }}"
              *ngIf="attendee.Streams[0].stream != undefined && !attendee.Streams[0].isVideo && attendee.Streams[0].isAudio"
              [muted]="true"
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
              class="video-view"
            ></audio>
            <video
              id="{{ attendee.Streams[0].id }}"
              class="video-view video-tag"
              *ngIf="attendee.Streams[0].stream != undefined && attendee.Streams[0].isVideo"
              [muted]="true"
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
              [ngStyle]="setDomainMainColor()"
            ></video>
            <!-- (click)="sendClickInfoMessage('MainAttendeeRemote-SmallApp-Desktop');SetMainVideo(attendee)" -->
          </div>

          <!-- OVERLAY
                    <div class="buttons-overlay-container">
                        <div class="button is-rounded is-transparent buttons-overlay-item">
                            <i class="fas fa-video"></i>
                        </div>
                        <div class="button is-rounded is-transparent buttons-overlay-item">
                            <i class="fas fa-microphone"></i>
                        </div>
                    </div>
                    <br>-->
          <div class="buttons-overlay-container-name">
            <div class="button is-small name-color" [ngStyle]="{ 'background-color': generatePastelColor(attendee.remoteName) }">
              <b>{{ attendee.remoteName }}</b>
            </div>
            <!--<img *ngIf="attendee.audioMuted" src="/assets/icons/micro-slash.svg" class="float-right" height="30" width="30">-->
            <i class="fas fa-microphone-slash fa-2x mic-muted float-right" *ngIf="attendee.audioMuted"></i>
          </div>
        </div>

        <div
          *ngIf="!smallAppointment && !noAppointment"
          class="video-side-container"
          [className]="
            mediumAppointment ? 'video-conf-container-4' : largeAppointment ? 'video-conf-container-large' : 'video-conf-container-huge'
          "
        >
          <div *ngIf="attendee.Streams.length == 0" class="img-view background-opacity video-tag">
            <img src="/assets/user.svg" class="img-view flex-row-container" />
            <span class="img-view">
              <i class="fas fa-spinner fa-spin fa-3x spinner-stream"></i>
            </span>
          </div>
          <div *ngIf="attendee.Streams.length > 0" class="img-view background-opacity video-tag">
            <img src="/assets/user.svg" *ngIf="attendee.Streams[0].stream == undefined || !attendee.Streams[0].isVideo" class="img-view flex-row-container" />
            <audio
              id="{{ attendee.Streams[0].id }}"
              *ngIf="attendee.Streams[0].stream != undefined && !attendee.Streams[0].isVideo && attendee.Streams[0].isAudio"
              [muted]="true"
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
              class="video-view"
            ></audio>
            <video
              id="{{ attendee.Streams[0].id }}"
              class="video-view video-tag"
              *ngIf="attendee.Streams[0].stream != undefined && attendee.Streams[0].isVideo"
              [muted]="true"
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
              [ngStyle]="setDomainMainColor()"
            ></video>
            <!-- (click)="sendClickInfoMessage('MainAttendeeRemote-MediumApp-Desktop');SetMainVideo(attendee)" [className]="(mediumAppointment ? 'video-4-mode' : '')" -->
          </div>

          <!-- OVERLAY
                    <div class="buttons-overlay-container">
                        <div class="button is-rounded is-transparent buttons-overlay-item">
                            <i class="fas fa-video"></i>
                        </div>
                        <div class="button is-rounded is-transparent buttons-overlay-item">
                            <i class="fas fa-microphone"></i>
                        </div>
                    </div>
                    <br>-->
          <div class="buttons-overlay-container-name">
            <div class="button is-small name-color" [ngStyle]="{ 'background-color': generatePastelColor(attendee.remoteName) }">
              <b>{{ attendee.remoteName }}</b>
            </div>
            <i class="fas fa-microphone-slash fa-2x mic-muted float-right" *ngIf="attendee.audioMuted"></i>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="control-bar"
      [class.is-hidden]="
        MainAttendee != undefined ||
        ScreenSharingEnabled ||
        WhiteboardEnabled ||
        RemoteScreenStream != undefined ||
        PDFVectSharingEnabled ||
        bSharingImage
      "
    >
      <!--<svg width="60" height="60" class="control-item pointer" *ngIf="LocalStreamAudio" (click)="sendClickInfoMessage('ControlBar-ToggleMic-Desktop');ToggleMic()">
                <image xlink:href="/assets/icons/micro.svg" width="60" height="60" *ngIf="LocalStreamAudioEnabled"/>
                <image xlink:href="/assets/icons/micro-slash.svg" width="60" height="60" *ngIf="!LocalStreamAudioEnabled"/>
            </svg>
            <svg width="60" height="60" class="control-item pointer" *ngIf="LocalStreamVideo" (click)="sendClickInfoMessage('ControlBar-ToggleVid-Desktop');ToggleVid()">
                <image xlink:href="/assets/icons/video.svg" width="60" height="60" *ngIf="LocalStreamVideoEnabled"/>
                <image xlink:href="/assets/icons/video-slash.svg" width="60" height="60" *ngIf="!LocalStreamVideoEnabled"/>
            </svg>-->

      <button
        class="button is-large mr-4"
        [class.is-vizu-background]="LocalStreamAudioEnabled"
        (click)="sendClickInfoMessage('ControlBar-ToggleMic-Desktop'); ToggleMic()"
      >
        <span class="icon">
          <svg
            width="50"
            height="50"
            class="pointer"
            *ngIf="LocalStreamAudio"
            style="position: absolute"
            [class.svg-white]="LocalStreamAudioEnabled"
          >
            <image xlink:href="/assets/icons/microphone.svg" width="50" height="50" *ngIf="LocalStreamAudioEnabled" />
            <image xlink:href="/assets/icons/mute.svg" width="50" height="50" *ngIf="!LocalStreamAudioEnabled" />
          </svg>
        </span>
      </button>

      <button
        class="button is-large mr-4"
        [class.is-vizu-background]="LocalStreamVideoEnabled"
        (click)="sendClickInfoMessage('ControlBar-ToggleVid-Desktop'); ToggleVid()"
      >
        <span class="icon">
          <svg
            width="50"
            height="50"
            class="pointer"
            *ngIf="LocalStreamVideo"
            style="position: absolute"
            [class.svg-white]="LocalStreamVideoEnabled"
          >
            <image xlink:href="/assets/icons/video.svg" width="50" height="50" *ngIf="LocalStreamVideoEnabled" />
            <image xlink:href="/assets/icons/video-slash.svg" width="50" height="50" *ngIf="!LocalStreamVideoEnabled" />
          </svg>
        </span>
      </button>

      <button
        class="button is-large is-vizu-background mr-4"
        *ngIf="State == 'started'"
        [matMenuTriggerFor]="menu"
        (click)="sendClickInfoMessage('ControlBar-ToggleDots-Touch'); ToggleDots()"
      >
        <span class="icon">
          <svg width="50" height="50" class="pointer svg-white" style="position: absolute">
            <image xlink:href="/assets/icons/menu-bar.svg" width="50" height="50" />
          </svg>
        </span>
      </button>
      <mat-menu #menu="matMenu" style="background-color: transparent">
        <span class="icon-text pointer" (click)="sendClickInfoMessage('ControlBarSharing-ShareLink-Desktop'); ShareLinkModal = true">
          <span>
            <i class="list-item pointer fa-solid fa-share-nodes control-item-fa"></i>
          </span>
          <span class="text-section">
            <p>{{ "room.share_link" | translate }}</p>
          </span>
        </span>

        <span class="icon-text pointer" (click)="sendClickInfoMessage('ControlBar-ToggleSS-MatMenu-Desktop'); ToggleScreenSharing()">
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/display.svg" width="20" height="20" *ngIf="!ScreenSharingEnabled" />
              <image xlink:href="/assets/icons/display-slash.svg" width="20" height="20" *ngIf="ScreenSharingEnabled" />
            </svg>
          </span>
          <span class="text-section">
            <p *ngIf="!ScreenSharingEnabled">{{ "room.share_screen" | translate }}</p>
            <p *ngIf="ScreenSharingEnabled">{{ "room.stop_share_screen" | translate }}</p>
          </span>
        </span>

        <span
          class="icon-text pointer"
          (click)="sendClickInfoMessage('ControlBar-ToggleChat-MatMenu-Desktop'); manageRightPanelState('chat', !rightPanel.Display)"
        >
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/chat-alt-on.svg" width="20" height="20" *ngIf="!rightPanel.Chat || !rightPanel.Display" />
              <image xlink:href="/assets/icons/chat-alt-off.svg" width="20" height="20" *ngIf="rightPanel.Chat && rightPanel.Display" />
            </svg>
          </span>
          <span class="text-section">
            <p *ngIf="rightPanel.Chat && rightPanel.Display">{{ "room.stop_chat" | translate }}</p>
            <p *ngIf="!rightPanel.Chat || !rightPanel.Display">{{ "room.chat" | translate }}</p>
          </span>
        </span>

        <!--<span class="icon-text pointer" (click)="ToggleWhiteboard()">
                    <span>
                        <i class="fas fa-chalkboard" class="control-item pointer"></i>
                    </span>
                    <span class="text-section">
                        <p>{{ 'room.whiteboard' | translate }}</p>
                    </span>
                </span>-->
        <!--<iframe src="https://miro.com/app/..." referrerpolicy="no-referrer-when-downgrade"></iframe>-->

        <span class="icon-text pointer" *ngIf="videoDevices.length > 1" (click)="SwapCamera()">
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/camera-switch.svg" width="20" height="20" />
            </svg>
          </span>
          <span class="text-section">
            <p>{{ "room.swap_camera" | translate }}</p>
          </span>
        </span>

        <!--<span class="icon-text pointer" (click)="OpenAddParticipantModal()">
                    <span>
                        <svg width="20" height="20" class="control-item pointer">
                            <image xlink:href="/assets/icons/profile-full.svg" width="20" height="20"/>
                        </svg>
                    </span>
                    <span class="text-section">
                        <p>{{ 'room.add_participant' | translate }}</p>
                    </span>
                </span>-->
      </mat-menu>
      <!--<svg width="60" height="60" class="control-item pointer" (click)="sendClickInfoMessage('ControlBar-Hangup-Desktop');Close()">
                <image xlink:href="/assets/icons/hangup.svg" width="60" height="60"/>
            </svg>-->
      <button class="button clear-call-btn is-large mr-4">
        <span class="icon">
          <svg width="50" height="50" class="pointer svg-white" (click)="sendClickInfoMessage('ControlBar-Hangup-Desktop'); Close()">
            <image xlink:href="/assets/icons/clear-call.svg" width="50" height="50" />
          </svg>
        </span>
      </button>
    </div>
    <ng-container
      *ngIf="
        MainAttendee != undefined &&
        !ScreenSharingEnabled &&
        !WhiteboardEnabled &&
        RemoteScreenStream == undefined &&
        !PDFVectSharingEnabled &&
        !bSharingImage
      "
    >
      <!--<img src="/assets/user.svg" *ngIf="MainAttendee.InFailed || MainAttendee.OutFailed || (!MainAttendee.Connected || MainAttendee.Stream == undefined)" class="mini-video-3">-->

      <!--<video id="{{MainAttendeeId}}" *ngIf="MainAttendee.Stream != undefined && !LocalStreamIsMain" [muted]="mainVideoMuted" [appNgmediaVideo]="MainAttendee.Stream" (click)="SetConf()" playsinline autoplay class="main-video" [ngStyle]="setDomainMainColor()"></video>-->
      <video
        id="{{ MainAttendeeId }}"
        *ngIf="MainAttendee.Stream != undefined && !LocalStreamIsMain"
        [muted]="true"
        [appNgmediaVideo]="MainAttendee.Stream"
        (click)="sendClickInfoMessage('SetConf-RemoteMainAttendee-Desktop'); ReinitConf()"
        playsinline
        autoplay
        class="main-video video-tag"
        [ngStyle]="setDomainMainColor()"
      ></video>
      <video
        id="{{ MainAttendeeId }}"
        *ngIf="MainAttendee.Stream != undefined && LocalStreamIsMain"
        [muted]="true"
        [appNgmediaVideo]="MainAttendee.Stream"
        (click)="sendClickInfoMessage('SetConf-LocalMainAttendee-Desktop'); ReinitConf()"
        playsinline
        autoplay
        class="main-video video-tag"
        [ngStyle]="setDomainMainColor()"
      ></video>
    </ng-container>
    <ng-container *ngIf="RemoteScreenStream != undefined">
      <video
        id="{{ RemoteScreenStreamId }}"
        (dblclick)="sendClickInfoMessage('SSRemote-Fullscreen-Desktop'); Fullscreen()"
        [appNgmediaVideo]="RemoteScreenStream"
        [muted]="true"
        playsinline
        autoplay
        class="remote-screen-video video-tag"
        [ngStyle]="setDomainMainColor()"
      ></video>
    </ng-container>
    <ng-container *ngIf="ScreenSharingEnabled">
      <video
        id="{{ ScreenStreamId }}"
        (dblclick)="sendClickInfoMessage('SSLocal-Fullscreen-Desktop'); Fullscreen()"
        [appNgmediaVideo]="ScreenStream"
        [muted]="true"
        playsinline
        autoplay
        class="remote-screen-video video-tag"
        [ngStyle]="setDomainMainColor()"
      ></video>
    </ng-container>
    <ng-container *ngIf="WhiteboardEnabled">
      <iframe class="miro-embedded-board" src="https://miro.com/app/..."></iframe>
    </ng-container>
    <ng-container *ngIf="PDFVectSharingEnabled">
      <div id="pdf-container" (scroll)="PDFScrolled($event)" class="pdf-space">
        <canvas id="pdfvect"></canvas>
      </div>
      <div
        [style.display]="Laser.Enabled ? 'block' : 'none'"
        [style.top]="Laser.Y + 'px'"
        [style.left]="Laser.X + 'px'"
        class="laser-pointer"
        [ngStyle]="{ 'background-color': generatePastelColor(Public ? UserInfo.Name : Customer.Name) }"
      ></div>
      <div
        *ngFor="let attendee of Participants; index as i"
        [style.display]="attendee.LaserState.Enabled ? 'block' : 'none'"
        [style.top]="attendee.LaserState.Y + 'px'"
        [style.left]="attendee.LaserState.X + 'px'"
        class="laser-pointer name-color"
        [ngStyle]="{ 'background-color': generatePastelColor(attendee.remoteName) }"
      ></div>
    </ng-container>
    <ng-container *ngIf="bSharingImage">
      <div id="pdf-container" (scroll)="PDFScrolled($event)" class="pdf-space">
        <img id="pdfvect" [src]="SharedImageData" class="shared-img-view" />
      </div>
      <div
        [style.display]="Laser.Enabled ? 'block' : 'none'"
        [style.top]="Laser.Y + 'px'"
        [style.left]="Laser.X + 'px'"
        class="laser-pointer"
        [ngStyle]="{ 'background-color': generatePastelColor(Public ? UserInfo.Name : Customer.Name) }"
      ></div>
      <div
        *ngFor="let attendee of Participants; index as i"
        [style.display]="attendee.LaserState.Enabled ? 'block' : 'none'"
        [style.top]="attendee.LaserState.Y + 'px'"
        [style.left]="attendee.LaserState.X + 'px'"
        class="laser-pointer name-color"
        [ngStyle]="{ 'background-color': generatePastelColor(attendee.remoteName) }"
      ></div>
    </ng-container>
    <div
      class="control-bar"
      *ngIf="MainAttendee != undefined || RemoteScreenStream != undefined || ScreenSharingEnabled || PDFVectSharingEnabled || bSharingImage"
    >
      <!--<svg width="60" height="60" class="control-item pointer" *ngIf="LocalStreamAudio" (click)="sendClickInfoMessage('ControlBarSharing-ToggleMic-Desktop');ToggleMic()">
                <image xlink:href="/assets/icons/micro.svg" width="60" height="60" *ngIf="LocalStreamAudioEnabled"/>
                <image xlink:href="/assets/icons/micro-slash.svg" width="60" height="60" *ngIf="!LocalStreamAudioEnabled"/>
            </svg>
            <svg width="60" height="60" class="control-item pointer" *ngIf="LocalStreamVideo" (click)="sendClickInfoMessage('ControlBarSharing-ToggleVid-Desktop');ToggleVid()">
                <image xlink:href="/assets/icons/video.svg" width="60" height="60" *ngIf="LocalStreamVideoEnabled"/>
                <image xlink:href="/assets/icons/video-slash.svg" width="60" height="60" *ngIf="!LocalStreamVideoEnabled"/>
            </svg>-->

      <button class="button is-large mr-4" [class.is-vizu-background]="LocalStreamAudioEnabled">
        <span class="icon">
          <svg
            width="50"
            height="50"
            class="pointer"
            *ngIf="LocalStreamAudio"
            (click)="sendClickInfoMessage('ControlBarSharing-ToggleMic-Desktop'); ToggleMic()"
            style="position: absolute"
            [class.svg-white]="LocalStreamAudioEnabled"
          >
            <image xlink:href="/assets/icons/microphone.svg" width="50" height="50" *ngIf="LocalStreamAudioEnabled" />
            <image xlink:href="/assets/icons/mute.svg" width="50" height="50" *ngIf="!LocalStreamAudioEnabled" />
          </svg>
        </span>
      </button>

      <button class="button is-large mr-4" [class.is-vizu-background]="LocalStreamVideoEnabled">
        <span class="icon">
          <svg
            width="50"
            height="50"
            class="pointer"
            *ngIf="LocalStreamVideo"
            (click)="sendClickInfoMessage('ControlBar-ToggleVid-Desktop'); ToggleVid()"
            style="position: absolute"
            [class.svg-white]="LocalStreamVideoEnabled"
          >
            <image xlink:href="/assets/icons/video.svg" width="50" height="50" *ngIf="LocalStreamVideoEnabled" />
            <image xlink:href="/assets/icons/video-slash.svg" width="50" height="50" *ngIf="!LocalStreamVideoEnabled" />
          </svg>
        </span>
      </button>

      <button
        class="button is-large is-vizu-background mr-4"
        *ngIf="State == 'started'"
        [matMenuTriggerFor]="menu"
        (click)="sendClickInfoMessage('ControlBarSharing-ToggleDots-Desktop'); ToggleDots()"
      >
        <span class="icon">
          <svg width="50" height="50" class="pointer svg-white" style="position: absolute">
            <image xlink:href="/assets/icons/menu-bar.svg" width="50" height="50" />
          </svg>
        </span>
      </button>

      <mat-menu #menu="matMenu" style="background-color: transparent">
        <!--<svg width="60" height="60" class="control-item pointer" (click)="ToggleScreenSharing()">
                    <image xlink:href="/assets/icons/screenshare.svg" width="60" height="60" *ngIf="!ScreenSharingEnabled"/>
                    <image xlink:href="/assets/icons/screenshare-slash.svg" width="60" height="60" *ngIf="ScreenSharingEnabled"/>
                </svg>-->

        <span class="icon-text pointer" (click)="sendClickInfoMessage('ControlBarSharing-ShareLink-Desktop'); ShareLinkModal = true">
          <span>
            <i class="list-item pointer fa-solid fa-share-nodes control-item-fa"></i>
          </span>
          <span class="text-section">
            <p>{{ "room.share_link" | translate }}</p>
          </span>
        </span>

        <span class="icon-text pointer" (click)="sendClickInfoMessage('ControlBarSharing-ToggleSS-Desktop'); ToggleScreenSharing()">
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/display.svg" width="20" height="20" *ngIf="!ScreenSharingEnabled" />
              <image xlink:href="/assets/icons/display-slash.svg" width="20" height="20" *ngIf="ScreenSharingEnabled" />
            </svg>
          </span>
          <span class="text-section">
            <p *ngIf="!ScreenSharingEnabled">{{ "room.share_screen" | translate }}</p>
            <p *ngIf="ScreenSharingEnabled">{{ "room.stop_share_screen" | translate }}</p>
          </span>
        </span>

        <span
          class="icon-text pointer"
          (click)="sendClickInfoMessage('ControlBarSharing-EnableChat-Desktop'); manageRightPanelState('chat', rightPanel.Display)"
          *ngIf="rightPanel.Cameras"
        >
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/chat-alt-on.svg" width="20" height="20" *ngIf="!rightPanel.Chat || !rightPanel.Display" />
              <image xlink:href="/assets/icons/chat-alt-off.svg" width="20" height="20" *ngIf="rightPanel.Chat && rightPanel.Display" />
            </svg>
          </span>
          <span class="text-section">
            <p *ngIf="rightPanel.Chat && rightPanel.Display">{{ "room.stop_chat" | translate }}</p>
            <p *ngIf="!rightPanel.Chat || !rightPanel.Display">{{ "room.chat" | translate }}</p>
          </span>
        </span>
        <span
          class="icon-text pointer"
          (click)="sendClickInfoMessage('ControlBarSharing-DisableChat-Desktop'); manageRightPanelState('chat', !rightPanel.Display)"
          *ngIf="!rightPanel.Cameras"
        >
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/chat-alt-on.svg" width="20" height="20" *ngIf="!rightPanel.Chat || !rightPanel.Display" />
              <image xlink:href="/assets/icons/chat-alt-off.svg" width="20" height="20" *ngIf="rightPanel.Chat && rightPanel.Display" />
            </svg>
          </span>
          <span class="text-section">
            <p *ngIf="rightPanel.Chat && rightPanel.Display">{{ "room.stop_chat" | translate }}</p>
            <p *ngIf="!rightPanel.Chat || !rightPanel.Display">{{ "room.chat" | translate }}</p>
          </span>
        </span>

        <!--<span class="icon-text pointer" (click)="ToggleWhiteboard()">
                    <span>
                        <i class="fas fa-chalkboard" class="control-item pointer"></i>
                    </span>
                    <span class="text-section">
                        <p>{{ 'room.whiteboard' | translate }}</p>
                    </span>
                </span>-->
        <!--<iframe src="https://miro.com/app/..." referrerpolicy="no-referrer-when-downgrade"></iframe>-->

        <span class="icon-text pointer" *ngIf="videoDevices.length > 1" (click)="SwapCamera()">
          <span>
            <svg width="20" height="20" class="list-item pointer">
              <image xlink:href="/assets/icons/camera-switch.svg" width="20" height="20" />
            </svg>
          </span>
          <span class="text-section">
            <p>{{ "room.swap_camera" | translate }}</p>
          </span>
        </span>
        <!--<span class="icon-text pointer" (click)="AddConfParticipant('0505050505')">
                    <span>
                        <svg width="20" height="20" class="control-item pointer">
                            <image xlink:href="/assets/icons/profile-full.svg" width="20" height="20"/>
                        </svg>
                    </span>
                    <span class="text-section">
                        <p>{{ 'room.add_participant' | translate }}</p>
                    </span>
                </span>-->
      </mat-menu>
      <!--<svg width="60" height="60" class="control-item pointer" (click)="sendClickInfoMessage('ControlBarSharing-Hangup-Desktop');Close()">
                <image xlink:href="/assets/icons/hangup.svg" width="60" height="60"/>
            </svg>-->
      <button class="button clear-call-btn is-large mr-4">
        <span class="icon">
          <svg width="50" height="50" class="pointer svg-white" (click)="sendClickInfoMessage('ControlBarSharing-Hangup-Desktop'); Close()">
            <image xlink:href="/assets/icons/clear-call.svg" width="50" height="50" />
          </svg>
        </span>
      </button>
    </div>
  </div>

  <div
    class="arrow-container right-side pointer is-hidden-touch"
    [class.animation-rightarrow-right-to-left]="rightPanel.Display"
    [class.animation-rightarrow-left-to-right]="!rightPanel.Display"
    (click)="sendClickInfoMessage('ToggleRightPanel-Desktop'); rightPanel.Display = !rightPanel.Display"
  >
    <svg
      width="20"
      height="20"
      class="arrow-right svg-black"
      [class.fa-rotate-90]="!rightPanel.Display"
      [class.fa-rotate-270]="rightPanel.Display"
    >
      <image xlink:href="/assets/icons/arrow.svg" width="20" height="20"></image>
    </svg>
  </div>

  <!--<div *ngIf="rightPanel.Display" class="is-hidden-touch" [class.animation-left-to-right]="!rightPanel.Chat" [class.video-side-text]="bTextCall" [class.video-side]="!bTextCall" [ngStyle]="setDomainMainColor()">-->
  <div
    class="is-hidden-touch rounded-top"
    style="background-color: white"
    [class.animation-rightpanel-right-to-left]="rightPanel.Display"
    [class.animation-rightpanel-left-to-right]="!rightPanel.Display"
    [class.video-side-text]="bTextCall"
    [class.video-side]="!bTextCall"
  >
    <!-- [ngStyle]="setDomainMainColor()" -->
    <div class="right-panel-menu">
      <div class="flex-row-container is-centered">
        <div class="doc-item-cursor" (click)="sendClickInfoMessage('RightPane-EnableChat-Desktop'); manageRightPanelState('chat', true)">
          <div [class.is-selected]="rightPanel.Chat === true" [class.rounded-top]="rightPanel.Chat === true">
            <img src="/assets/icons/chat-balloon.svg" width="42" height="42" />
          </div>
        </div>
        <div
          class="doc-item-cursor"
          (click)="sendClickInfoMessage('RightPane-EnableCam-Desktop'); manageRightPanelState('cam', true)"
          *ngIf="
            MainAttendee != undefined || ScreenSharingEnabled || RemoteScreenStream != undefined || PDFVectSharingEnabled || bSharingImage
          "
        >
          <div [class.is-selected]="rightPanel.Cameras === true" [class.rounded-top]="rightPanel.Cameras === true">
            <img src="/assets/icons/group.svg" width="42" height="42" />
          </div>
        </div>
      </div>
    </div>
    <!--OLD *ngIf="rightPanel.Cameras && (MainAttendee != undefined || ScreenSharingEnabled || RemoteScreenStream != undefined || PDFVectSharingEnabled || bSharingImage)"-->
    <div class="video-side-container" [className]="smallMiniAppointment ? 'mini-video-3' : 'mini-video'" *ngIf="rightPanel.Cameras">
      <div class="img-view background-opacity video-tag">
        <img src="/assets/user.svg" *ngIf="!LocalStreamVideo && !LocalStreamIsMain" class="img-view-mini flex-row-container" />
        <video
          class="video-preview video-tag"
          id="{{ LocalStream.id }}"
          *ngIf="!LocalStreamIsMain && LocalStream != undefined && LocalStreamVideo"
          [srcObject]="LocalStream"
          playsinline
          autoplay
          [muted]="true"
          [ngStyle]="setDomainMainColor()"
        ></video>
        <!-- (click)="sendClickInfoMessage('RightPane-MiniLocalMain-Desktop');SetMainVideo()" -->
        <audio
          id="{{ LocalStream.id }}"
          *ngIf="!LocalStreamIsMain && LocalStream != undefined && !LocalStreamVideo"
          [srcObject]="LocalStream"
          playsinline
          autoplay
          [muted]="true"
        ></audio>
        <!--<video class="video-tag" id="{{ScreenStreamMiniId}}" *ngIf="ScreenSharingEnabled" [srcObject]="ScreenStream" playsinline autoplay [muted]="true" [ngStyle]="setDomainMainColor()"></video>-->
      </div>
      <!-- OVERLAY
            <div class="buttons-overlay-container-mini">
                <div class="button is-rounded is-transparent" [className]="smallMiniAppointment ? 'buttons-overlay-item' : 'buttons-overlay-item-mini'">
                    <i class="fas fa-video"></i>
                </div>
                <div class="button is-rounded is-transparent" [className]="smallMiniAppointment ? 'buttons-overlay-item' : 'buttons-overlay-item-mini'">
                    <i class="fas fa-microphone"></i>
                </div>
            </div>
            <br>-->

      <div class="buttons-overlay-container-name" *ngIf="!LocalStreamIsMain">
        <div
          class="button is-small name-color"
          [ngStyle]="{ 'background-color': generatePastelColor(Public ? UserInfo.Name : Customer.Name) }"
        >
          {{ Public ? UserInfo.Name : Customer.Name }}
        </div>
      </div>
    </div>
    <div *ngIf="rightPanel.Cameras" style="width: 100%">
      <div
        *ngFor="let attendee of Participants; index as i"
        class="video-side-container"
        [className]="smallMiniAppointment ? 'mini-video-3' : 'mini-video'"
      >
        <div *ngIf="attendee.Streams.length == 0" class="img-view">
          <!--tester img-view-mini background-opacity video-tag-->
          <div class="img-view background-opacity video-tag">
            <img src="/assets/user.svg" class="img-view-mini flex-row-container" />
            <span class="icon">
              <i class="fas fa-arrow-circle-up"></i>
            </span>
          </div>
        </div>
        <div *ngIf="attendee.Streams.length > 0" class="img-view">
          <!--tester img-view-mini background-opacity video-tag-->
          <div class="img-view background-opacity video-tag">
            <img
              src="/assets/user.svg"
              *ngIf="attendee.Streams[0].stream == undefined || !attendee.Streams[0].isVideo"
              class="img-view-mini flex-row-container"
            />
            <audio
              id="{{ attendee.Streams[0].id }}"
              [muted]="true"
              *ngIf="
                attendee.Streams[0].stream != undefined &&
                attendee.isScreenSharing == false &&
                !attendee.Streams[0].isVideo &&
                attendee.Streams[0].isAudio
              "
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
            ></audio>
            <video
              id="{{ attendee.Streams[0].id }}"
              [muted]="true"
              *ngIf="
                !ScreenSharingEnabled &&
                RemoteScreenStream == undefined &&
                attendee.isScreenSharing == false &&
                attendee.Streams[0].stream != undefined &&
                attendee.Streams[0].isVideo &&
                attendee.showMiniVideo
              "
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
              [ngStyle]="setDomainMainColor()"
              class="video-tag"
            ></video>
            <!-- (click)="sendClickInfoMessage('RightPane-MiniLocalRemote-Desktop');SetMainVideo(attendee)" -->
            <video
              id="{{ attendee.Streams[0].id }}"
              [muted]="true"
              *ngIf="
                (ScreenSharingEnabled || RemoteScreenStream != undefined) &&
                attendee.isScreenSharing == false &&
                attendee.Streams[0].stream != undefined &&
                attendee.Streams[0].isVideo
              "
              [appNgmediaVideo]="attendee.Streams[0].stream"
              playsinline
              autoplay
              [ngStyle]="setDomainMainColor()"
              class="video-tag"
            ></video>
          </div>
        </div>
        <!-- OVERLAY
                <div [className]="smallMiniAppointment ? 'buttons-overlay-container-mini-3' : 'buttons-overlay-container-mini'">
                    <div class="button is-rounded is-transparent" [className]="smallMiniAppointment ? 'buttons-overlay-item' : 'buttons-overlay-item-mini'">
                        <i class="fas fa-video"></i>
                    </div>
                    <div class="button is-rounded is-transparent" [className]="smallMiniAppointment ? 'buttons-overlay-item' : 'buttons-overlay-item-mini'">
                        <i class="fas fa-microphone"></i>
                    </div>
                </div>
                <br>-->
        <div class="buttons-overlay-container-name" *ngIf="attendee.isScreenSharing == false && attendee.showMiniVideo">
          <div class="button is-small name-color" [ngStyle]="{ 'background-color': generatePastelColor(attendee.remoteName) }">
            {{ attendee.remoteName }}
          </div>
          <!--<img *ngIf="attendee.audioMuted" src="/assets/icons/micro-slash.svg" class="float-right" height="30" width="30">-->
          <i class="fas fa-microphone-slash fa-2x mic-muted float-right" *ngIf="attendee.audioMuted"></i>
        </div>
      </div>
    </div>
    <div class="message is-primary chat-box" class="chat-size chat-hidden" [class.chat-hidden]="!(rightPanel.Chat && State == 'started')">
      <div #messageBox [class.messages-box-text]="bTextCall" [class.messages-box]="!bTextCall">
        <ng-container *ngFor="let message of Messages; first as isFirst">
          <div *ngIf="isFirst" class="message-timestamp">{{ getHours(message.Timestamp) }}</div>
          <div [class.message-sent]="message.FromMe" [class.message-received]="!message.FromMe">
            <div class="from-name">{{ message.From }}</div>
            <div style="display: inline-flex">
              <img *ngIf="message.FromMe" src="{{ message.Logo }}" class="mr-1 chat-logo" />
              <div
                [style.display]="message.Type == 'chat' ? 'block' : 'grid'"
                [style.justify-items]="message.Type == 'file' && !message.FromMe ? 'end' : ''"
              >
                <div *ngIf="message.Type == 'chat'" class="message-text">
                  {{ message.Text }}
                </div>
                <div *ngIf="message.Type == 'file'" class="message-text">
                  <a href="javascript:void(0);" (click)="sendClickInfoMessage('Chat-DownloadFile'); message.Url()">{{ message.Text }}</a>
                </div>
                <div *ngIf="message.ImageData != undefined" class="message-text">
                  <img [src]="message.ImageData" />
                </div>
              </div>
              <img *ngIf="!message.FromMe" src="{{ message.Logo }}" class="ml-1 chat-logo" />
            </div>
          </div>
        </ng-container>
      </div>

      <div [class.chat-input]="!bTextCall" [class.chat-input-text]="bTextCall">
        <app-file-uploader
          [AppointmentID]="AppointmentID"
          [Limitations]="Limitations"
          [Customer]="Customer"
          (OpenUpgradeModal)="bUpgradeModal = true"
          class="margin-5"
          style="padding-top: 10px"
        ></app-file-uploader>
        <div class="control has-icons-right">
          <input
            *ngIf="!bTextCall"
            type="text"
            name="ChatText"
            id="sendMessageInputText"
            (keydown)="OnKeyDown($event)"
            [(ngModel)]="Data.ChatText"
            [class.input-text]="bTextCall"
            class="input border-radius-15"
            placeholder="Écrire dans le chat"
          />
          <textarea
            *ngIf="bTextCall"
            name="ChatText"
            id="sendMessageInputText"
            (keydown)="OnKeyDown($event)"
            [(ngModel)]="Data.ChatText"
            [class.input-text]="bTextCall"
            class="input border-radius-15"
            placeholder="Écrire dans le chat"
          ></textarea>
          <span
            class="icon is-small is-right pointer"
            (click)="sendClickInfoMessage('Chat-SendMessage'); sendChatMessage()"
            style="pointer-events: all"
          >
            <svg width="30" height="30">
              <image xlink:href="/assets/icons/plane.svg" width="30" height="30" />
            </svg>
          </span>
        </div>
        <!--<button type="submit" class="button is-danger">
                    <span class="icon">
                        <i class="fas fa-arrow-circle-up"></i>
                    </span>
                </button>-->
      </div>
    </div>
  </div>
  <div class="is-hidden-desktop" [class.panel-chat-size]="rightPanel.Chat && rightPanel.Display && State == 'started'">
    <div
      class="message is-primary chat-box"
      class="chat-size chat-hidden"
      [class.chat-hidden]="!(rightPanel.Chat && rightPanel.Display && State == 'started')"
    >
      <button
        class="delete is-danger leave-chat"
        aria-label="close"
        (click)="sendClickInfoMessage('Chat-CloseChat-Touch'); manageRightPanelState('chat', !rightPanel.Display)"
      ></button>
      <div #messageBox class="messages-box">
        <ng-container *ngFor="let message of Messages; first as isFirst">
          <div *ngIf="isFirst" class="message-timestamp">{{ getHours(message.Timestamp) }}</div>
          <div [class.message-sent]="message.FromMe" [class.message-received]="!message.FromMe">
            <div class="from-name">{{ message.From }}</div>
            <div style="display: inline-flex">
              <img *ngIf="message.FromMe" src="{{ message.Logo }}" class="mr-1" style="width: 40px; height: 40px" />
              <div [style.display]="message.Type == 'chat' ? 'contents' : 'block'">
                <div *ngIf="message.Type == 'chat'" class="message-text">
                  {{ message.Text }}
                </div>
                <div *ngIf="message.Type == 'file'" class="message-text">
                  <a href="javascript:void(0);" (click)="sendClickInfoMessage('Chat-DownloadFile-Touch'); message.Url()">{{
                    message.Text
                  }}</a>
                </div>
                <div *ngIf="message.ImageData != undefined" class="message-text">
                  <img [src]="message.ImageData" />
                </div>
              </div>
              <img *ngIf="!message.FromMe" src="{{ message.Logo }}" class="mr-1" style="width: 40px; height: 40px" />
            </div>
          </div>
        </ng-container>
      </div>

      <div class="chat-input">
        <app-file-uploader
          [AppointmentID]="AppointmentID"
          [Limitations]="Limitations"
          (Used)="FileTransferUsed()"
          class="margin-5"
          style="padding-top: 10px"
        ></app-file-uploader>
        <form (ngSubmit)="sendChatMessage()">
          <div class="field has-addons">
            <div class="control has-icons-right">
              <input
                type="text"
                id="sendMessageInputText"
                name="ChatText"
                (keydown)="OnKeyDown($event)"
                [(ngModel)]="Data.ChatText"
                class="input border-radius-15"
                placeholder="Écrire dans le chat"
              />
              <span
                class="icon is-small is-right pointer"
                (click)="sendClickInfoMessage('Chat-SendMessage'); sendChatMessage()"
                style="pointer-events: all"
              >
                <svg width="30" height="30">
                  <image xlink:href="/assets/icons/plane.svg" width="30" height="30" />
                </svg>
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="control-bar is-hidden-desktop" *ngIf="rightPanel.Chat && rightPanel.Display">
        <button
          class="button is-large mr-4"
          [class.is-vizu-background]="LocalStreamAudioEnabled"
          (click)="sendClickInfoMessage('ControlBar-ToggleMic-Touch'); ToggleMic()"
        >
          <span class="icon">
            <svg
              width="50"
              height="50"
              class="pointer"
              *ngIf="LocalStreamAudio"
              style="position: absolute"
              [class.svg-white]="LocalStreamAudioEnabled"
            >
              <image xlink:href="/assets/icons/microphone.svg" width="50" height="50" *ngIf="LocalStreamAudioEnabled" />
              <image xlink:href="/assets/icons/mute.svg" width="50" height="50" *ngIf="!LocalStreamAudioEnabled" />
            </svg>
          </span>
        </button>

        <button
          class="button is-large mr-4"
          [class.is-vizu-background]="LocalStreamVideoEnabled"
          (click)="sendClickInfoMessage('ControlBar-ToggleVid-Touch'); ToggleVid()"
        >
          <span class="icon">
            <svg
              width="50"
              height="50"
              class="pointer"
              *ngIf="LocalStreamVideo"
              style="position: absolute"
              [class.svg-white]="LocalStreamVideoEnabled"
            >
              <image xlink:href="/assets/icons/video.svg" width="50" height="50" *ngIf="LocalStreamVideoEnabled" />
              <image xlink:href="/assets/icons/video-slash.svg" width="50" height="50" *ngIf="!LocalStreamVideoEnabled" />
            </svg>
          </span>
        </button>

        <button
          class="button is-large is-vizu-background mr-4"
          *ngIf="State == 'started'"
          [matMenuTriggerFor]="menu"
          (click)="sendClickInfoMessage('ControlBar-ToggleDots-Touch'); ToggleDots()"
        >
          <span class="icon">
            <svg width="50" height="50" class="pointer svg-white" style="position: absolute">
              <image xlink:href="/assets/icons/menu-bar.svg" width="50" height="50" />
            </svg>
          </span>
        </button>

        <mat-menu #menu="matMenu" style="background-color: transparent">
          <span class="icon-text pointer" (click)="sendClickInfoMessage('ControlBarSharing-Sharelink-Touch'); ShareLinkModal = true">
            <span>
              <i class="list-item pointer fa-solid fa-share-nodes control-item-fa"></i>
            </span>
            <span class="text-section">
              <p>{{ "room.share_link" | translate }}</p>
            </span>
          </span>

          <span
            class="icon-text pointer"
            (click)="sendClickInfoMessage('ControlBar-ToggleChat-Touch'); manageRightPanelState('chat', !rightPanel.Display)"
          >
            <span>
              <svg width="20" height="20" class="list-item pointer">
                <image xlink:href="/assets/icons/chat-alt-on.svg" width="20" height="20" *ngIf="!rightPanel.Chat || !rightPanel.Display" />
                <image xlink:href="/assets/icons/chat-alt-off.svg" width="20" height="20" *ngIf="rightPanel.Chat && rightPanel.Display" />
              </svg>
            </span>
            <span class="text-section">
              <p *ngIf="rightPanel.Chat && rightPanel.Display">{{ "room.stop_chat" | translate }}</p>
              <p *ngIf="!rightPanel.Chat || !rightPanel.Display">{{ "room.chat" | translate }}</p>
            </span>
          </span>

          <!--<span class="icon-text pointer" (click)="ToggleWhiteboard()">
                        <span>
                            <i class="fas fa-chalkboard" class="control-item pointer"></i>
                        </span>
                        <span class="text-section">
                            <p>{{ 'room.whiteboard' | translate }}</p>
                        </span>
                    </span>-->
          <!--<iframe src="https://miro.com/app/..." referrerpolicy="no-referrer-when-downgrade"></iframe>-->

          <span class="icon-text pointer" *ngIf="videoDevices.length > 1" (click)="SwapCamera()">
            <span>
              <svg width="20" height="20" class="list-item pointer">
                <image xlink:href="/assets/icons/camera-switch.svg" width="20" height="20" />
              </svg>
            </span>
            <span class="text-section">
              <p>{{ "room.swap_camera" | translate }}</p>
            </span>
          </span>
        </mat-menu>
        <!--<svg width="40" height="40" class="control-item pointer" (click)="sendClickInfoMessage('ControlBar-Hangup-Touch');Close()">
                    <image xlink:href="/assets/icons/hangup.svg" width="40" height="40"/>
                </svg>-->
        <button class="button clear-call-btn is-large mr-4">
          <span class="icon">
            <svg width="50" height="50" class="pointer svg-white" (click)="sendClickInfoMessage('ControlBar-Hangup-Touch'); Close()">
              <image xlink:href="/assets/icons/clear-call.svg" width="50" height="50" />
            </svg>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div [class.is-active]="ExtensionNotInstalledModal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Installation de l'extension</p>
      <button (click)="sendClickInfoMessage('ExtWebRTC-Close'); ExtensionNotInstalledModal = false" class="delete"></button>
    </header>
    <section class="modal-card-body">
      <span *ngIf="!ExtensionInstalled">Une extension chrome est requise pour le partage d'ecran.</span>
      <span *ngIf="ExtensionInstalled">L'extension a été installée avec succès.</span>
    </section>
    <footer class="modal-card-foot">
      <a
        *ngIf="!ExtensionInstalled"
        target="_blank"
        rel="noopener noreferrer"
        href="https://chrome.google.com/webstore/detail/meetnow-screen-capture/dbabfolfgmkobccpkcpedoikilofmapf"
        class="button is-success"
        >Installer</a
      >
      <a
        *ngIf="ExtensionInstalled"
        (click)="sendClickInfoMessage('ExtWebRTC-Cancel'); ExtensionNotInstalledModal = false; ToggleScreenSharing()"
        class="button is-success"
        >ok</a
      >
    </footer>
  </div>
</div>

<div [class.is-active]="NeedFocusModal" class="modal pointer">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ "focus_modal.title" | translate }}</p>
      <button (click)="NeedFocusModal = false" class="delete"></button>
    </header>
    <section class="modal-card-body">
      <span>{{ "focus_modal.body1" | translate }}</span
      ><br />
      <span>{{ "focus_modal.body2" | translate }}</span>
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</div>

<app-vizu-upgrade-plan
  [CurrentSubscription]="SubscriptionType"
  [bUpgradeModalOpened]="bUpgradeModal"
  (CloseUpgradeModal)="bUpgradeModal = false"
></app-vizu-upgrade-plan>

<!--<div [class.is-active]="NeedAddParticipantModal" class="modal pointer">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ 'add_participant_modal.title' | translate }}</p>
            <button (click)="NeedAddParticipantModal = false" class="delete"></button>
        </header>
        <section class="modal-card-body">
            <span>{{ 'add_participant_modal.body' | translate }}</span>

            <div class="control add-part-container">
                <input type="text" [(ngModel)]="participantToAdd" class="input control-item" placeholder="ex: 0512345...">
                <button type="button" (click)="sendClickInfoMessage('AddParticipant');AddConfParticipant(participantToAdd)" class="button is-success control-item">
                    <span class="icon is-small">
                        <i class="fas fa-phone"></i>
                    </span>
                </button>
            </div>
            <div class="contact-card-content pointer" *ngFor="let Contact of Contacts | collaborator:participantToAdd" (click)="sendClickInfoMessage('AddCollaboratorParticipant');AddConfParticipant(Contact.To)">
                <ng-container [ngSwitch]="Contact.Type">
                    <div *ngSwitchCase="'User'">
                        <img src="{{Contact.data.LogoURI}}" *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''" height="80" width="80" style="border-radius:50%; padding:9px;">
                        <svg width="80" height="80" *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''">
                            <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                        </svg>
                    </div>
                    <div *ngSwitchCase="'UserContacts'">
                        <svg width="80" height="80">
                            <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                        </svg>
                    </div>
                </ng-container>

                <div class="contact-name">
                    <b class="classic-text">{{ Contact.DisplayName }}</b>
                </div>

                <svg width="25" height="25" class="pointer">
                    <image xlink:href="/assets/icons/call.svg" width="25" height="25"/>
                </svg>
            </div>
        </section>
        <footer class="modal-card-foot">
            <a (click)="sendClickInfoMessage('NeedAddParticipantModal-Cancel');NeedAddParticipantModal = false" class="button">Cancel</a>
        </footer>
    </div>
</div>-->

<div [class.is-active]="ShareLinkModal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ "room.modal_share_link_title" | translate }}</p>
      <button class="delete" (click)="sendClickInfoMessage('LinkQrCodeModal-Ok'); ShareLinkModal = false" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <div class="qrcode-container">
        <div class="font-large margin-10">
          <p>Lien vers la room</p>
          <div class="field has-addons">
            <div class="control">
              <input type="text" class="input" value="{{ roomLink }}" disabled />
            </div>
            <div class="control">
              <button class="button tooltip" (click)="CopyLink()" [ngStyle]="setDomainMainColor()">
                <i class="fas fa-copy is-white-button"></i>
                <span class="tooltiptext">{{ "shell.room.copy" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
        <img [src]="qrCodeSafeUrl" />
      </div>
    </section>
    <footer class="modal-card-foot">
      <a (click)="sendClickInfoMessage('LinkQrCodeModal-Ok'); ShareLinkModal = false" class="button">Ok</a>
    </footer>
  </div>
</div>

<div [class.is-active]="ScreenCaptureErrorModal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">Partage d'écran indisponible</p>
      <button (click)="sendClickInfoMessage('ScreenCaptureError-Close'); ScreenCaptureErrorModal = false" class="delete"></button>
    </header>
    <section class="modal-card-body">Le partage d'écran n'est pas disponible.</section>
    <footer class="modal-card-foot">
      <a (click)="sendClickInfoMessage('ScreenCaptureError-Cancel'); ScreenCaptureErrorModal = false" class="button">Ok</a>
    </footer>
  </div>
</div>

<div [class.is-active]="deniedPermissionsModal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content" style="width: 800px">
    <div class="modal-card-head">
      <p class="modal-card-title" *ngIf="deniedPermissions.audio && deniedPermissions.video">
        {{ "room.permissions_denied_cam_and_mic_title" | translate }}
      </p>
      <p class="modal-card-title" *ngIf="deniedPermissions.audio && !deniedPermissions.video">
        {{ "room.permissions_denied_mic_title" | translate }}
      </p>
      <p class="modal-card-title" *ngIf="!deniedPermissions.audio && deniedPermissions.video">
        {{ "room.permissions_denied_cam_title" | translate }}
      </p>
      <button class="delete" (click)="clearDeniedPermissionsModal()" aria-label="close"></button>
    </div>
    <div class="modal-card-body">
      <div class="flex-row-container">
        <img src="/assets/denied-permissions.gif" class="shadow-light" width="300px" height="300px" />
        <div class="font-large margin-10">
          <p>
            {{ "room.permissions_denied_content_1" | translate }}
          </p>
          <br />
          <p *ngIf="deniedPermissions.audio && deniedPermissions.video">
            {{ "room.permissions_denied_content_cam_and_mic_2" | translate }}
          </p>
          <p *ngIf="deniedPermissions.audio && !deniedPermissions.video">
            {{ "room.permissions_denied_content_mic_2" | translate }}
          </p>
          <p *ngIf="!deniedPermissions.audio && deniedPermissions.video">
            {{ "room.permissions_denied_content_cam_2" | translate }}
          </p>
          <br />
          <p>
            {{ "room.permissions_denied_content_3" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="modal-card-foot"></div>
  </div>
</div>

<div [class.is-active]="PDFErrorModal" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">Ficher invalide</p>
      <button (click)="sendClickInfoMessage('PDFError-Close'); PDFErrorModal = false" class="delete"></button>
    </header>
    <section class="modal-card-body">Les fichiers selectionés ne sont pas des images ou des pdf valides.</section>
    <footer class="modal-card-foot">
      <a (click)="sendClickInfoMessage('PDFError-Cancel'); PDFErrorModal = false" class="button">Ok</a>
    </footer>
  </div>
</div>

<div [class.is-active]="Disconnected && !Public" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ "shell.disconnected.title" | translate }}</p>
    </header>
    <section class="modal-card-body">
      {{ "shell.disconnected.text" | translate }}
    </section>
    <footer class="modal-card-foot">
      <a (click)="sendClickInfoMessage('ConnectionError-ReloadWebpage'); Reload()" class="button is-danger">{{
        "shell.disconnected.reload" | translate
      }}</a>
    </footer>
  </div>
</div>

<div [class.is-active]="Limitations?.InConfRestriction && b5MinRemaining" class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ "room.appointment_close_soon_title" | translate }}</p>
    </header>
    <section class="modal-card-body">
      {{ "room.appointment_close_soon_text1" | translate }}
      {{ "room.appointment_close_soon_text2" | translate }}
    </section>
    <footer class="modal-card-foot">
      <a (click)="sendClickInfoMessage('FreeTimeLimit-Upgrade'); b5MinRemaining = false; bUpgradeModal = true" class="button is-primary">{{
        "room.upgrade" | translate
      }}</a>
      <a (click)="sendClickInfoMessage('FreeTimeLimit-Ok'); b5MinRemaining = false" class="button">Ok</a>
    </footer>
  </div>
</div>

<div *ngIf="!Public && missedParticipant !== undefined" class="offline-notification">
  <p>
    <b>{{ missedParticipant.Name }}</b> {{ "room.participant_missed_text1" | translate }}
  </p>
  <br />
  <p>{{ "room.participant_missed_text2" | translate }}</p>
  <br />
  <p>{{ "room.participant_missed_text3" | translate }}</p>
</div>

<div *ngIf="!Public && LockedAttendees.length > 0" class="attendee-locked-container">
  <div *ngFor="let attendee of LockedAttendees" class="notification" [ngStyle]="getZIndex(attendee)">
    <!-- style="z-index: 1 !important;-->
    <button (click)="sendClickInfoMessage('RejectAttendee1'); RejectLockedAttendee(attendee)" class="delete"></button>
    <div class="room-request">{{ attendee.Data.Name }} souhaite rejoindre la salle</div>
    <div class="flex-row-container justify-right">
      <button
        type="button"
        (click)="sendClickInfoMessage('AnswerAttendee'); AnswerLockedAttendee(attendee)"
        class="button is-success width-50per"
      >
        <span class="icon is-small">
          <i class="fas fa-check"></i>
        </span>
      </button>

      <div class="dropdown is-hoverable width-50per">
        <button type="button" class="button is-danger margin-2 width-100per">
          <span class="icon is-small">
            <i class="fas fa-ban"></i>
          </span>
        </button>

        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content position-fixed">
            <a class="dropdown-item" (click)="sendClickInfoMessage('RejectAttendee2'); RejectLockedAttendee(attendee)">
              {{ "shell.room.reject_call" | translate }}
            </a>
            <a class="dropdown-item" (click)="sendClickInfoMessage('OpenRejectMessage'); DisplayRefuseMessage(attendee)">
              {{ "shell.room.reject_with_message" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="notification" *ngIf="inCallRefuseMessageShown">
    <div>
      <div class="control has-icons-right">
        <textarea
          class="textarea refuse-input"
          id="textarea-refuse-message"
          rows="2"
          placeholder="{{ 'shell.room.reject_message_placeholder' | translate }}"
          [(ngModel)]="inCallRefuseMessage"
          (ngModelChange)="valueRejectMessageChange()"
          maxlength="140"
        ></textarea>
        <span class="icon is-small is-right" (click)="inCallRefuseMessage = ''" style="height: 60px; width: 30px">
          <i class="fas fa-times"></i>
        </span>
      </div>
      <span>{{ inCallRefuseMessageRemaining }} / 140</span>
    </div>
    <div class="margin-20">
      <button type="button" class="button is-danger" (click)="RefuseIncomingSpecificCallWithMessage()">
        {{ "shell.room.reject" | translate }}
      </button>
    </div>
  </div>
  <audio src="/assets/ringing.m4a" playsinline autoplay loop></audio>
</div>
<app-notification #notificationComponent></app-notification>
