import { Component, OnInit } from '@angular/core'

import { EmitterService } from '../../services/emitter.service'
import { RtcService } from '../../services/rtc.service'
import { AuthService } from '../../services/auth.service'

import { Appointment, Collaborator, Customer, Limitations } from '../../domain/domain'

@Component({
    selector: 'app-vizu-shell',
    templateUrl: './vizu-shell.component.html',
    styleUrls: ['./vizu-shell.component.scss'],
})
export class VizuShellComponent implements OnInit {
    State = 'appointment_closed'
    Disconnected = false

    Customer: Customer
    Contacts: Collaborator[]
    Rooms: Appointment[]
    Limitations: Limitations
    
    Room: string = ""


    pingInterval: any;

    WaitingRequest = true
    timeInterval: any;

    audioDeviceSelected: string = ""
    videoDeviceSelected: string = ""

    bTelephonyMode = false;

    UserInfo = {
        Name: '',
        ReconnectId: '',
        AppointmentID: '',
    }

    makeCallParams = {
        to: "",
        from: "",
        reconnectId: ""
    }

    constructor(
        private rtc: RtcService,
        private NgmsService: EmitterService,
        private auth: AuthService,
    )
    {
        this.NgmsService.EmitNgmsSettings$.subscribe({
            next: this.handleNewEmittedMsg.bind(this)
        })
    }

    handleNewEmittedMsg(data: any){
        let messageRcv = data.message;
        let dataRcv = data.data;
        if (messageRcv == "OnMeData") {
            this.onmedata(dataRcv)
        } else if (messageRcv == "OnContactsChanged") {
            this.oncontactschanged(dataRcv)
        } else if (messageRcv == "OnConfRoomsChanged") {
            this.onconfroomschanged(dataRcv)
        } else if (messageRcv == "OnAuthorization") {
            this.onauthorization()
        }
    }

    handleLimitations() {
        if (this.Customer.SubscriptionType === "pro") {
            this.Limitations = {
                MessagePanelHidden: false,
                TeamPanelHidden: true,
                RoomPanelHidden: true,
                InConfRestriction: false
            }
        } else if (this.Customer.SubscriptionType === "enterprise") {
            this.Limitations = {
                MessagePanelHidden: false,
                TeamPanelHidden: false,
                RoomPanelHidden: false,
                InConfRestriction: false
            }
        } else {
            this.Limitations = {
                MessagePanelHidden: true,
                TeamPanelHidden: true,
                RoomPanelHidden: true,
                InConfRestriction: true
            }
        }
    }

    async ngOnInit(): Promise<void> {
        console.log("State = "+this.State)
        
        this.rtc.notifyOnWebSocketConnected         = this.notifyonwebsocketconnected.bind(this)
        this.rtc.notifyOnWebSocketError             = this.notifyonwebsocketerror.bind(this)
        this.rtc.notifyOnMediaStreamAdded           = this.onmediastreamadded.bind(this)
        this.rtc.notifyOnStreamAdded                = this.onstreamadded.bind(this)
        this.rtc.notifyOnStreamRemoved              = this.onstreamremoved.bind(this)
        this.rtc.notifyOnCallConnected              = this.onconnected.bind(this)
        this.rtc.notifyOnCallDisconnected           = this.ondisconnected.bind(this)
        this.rtc.notifyOnParticipantAdded           = this.onparticipantadded.bind(this)
        this.rtc.notifyOnParticipantUpdated         = this.onparticipantupdated.bind(this)
        this.rtc.notifyOnParticipantRemoved         = this.onparticipantremoved.bind(this)
        this.rtc.notifyOnAuthorization              = this.onauthorization.bind(this)
        this.rtc.notifyOnMessage                    = this.onmessage.bind(this)
        this.rtc.notifyOnMessageDataChannel         = this.onmessagedatachannel.bind(this)
        this.rtc.notifyOnMessageDeliveryProgress    = this.onmessagedeliverprogress.bind(this)

        /*this.rtc.Events.subscribe(
            async (msg): Promise<void> => {
                switch (msg.Type) {
                case 'appointment_started':
                    this.State = 'appointment_started'
                    //this.InRoom = true
                    //this.WaitingRequest = false
                    break
                case 'appointment_closed':
                    this.State = 'appointment_closed'
                    //this.InRoom = false 
                    break
                    break
                case 'connection_replaced':
                case 'disconnected':
                    this.State = 'appointment_closed'
                    //this.InRoom = false
                    this.Disconnected = true
                    break
                }
            },
            (error) => {
            },
            () => {
            },
        )*/
        //this.rtc.getAuthorization(this.Customer.Username, null, null, this.Customer.Name)
        //this.rtc.getAuthorization(this.Customer.Username, this.Customer.Username, "bob", this.Customer.Name)
        if (this.bTelephonyMode) {
            this.rtc.InitRegisterCall(this.Customer.Username)   
        }

        if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
            var dateToday = new Date()
            var dateLastDisconnection: any = new Date(Number(localStorage.getItem("VizuLive\\LastConnection")))
            var diffDates: any = dateToday.getTime() - dateLastDisconnection.getTime();
            var Seconds_from_T1_to_T2 = diffDates / 1000;
            var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
            if (Seconds_Between_Dates < 30) {
                if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
                    this.UserInfo.AppointmentID = localStorage.getItem("VizuLive\\AppointmentID")
                    this.UserInfo.ReconnectId = localStorage.getItem("VizuLive\\ReconnectID")
                    let CustomerName = localStorage.getItem("VizuLive\\CustomerName")
                    let CustomerId = localStorage.getItem("VizuLive\\CustomerId")
                    let initCustomer = {
                        id: CustomerId,
                        Name: CustomerName,
                        Enabled: true,
                        Username: "",
                        Password: "",
                        Email: "",
                        ProfileId: "",
                        DomainId: "",
                        LogoURI: "",
                        ServiceProvider: false,
                        SubscriptionType: "free",
                        Administrator: false,
                        State: "",
                        Room: ""
                    }
                    this.Customer = initCustomer

                    this.rtc.callbackWebSocket()
                }
            }
            this.deleteReconnectItems()
        } else {
            this.deleteReconnectItems()
        }

        /*window.onbeforeunload = function () {
            const appointmentID = this.Room
            const dateLeft = Date.now()
            //OLD BEHAVIOUR
            //this.rtc.clearCall()
            let reconnectId = this.rtc.getReconnectId()
            localStorage.setItem("VizuLive\\LastConnection", dateLeft.toString())
            localStorage.setItem("VizuLive\\AppointmentID", appointmentID)
            localStorage.setItem("VizuLive\\Name", this.Customer.Username)
            localStorage.setItem("VizuLive\\ReconnectID", reconnectId)
        }.bind(this)*/
    }

    deleteReconnectItems() {
        if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
            localStorage.removeItem('VizuLive\\LastConnection');
        }
        if (localStorage.getItem("VizuLive\\AppointmentID") !== "null" && localStorage.getItem("VizuLive\\AppointmentID") !== null) {
            localStorage.removeItem('VizuLive\\AppointmentID');
        }
        if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
            localStorage.removeItem('VizuLive\\ReconnectID');
        }
        if (localStorage.getItem("VizuLive\\CustomerName") !== "null" && localStorage.getItem("VizuLive\\CustomerName") !== null) {
            localStorage.removeItem("VizuLive\\CustomerName")
        }
        if (localStorage.getItem("VizuLive\\CustomerId") !== "null" && localStorage.getItem("VizuLive\\CustomerId") !== null) {
            localStorage.removeItem("VizuLive\\CustomerId")
        }
    }

    notifyonwebsocketconnected() {
        if (this.UserInfo.ReconnectId !== "") {
            this.EnterRoom({ waitingRequest: true, appointment: this.UserInfo.AppointmentID })
            let myThis = this
            return new Promise((resolve: any, reject: any) => {
                myThis.rtc.GetUserMedia(true, null)
                .then(
                    function(success: any) {
                        myThis.deleteReconnectItems()
                        if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
                            myThis.makeCallParams = {
                                to: myThis.UserInfo.AppointmentID,
                                from: myThis.Customer.Username,
                                reconnectId: myThis.UserInfo.ReconnectId
                            }
                            myThis.rtc.getAuthorization(myThis.UserInfo.AppointmentID, true, myThis.Customer.Room, myThis.UserInfo.ReconnectId)
                        } else {
                            myThis.rtc.InitMakeCall(myThis.UserInfo.AppointmentID, true, myThis.Customer.Username, myThis.UserInfo.ReconnectId, false)
                        }
                        resolve(success)
                    },
                    function(error: any) {
                        myThis.deleteReconnectItems()
                        reject(error)
                    }
                )
            })
        }
    }

    onmedata(userData: any) {
        this.Customer = userData
        this.handleLimitations()
    }

    oncontactschanged(contacts: Collaborator[]) {
        this.Contacts = contacts
    }

    onconfroomschanged(event: any) {
        this.Rooms = event
        this.Room = this.Rooms[0].To
        /*for (let i = 0; i < this.Contacts.length; i++) {
            this.Contacts[i].DisplayName = this.Contacts[i].DisplayName.charAt(0).toUpperCase() + this.Contacts[i].DisplayName.slice(1);
        }*/
    }

    onmediastreamadded(event: any) {
        console.log(event)
        console.log("--------SHELL / ON MEDIASTREAM ADDED")
        this.NgmsService.EmitNgmsSettings(event, "OnMediaStreamAdded");
    }

    onstreamadded(event: any) {
        console.log(event)
        console.log("--------SHELL / ON STREAM ADDED")
        this.NgmsService.EmitNgmsSettings(event, "OnStreamAdded");
    }

    onstreamremoved(event: any) {
        console.log("--------SHELL / ON STREAM REMOVED")
        this.NgmsService.EmitNgmsSettings(event, "OnStreamRemoved");
    }

    onparticipantadded(event: any) {
        console.log("--------SHELL / ON PARTICIPANT ADDED")
        this.NgmsService.EmitNgmsSettings(event, "OnParticipantAdded");
    }

    onparticipantupdated(event: any) {
        console.log("--------SHELL / ON PARTICIPANT UPDATED")
        this.NgmsService.EmitNgmsSettings(event, "OnParticipantUpdated");
    }

    onparticipantremoved(event: any) {
        console.log("--------SHELL / ON PARTICIPANT REMOVED")
        this.NgmsService.EmitNgmsSettings(event, "OnParticipantRemoved");
    }

    onauthorization() {
        //Only be called for reconnection
        if (this.makeCallParams.to !== undefined && this.makeCallParams.reconnectId !== "") {
            this.rtc.InitMakeCall(this.makeCallParams.to, true, this.makeCallParams.from, this.makeCallParams.reconnectId, true)
            this.makeCallParams = {
                to: "",
                from: "",
                reconnectId: ""
            }
        }
    }

    onconnected(event: any) {
        let myThis = this
        this.rtc.sendLogMessage()
        .subscribe(() => {
        })
        let reconnectId = this.rtc.getReconnectId()
        if (this.rtc.cookies?.performance) {
			localStorage.setItem("VizuLive\\Name", this.UserInfo.Name);
			if (reconnectId !== undefined) {
				localStorage.setItem("VizuLive\\ReconnectID", reconnectId);
				localStorage.setItem("VizuLive\\CustomerId", this.Customer.id);
				localStorage.setItem("VizuLive\\CustomerName", this.Customer.Name);
				localStorage.setItem("VizuLive\\AppointmentID", this.rtc.options.to);
			}
		}
		this.timeInterval = setInterval(function () {
			const dateLeft = Date.now();
			if (myThis.rtc.cookies?.performance) {
				localStorage.setItem("VizuLive\\LastConnection", dateLeft.toString())
			}
		}, 1000)
        this.NgmsService.EmitNgmsSettings(event, "OnConnected");
    }

    ondisconnected(event: any) {
        //this.rtc.CloseUserMedia()
        console.log("--------SHELL / ON DISCONNECTED")
        if (this.timeInterval != undefined) {
            clearInterval(this.timeInterval)
            if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
                localStorage.removeItem('VizuLive\\LastConnection');
            }
            if (localStorage.getItem("VizuLive\\AppointmentID") !== "null" && localStorage.getItem("VizuLive\\AppointmentID") !== null) {
                localStorage.removeItem('VizuLive\\AppointmentID');
            }
            if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
                localStorage.removeItem('VizuLive\\ReconnectID');
            }
            //Clear the reconectId or the page will try to reconnect after dropping the call
            this.UserInfo = {
                Name: '',
                ReconnectId: '',
                AppointmentID: '',
            }
        }
        this.State = 'appointment_closed'
        if (this.bTelephonyMode) {
            this.rtc.InitRegisterCall(this.Customer.Username)
        }

        this.NgmsService.EmitNgmsSettings(event, "OnDisconnected");
    }

    Logout(): void {
        /*if (localStorage.getItem("VizuLive\\RememberMe") !== "null" && localStorage.getItem("VizuLive\\RememberMe") !== null) {
            localStorage.setItem('VizuLive\\RememberMe', "false");
        }*/
        localStorage.setItem('VizuLive\\Logout', "true");
        this.auth.Unauthenticate()
        .subscribe(() => {
            this.rtc.closeWebSocket()
            this.auth.UnauthenticatedSig.next()
        })
    }

    notifyonwebsocketerror(errorEvent: any) {
        console.log("Error connecting Web Socket: ", errorEvent)
        this.Logout()
    }

    onmessage(event: any) {
        console.log("--------SHELL / ON MESSAGE")
        this.NgmsService.EmitNgmsSettings(event, "OnMessage");
    }

    onmessagedatachannel(event: any) {
        console.log("--------SHELL / ON MESSAGE")
        this.NgmsService.EmitNgmsSettings(event, "OnMessageDataChannel");
    }

    onmessagedeliverprogress(event: any) {
        console.log("--------SHELL / ON MESSAGE DELIVERY PROGRESS")
        this.NgmsService.EmitNgmsSettings(event, "OnMessageDeliveryProgress");
    }

    /*EnterRoom(waitingRequest: boolean) {
        this.WaitingRequest = waitingRequest
        this.State = 'appointment_started'
    }*/

    /*onconnected() {
        console.log("----------CONNECTED / SHELL----------")
        this.State = 'appointment_started'
    }

    ondisconnected() {
        console.log("----------DISCONNECTED----------")
        this.State = 'appointment_closed'
    }*/

    EnterRoom(values: any) {
        this.WaitingRequest = values.waitingRequest
        /*if (values.appointment === this.Customer.Username) {
            this.Room = this.Customer
        }*/
        console.log(this.Rooms)

        this.Room = values.appointment
        //console.log(this.Room)
        this.State = 'appointment_started'
    }

    EnterSpecificRoom(room: string) {
        this.Room = room;
        console.log(room)
    }

    Reload(): void {
        window.location.href = window.location.href
    }
}

async function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
            resolve(undefined)
        }, duration)
    })
}