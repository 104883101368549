import { Pipe, PipeTransform } from '@angular/core';
import { Collaborator } from '../domain/domain'

@Pipe({ name: 'collaborator' })
export class CollaboratorPipe implements PipeTransform {
  transform(values: Collaborator[], filter: string): Collaborator[] {
    if (!filter || filter.length === 0) {
      return values;
    }

    if (values.length === 0) {
      return values;
    }

    return values.filter((value: Collaborator) => {
      const nameFound =
        value.DisplayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      
      if (nameFound) {
        return value;
      }
    });
  }
}