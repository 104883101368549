<button class="button is-primary btnOpen mx-1" style="border-radius: 0px 0px 10px 10px !important;" (click)="openModal()">
    <span class="icon">
        <i class="fas fa-comment"></i>
    </span>
    <span>{{ 'feedback.title' | translate }}</span>
</button>
<div class="modal" [ngClass]="{'is-active': isModalActive}">
    <div class="modal-background" (click)="closeModal()"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ 'feedback.title' | translate }}</p>
            <button class="delete" aria-label="close" (click)="closeModal()"></button>
        </header>
        <section class="modal-card-body">
            <form [formGroup]="feedbackForm" id="feedbackForm" (ngSubmit)="onSubmit()">
                <div class="field">
                    <label class="label">{{ 'feedback.type.title' | translate }}</label>
                    <div class="control">
                        <div class="select">
                            <select name="feedbackType" formControlName="typeSelected" required>
                                <option value="">{{ 'feedback.type.options.select' | translate }}</option>
                                <option value="Problem">{{ 'feedback.type.options.problem' | translate }}</option>
                                <option value="Suggestion">{{ 'feedback.type.options.suggestion' | translate }}</option>
                                <option value="Help">{{ 'feedback.type.options.help' | translate }}</option>
                                <option value="Other">{{ 'feedback.type.options.other' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label class="label">{{ 'feedback.message.title' | translate }}</label>
                    <div class="control">
                        <textarea class="textarea" name="feedbackMessage" placeholder="{{ 'feedback.message.placeholder' | translate }}" formControlName="textInput" required></textarea>
                    </div>
                </div>
<!--
                <div class="field">
                    <label class="label">Suivi</label>
                    <div class="control">
                        <label class="radio">
                            <input type="radio" name="followUp" value="noReturn" checked>
                            Aucun
                        </label>
                        <label class="radio">
                            <input type="radio" name="followUp" value="email">
                            Email
                        </label>
                        <label class="radio">
                            <input type="radio" name="followUp" value="phone">
                            Téléphone
                        </label>
                    </div>
                </div>
-->
            </form>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-primary" type="submit" form="feedbackForm" [disabled]="feedbackForm.invalid">{{ 'feedback.button.submit' | translate }}</button>
            <button class="button" (click)="closeModal()">{{ 'feedback.button.cancel' | translate }}</button>
        </footer>
    </div> 
</div>

<app-notification #notificationComponent></app-notification>