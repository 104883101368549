<app-vizu-header [Public]="false">
    <div style="display: flex;">
        <div *ngIf="bAccountConfig" class="is-right is-hoverable">
            <button class="button is-danger aligned-top" (click)="sendClickInfoMessage('CloseAccountConfig');Config()">
                <span>Fermer</span>
            </button>
        </div>
        <div *ngIf="bAdminConfig" class="is-right is-hoverable">
            <button class="button is-danger aligned-top" (click)="sendClickInfoMessage('CloseAdminConfig');Administrate()">
                <span>Fermer</span>
            </button>
        </div>
        <div *ngIf="!bAccountConfig && !bAdminConfig" class="dropdown is-hoverable">
            <!--<i class="fas fa-user-cog fa-4x"></i>-->
            <div class="dropdown-trigger" *ngIf="Customer !== undefined">
                <!--<button class="button aligned-top">
                    <span>Menu</span>
                    <span class="icon is-small">
                        <i class="fas fa-angle-down"></i>
                    </span>
                </button>-->
                <!--<i class="fas fa-webcam fa-4x"></i>-->
                <img src="{{Customer.LogoURI}}" *ngIf="Customer.LogoURI != undefined && Customer.LogoURI != ''" height="80" width="80" style="border-radius:50%; padding:9px;">
                <svg width="80" height="80" *ngIf="Customer.LogoURI == undefined || Customer.LogoURI == ''">
                    <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                </svg>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <a (click)="Config()" class="dropdown-item">
                        {{ 'shell.menu.account_config' | translate }}
                    </a>
                    <a *ngIf="!bAdminConfig && IsAdmin" (click)="sendClickInfoMessage('OpenAdminConfig'); Administrate()" class="dropdown-item">
                        Admin
                    </a>
                    <a (click)="sendClickInfoMessage('Logout');Logout()" class="dropdown-item">
                        {{ 'shell.disconnected.title' | translate }}
                    </a>
                </div>
            </div>
        </div>
        <app-button-feedback></app-button-feedback>
    </div>
    <audio id="welcome_audio" autoplay>
        <source src="/assets/welcome.m4a" type="audio/x-m4a">
    </audio>
</app-vizu-header>

<app-vizu-audio-perm *ngIf="isAudioPermAllowed === false" (click)="sendClickInfoMessage('AudioPermissionsEnabled');modifyAudioPerm()"></app-vizu-audio-perm>


<app-loading-screen *ngIf="DomainData == undefined">
    <ng-container *ngIf="rtc.bWebsocketReconnecting">
        <p>{{ 'common.link_failure1' | translate }}</p>
        <p>{{ 'common.link_failure2' | translate }}</p>
        <br/>
    </ng-container>
</app-loading-screen>
<div *ngIf="DomainData !== undefined" class="image-background" [ngStyle]="setImageBackground()" style="overflow:auto;">
    <div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>

    <div *ngIf="bAccountConfig">
        <app-vizu-account-config [errorMonitor]="errorMonitor" class="full-config-height"></app-vizu-account-config>
    </div>

    <div *ngIf="bAdminConfig">
        <app-vizu-admin-config [AdminCompany]="DomainData.CustomData.FriendlyName" [isServiceProvider]="isServiceProvider" class="full-config-height"></app-vizu-admin-config>
    </div>

    <div class="main-space is-hidden-touch" *ngIf="!bAccountConfig && !bAdminConfig">
        <div class="rooms-title" *ngIf="!bAccountConfig && !bAdminConfig">{{ 'shell.room.my_room' | translate }}</div>
        <div class="content-perso margin-top-content margin-bottom-content" *ngIf="!bAccountConfig && !bAdminConfig">
            <div class="main-card-title has-text-primary" [ngStyle]="setDomainAltColor()">
                <div *ngIf="Customer != undefined" class="shadowed classic-text">{{ 'shell.room.room_of' | translate }}{{Customer.Name}}</div>
                
            </div>
            <div class="main-card-content perso-card-content" [class]="Customer?.SubscriptionType === 'free' || Customer?.SubscriptionType === 'free' ? 'padding-30' : ''">
                <div>
                    <div class="flex-column-container room-element">
                        <div class="conf-icon-wrapper" *ngIf="Customer !== undefined">
                            <!--<app-people-icon [On]="Attendees.length > 0"></app-people-icon>-->
                            <img src="{{Customer.LogoURI}}" *ngIf="Customer.LogoURI != undefined && Customer.LogoURI != ''" height="80" width="80" style="border-radius:50%; padding:9px;">
                            <svg width="80" height="80" *ngIf="Customer.LogoURI == undefined || Customer.LogoURI == ''">
                                <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                            </svg>
                            <div *ngIf="Attendees.length > 0" class="conf-participant-count-circle">
                                <div class="conf-participant-count">{{ Attendees.length }}</div>
                            </div>
                        </div>
                        <div *ngIf="Attendees.length != 0" class="attendees-container">
                            <div>{{ 'shell.room.attendees' | translate }} :</div>
                            <span *ngFor="let attendee of Attendees" class="conf-participant-name">{{ attendee }} </span>
                        </div>
                        <div *ngIf="Attendees.length == 0" class="attendees-container">
                            <div>{{ 'shell.room.waiting' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex-column-container room-element">
                        <input *ngIf="Customer !== undefined" type="text" id="{{Customer.Room}}" class="link-content">
                        <!--<button *ngIf="Customer !== undefined" (click)="sendClickInfoMessage('CopyLinkPrivate');CopyLink(Customer.Room)" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                            <span>{{ 'shell.room.copy_link' | translate }}</span>
                            <svg width="20" height="20">
                                <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                            </svg>
                        </button>
                        <a *ngIf="Customer !== undefined && mobileDevice" id="{{Customer.Room}}-invite" [href]="Customer.Uri" target="_blank">
                            <button *ngIf="Customer !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                                <span>{{ 'shell.room.send_sms' | translate }} </span>
                            </button>
                        </a>
                        <a *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" [href]="Customer.Uri" target="_blank">
                            <button *ngIf="Customer !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPrivateLink');" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                                <span>{{ 'shell.room.send_email' | translate }} </span>
                            </button>
                        </a>-->
                        <div class="flex-row-container">
                            <button *ngIf="Customer !== undefined" (click)="sendClickInfoMessage('CopyLinkPrivate');CopyLink(Customer.Room)" class="button is-vizu-look is-white-button room-element shadowed tooltip" [ngStyle]="setDomainMainColor()">
                                <svg width="20" height="20">
                                    <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                                </svg>
                                <span class="tooltiptext">{{ 'shell.room.copy_link' | translate }}</span>
                            </button>

                            <div *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" class="room-element dropdown is-hoverable" [href]="Customer.Uri" target="_blank">
                                <button *ngIf="Customer !== undefined && !mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fa-solid fa-share-nodes font-x-large"></i>
                                </button>
                                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div class="dropdown-content">
                                        <a *ngIf="Customer !== undefined && mobileDevice" id="{{Customer.Room}}-invite" class="dropdown-item" [href]="Customer.Uri" target="_blank">
                                            {{ 'shell.room.send_sms' | translate }}
                                        </a>
                                        <a *ngIf="Customer !== undefined && !mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeLink(Customer);">
                                            {{ 'shell.room.qrcode_link' | translate }}
                                        </a>
                                        <a *ngIf="Customer !== undefined && !mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodeSmsPrivateLink'); displayQrCodeSms(Customer);">
                                            {{ 'shell.room.qrcode_sms' | translate }}
                                        </a>
                                        <a *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" class="dropdown-item" [href]="Customer.Uri" target="_blank">
                                            {{ 'shell.room.send_email' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!--<a *ngIf="Customer !== undefined && mobileDevice" id="{{Customer.Room}}-invite-sms" class="tooltip shadowed room-element" [href]="Customer.Uri" target="_blank">
                                <button *ngIf="Customer !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fas fa-sms font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.send_sms' | translate }}</span>
                            </a>
                            <a *ngIf="Customer !== undefined && !mobileDevice" class="tooltip shadowed room-element">
                                <button *ngIf="Customer !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeSms(Customer);" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fas fa-sms font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.qrcode_sms' | translate }}</span>
                            </a>
                            <a *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite-mail" class="tooltip shadowed room-element" [href]="Customer.Uri" target="_blank">
                                <button *ngIf="Customer !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="far fa-envelope font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.send_email' | translate }}</span>
                            </a>-->
                        </div>
                        <button class="button is-join-button is-vizu-look room-element" (click)="sendClickInfoMessage('EnterPrivateRoom'); EnterOwnRoom(PrivateRoom.Name)" *ngIf="!bTelephonyMode">
                            <div class="enter-button-out pointer" *ngIf="!waitingToEnter">
                                <div class="enter-button-in pointer">
                                    <span class="enter-button pointer">
                                    </span>
                                </div>
                            </div>
                            <b class="button-text pointer" *ngIf="!waitingToEnter">{{ 'shell.room.join' | translate }} ?</b>
                            <div *ngIf="waitingToEnter">
                                <!--<i class="fas fa-spinner fa-spin"></i>-->
                                <app-loader-points></app-loader-points>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="rooms-title" *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig">{{ 'shell.room.title' | translate }}</div>
        <div class="content content-size margin-top-content margin-bottom-content" *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig">
            <div class="columns">
                <div *ngFor="let Room of Rooms; index as i" class="main-card column">
                    <div class="main-card-title has-text-primary" [ngStyle]="setDomainAltColor()">
                        <div class="shadowed classic-text" [ngStyle]="setDomainAltColor()">{{ 'shell.room.room' | translate }} {{Room.data.Name}}</div>
                        <!-- ROOM LOCKED FEATURE
                        <div *ngIf="Room != undefined" class="room-lock-switch tooltip">
                            <svg width="25" height="25">
                                <image *ngIf="Room.data.Locked" xlink:href="/assets/icons/lock-white.svg" width="25" height="25" (clic)="ToggleRoomLock(false, i)"/>
                                <image *ngIf="!Room.data.Locked" xlink:href="/assets/icons/unlock-white.svg" width="25" height="25" (clic)="ToggleRoomLock(true, i)"/>
                            </svg>
                            <span *ngIf="Room.data.Locked" class="tooltiptext">{{ 'shell.room.locked_tooltip' | translate }}</span>
                            <span *ngIf="!Room.data.Locked" class="tooltiptext">{{ 'shell.room.unlocked_tooltip' | translate }}</span>
                        </div>-->
                    </div>
                    <div class="main-card-content conf-card-content">
                        <div class="flex-column-container room-element">
                            <div class="conf-icon-wrapper">
                                <app-people-icon [On]="Room.ParticipantsCount > 0" [isTouch]="false"></app-people-icon>
                            </div>
                            <span *ngIf="Room.ParticipantsCount > 0" class="enter-button-out stack-count-to-image">{{ Room.ParticipantsCount }}</span>
                            <div *ngIf="Room.ParticipantsCount == 0" class="attendees-container">
                                <div>{{ 'shell.room.free' | translate }}</div>
                            </div>
                            <div *ngIf="Room.ParticipantsCount > 0" class="attendees-container">
                                <div>{{ 'shell.room.busy' | translate }}</div>
                            </div>
                        </div>
                        <input *ngIf="Room != undefined" type="text" id="{{Room.data.Room}}" class="link-content">
                        <!--<button class="button is-vizu-look is-white-button room-element shadowed" (click)="sendClickInfoMessage('CopyLinkPublic');CopyLink(Room.data.Room)" [ngStyle]="setDomainMainColor()">
                            <span button-text>{{ 'shell.room.copy_link' | translate }}</span>
                            <svg width="20" height="20">
                                <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                            </svg>
                        </button>
                        <a *ngIf="Room !== undefined && mobileDevice" id="{{Room.data.Room}}-invite" [href]="Room.Uri" target="_blank">
                            <button *ngIf="Room !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPublicLink');" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                                <span>{{ 'shell.room.send_sms' | translate }} </span>
                            </button>
                        </a>
                        <a *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" [href]="Room.Uri" target="_blank">
                            <button *ngIf="Room !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPublicLink');" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                                <span>{{ 'shell.room.send_email' | translate }} </span>
                            </button>
                        </a>-->
                        <div class="flex-row-container">
                            <button class="button is-vizu-look is-white-button room-element shadowed tooltip" (click)="sendClickInfoMessage('CopyLinkPublic');CopyLink(Room.data.Room)" [ngStyle]="setDomainMainColor()">
                                <svg width="20" height="20">
                                    <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                                </svg>
                                <span button-text class="tooltiptext">{{ 'shell.room.copy_link' | translate }}</span>
                            </button>

                            <div *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" class="room-element dropdown is-hoverable" [href]="Room.Uri" target="_blank">
                                <button *ngIf="Room !== undefined && !mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fa-solid fa-share-nodes font-x-large"></i>
                                </button>
                                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div class="dropdown-content">
                                        <a *ngIf="Room !== undefined && mobileDevice" id="{{Customer.Room}}-invite" class="dropdown-item" [href]="Room.Uri" target="_blank">
                                            {{ 'shell.room.send_sms' | translate }}
                                        </a>
                                        <a *ngIf="Room !== undefined && !mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeLink(Room);">
                                            {{ 'shell.room.qrcode_link' | translate }}
                                        </a>
                                        <a *ngIf="Room !== undefined && !mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodeSmsPrivateLink'); displayQrCodeSms(Room);">
                                            {{ 'shell.room.qrcode_sms' | translate }}
                                        </a>
                                        <a *ngIf="Room !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" class="dropdown-item" [href]="Room.Uri" target="_blank">
                                            {{ 'shell.room.send_email' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!--<a *ngIf="Room !== undefined && mobileDevice" id="{{Room.data.Room}}-invite" class="tooltip shadowed room-element" [href]="Room.Uri" target="_blank">
                                <button *ngIf="Room !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPublicLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fas fa-sms font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.send_sms' | translate }}</span>
                            </a>
                            <a *ngIf="Room !== undefined && !mobileDevice" class="tooltip shadowed room-element">
                                <button *ngIf="Room !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('QrCodePublicLink'); displayQrCodeSms(Room);" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fas fa-sms font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.qrcode_sms' | translate }}</span>
                            </a>
                            <a *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" class="tooltip shadowed room-element" [href]="Room.Uri" target="_blank">
                                <button *ngIf="Room !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPublicLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="far fa-envelope font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.send_email' | translate }}</span>
                            </a>-->
                        </div>
                        <button class="button is-join-button is-vizu-look room-element" (click)="sendClickInfoMessage('EnterPublicRoom');EnterOwnRoom(Room.data.Room)">
                            <div class="enter-button-out pointer" *ngIf="!waitingToEnter">
                                <div class="enter-button-in pointer">
                                    <span class="enter-button pointer">
                                    </span>
                                </div>
                            </div>
                            <b class="button-text pointer" *ngIf="!waitingToEnter">{{ 'shell.room.join' | translate }} ?</b>
                            <div *ngIf="waitingToEnter">
                                <!--<i class="fas fa-spinner fa-spin"></i>-->
                                <app-loader-points></app-loader-points>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content content-size margin-top-content margin-bottom-content" *ngIf="Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig && GlobalMenu.Rooms">

        </div>

        <div [class.contacts-container]="!GlobalMenu.Messages" [class.contacts-container-messages]="GlobalMenu.Messages" *ngIf="!bAccountConfig && !bAdminConfig">
            <!--<div class="contacts-title">
                {{ 'shell.room.collaborators' | translate }}
            </div>-->
            <div [class.conf-card-content]="!GlobalMenu.Messages" [class.conf-card-content-messages]="GlobalMenu.Messages" [class.messages-box] = "GlobalMenu.Messages && messagesRecepient != ''">
                <div class="header-container">
                    <div class="flex-column-container" (click)="GlobalMenuClicked('contacts')">
                        <i class="fas fa-user-friends fa-3x pointer" [ngClass]="(GlobalMenu.Contacts == true ? 'menu-enabled' : 'menu-disabled')">
                        </i>
                        <p class="pointer">{{ 'shell.room.team' | translate }}</p>
                    </div>
                    <!--<i class="fas fa-history fa-3x pointer" [ngClass]="(GlobalMenu.History == true ? 'menu-enabled' : 'menu-disabled')" (click)="GlobalMenuClicked('history')"></i>-->
                    <div class="flex-column-container" class="pointer" (click)="GlobalMenuClicked('messages')">
                        <div class="icon-notification">
                            <i class="fas fa-comments fa-3x pointer" [ngClass]="(GlobalMenu.Messages == true ? 'menu-enabled' : 'menu-disabled')"></i>
                            <div *ngIf="hasNewMessages()" class="conf-participant-count"></div>
                        </div>
                        <p class="pointer">Messages</p>
                    </div>
                </div>

                <div class="control top-container" *ngIf="Limitations?.TeamPanelHidden === false && GlobalMenu.Contacts">
                    <input type="text" name="SearchBar" [(ngModel)]="SearchField" class="input input-text-bar" placeholder="Rechercher un nom...">
                    <!--<button type="button" (click)="sendClickInfoMessage('TelephonyCall');EnterOtherRoom(SearchField)" class="button is-success margin-2">
                        <span class="icon is-small">
                            <i class="fas fa-phone"></i>
                        </span>
                    </button>-->
                </div>
                <div class="control top-container" *ngIf="GlobalMenu.Messages">
                    <input type="text" name="SearchBar" [(ngModel)]="SearchField" class="input input-text-bar" placeholder="Rechercher un nom...">
                    <!--<button type="button" (click)="sendClickInfoMessage('TelephonyCall');EnterOtherRoom(SearchField)" class="button is-success margin-2">
                        <span class="icon is-small">
                            <i class="fas fa-phone"></i>
                        </span>
                    </button>-->
                </div>

                <div *ngIf="GlobalMenu.Messages && messagesRecepient != ''" class="classic-text centered marged-bordered-bottom full-width">
                    <br/>
                    <i class="fas fa-xl fa-arrow-left pointer" (click)="this.messagesRecepient = ''; this.messagesDisplayed = []"></i>
                    <b class="messages-name">{{ messagesRecepient }}</b>
                    <i class="fas fa-xl fa-ban pointer" (click)="deleteChatMessages(this.messagesTo, this.messagesRecepient)"></i>
                </div>

                <div #messageContainer class="main-container">
                    <ng-container *ngIf="GlobalMenu.Contacts">
                        <div *ngIf="Limitations?.TeamPanelHidden === false">
                            <div class="contact-card-row" *ngFor="let Contact of Contacts | collaborator:SearchField">
                                <ng-container [ngSwitch]="Contact.Type">
                                    <div *ngSwitchCase="'User'">
                                        <img *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''" src="{{Contact.data.LogoURI}}" height="80" width="80" style="border-radius:50%; padding: 7px;">
                                        <img *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''" src="/assets/icons/profile-default.svg" width="80" height="80"/>
                                        
                                        <app-vizu-presence [bMessageView]="false" [Contact]="Contact" [DomainData]="DomainData"></app-vizu-presence>
                                    </div>
                                    
                                    <div *ngSwitchCase="'UserContacts'">
                                        <img src="/assets/icons/profile-default.svg" width="80" height="80"/>
                                        <!--<svg width="80" height="80">
                                            <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                                        </svg>-->
                                    </div>
                                    <div *ngSwitchCase="'Anonymous'">
                                        <img src="/assets/icons/profile-default.svg" width="80" height="80"/>
                                        <!--<svg width="80" height="80">
                                            <image xlink:href="/assets/icons/profile-anonymous-default.svg" width="80" height="80"/>
                                        </svg>-->
                                    </div>
                                </ng-container>
            
                                <div class="content-name-60">
                                    <b class="classic-text">{{ Contact.DisplayName }}</b>
                                </div>
        
                                <svg width="25" height="25" class="pointer" (click)="sendClickInfoMessage('EnterCollaboratorRoom');EnterOtherRoom(Contact.data.Room)">
                                    <image xlink:href="/assets/icons/call.svg" width="25" height="25"/>
                                </svg>
                                <!-- <i class="far fa-envelope icon-size pointer" (click)="displayMessages(Contact.To, Contact.DisplayName)"></i> -->
                                <i [matMenuTriggerFor]="menuContact" class="fa-solid fa-bars icon-size pointer"></i>
                                <mat-menu #menuContact="matMenu">
                                    <button mat-menu-item (click)="displayMessages(Contact.To, Contact.DisplayName)"><i class="far fa-envelope icon-size pointer"></i>Messagerie</button>
                                    <button mat-menu-item (click)="CopyLink(Contact.data.Room)" id="{{Contact.data.Room}}"><i class="icon-size pointer"><svg width="25" height="25"><image xlink:href="/assets/icons/copy.svg" width="25" height="25"/></svg></i>Lien</button>
                                </mat-menu>
                            </div>
                        </div>

                        <!-- subscription type free ou pro -->
                        <div *ngIf="Customer.SubscriptionType !== 'enterprise' && Limitations?.TeamPanelHidden" class="panel-hidden-container team-limitation-text">
                            <div class="margin-center big-text text-center">
                                {{ 'shell.room.team_panel_hidden1' | translate }}
                            </div><br>
                            <div class="margin-center big-text text-center">
                                {{ 'shell.room.team_panel_hidden2' | translate }}
                            </div><br>
                            <input type="button" class="button is-success" value="{{ 'room.upgrade' | translate }}" (click)="bUpgradeModal = true" style="margin: 0px 60px;"/>
                        </div>

                        <!-- entreprise cloisonnée -->
                        <div *ngIf="Customer.SubscriptionType === 'enterprise' && Limitations?.TeamPanelHidden" class="panel-hidden-container">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="GlobalMenu.History">
                        <div class="contact-card-row pointer" *ngFor="let Cdr of Cdrs | collaborator:SearchField" (click)="sendClickInfoMessage('EnterCollaboratorRoom');EnterOtherRoomFromHistory(Cdr)">
                            <b class="classic-text" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')">{{ Cdr.ToUri }}</b>
                            <i class="fas fa-arrow-left arrow" *ngIf="Cdr.In" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')"></i>
                            <i class="fas fa-arrow-right arrow" *ngIf="!Cdr.In" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')"></i>
                            <p class="classic-text" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')">{{ Cdr.DateFormatted }}</p>
                        </div>
                    </ng-container>
    
                    <ng-container *ngIf="GlobalMenu.Messages && messagesRecepient == ''">
                        <div class="contact-card-row" *ngFor="let Contact of Contacts_and_Anonymous() | collaborator:SearchField" (click)="displayMessages(Contact.To, Contact.DisplayName)" [ngClass]="(!DomainData.SharedUsers && Contact.Type !== 'Anonymous') ? 'hidden' : ''"> <!-- Hide if not SharedUsers && not anonymous message -->
                            <div *ngIf="DomainData.SharedUsers && Contact.Type === 'User'">
                                <img *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''" height="80" width="80" src="{{Contact.data.LogoURI}}" class="profile-custom" />
                                <img *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''" height="80" width="80" src="/assets/icons/profile-default.svg" />
                                
                                <app-vizu-presence [bMessageView]="true" [Contact]="Contact" [DomainData]="DomainData"></app-vizu-presence>
                            </div>
                            <div *ngIf="DomainData.SharedUsers && Contact.Type === 'UserContacts'">
                                <img height="80" width="80" src="/assets/icons/profile-default.svg" />
                            </div>
                            <div *ngIf="Contact.Type === 'Anonymous'">
                                <img height="80" width="80" src="/assets/icons/profile-anonymous-default.svg" />
                            </div>
                                
                            <div *ngIf="DomainData.SharedUsers || (!DomainData.SharedUsers && Contact.Type === 'Anonymous')" class="content-name-60">
                                <b class="classic-text">{{ Contact.DisplayName }}</b>
                                <p *ngIf="!Limitations?.MessagePanelHidden" class="message-preview">{{ Contact?.Messages[Contact.Messages.length - 1]?.Content }}</p>
                                <p *ngIf="Limitations?.MessagePanelHidden" class="message-preview">{{ Contact?.Messages[Contact.Messages.length - 1]?.Content | translate }}</p>
                            </div>

                            <div *ngIf="DomainData.SharedUsers || (!DomainData.SharedUsers && Contact.Type === 'Anonymous')" class="conf-icon-wrapper contact-messages-count-circle" [ngStyle]="{'visibility': (Contact.UnreadMessagesNumber !== undefined && Contact.UnreadMessagesNumber > 0) ? 'visible' : 'hidden'}">
                                <div class="conf-participant-count">{{ Contact.UnreadMessagesNumber }}</div>
                            </div>

                            <i *ngIf="DomainData.SharedUsers" class="far fa-envelope icon-size pointer" (click)="displayMessages(Contact.To, Contact.DisplayName)"></i>
                        </div>
                    </ng-container>
    
                    <!--<ng-container *ngIf="GlobalMenu.Messages && messagesRecepient != ''">
                        <div class="classic-text centered">
                            <br/>
                            <b>{{ messagesRecepient }}</b>
                        </div>
                        <hr>
                        <div *ngFor="let messageDisplayed of messagesDisplayed">
                            <div class="classic-text" [ngClass]="(messageDisplayed.In ? 'remote-side' : 'local-side')">
                                <p class="text-message">{{ messageDisplayed.Content }}</p>
                            </div>
                        </div>
                    </ng-container>-->

                    <ng-container *ngIf="GlobalMenu.Messages && messagesRecepient != ''" class="messages-container">
                        <div *ngFor="let messageDisplayed of messagesDisplayed;" (mouseenter)="isMessageHover[messageDisplayed.id] = true" (mouseleave)="isMessageHover[messageDisplayed.id] = false">
                            <div class="classic-text" *ngIf="messageDisplayed.Contact !== ''" [ngClass]="(messageDisplayed.To == messagesTo ? 'local-side' : 'remote-side')">
                                <div *ngIf="messageDisplayed.To == messagesTo" [hidden]="!isMessageHover[messageDisplayed.id]" class="message-edit pointer"><i class="fa-solid fa-ellipsis-vertical" style="position: absolute; width: 10px; text-align: center; top: calc(50% - 10px);" [matMenuTriggerFor]="messageEditor"></i></div>
                                <p class="text-message" [attr.data-time]="formatTimestamp(messageDisplayed.Timestamp)">{{ messageDisplayed.Contact }}</p>
                            </div>
                            <div class="classic-text" [ngClass]="(messageDisplayed.To == messagesTo ? 'local-side' : 'remote-side')">
                                <div *ngIf="messageDisplayed.To == messagesTo" [hidden]="!isMessageHover[messageDisplayed.id]" class="message-edit pointer"><i class="fa-solid fa-ellipsis-vertical" style="position: absolute; width: 10px; text-align: center; top: calc(50% - 10px);" [matMenuTriggerFor]="messageEditor"></i></div>
                                <p *ngIf="!this.Limitations?.MessagePanelHidden" class="text-message" [attr.data-time]="formatTimestamp(messageDisplayed.Timestamp)">{{ messageDisplayed.Content }}</p>
                                <p *ngIf="this.Limitations?.MessagePanelHidden" class="text-message" [attr.data-time]="formatTimestamp(messageDisplayed.Timestamp)">{{ messageDisplayed.Content | translate }}</p>
                                <div *ngIf="messageDisplayed.To != messagesTo" [hidden]="!isMessageHover[messageDisplayed.id]" class="message-edit pointer"><i class="fa-solid fa-ellipsis-vertical" style="width: 10px; text-align: center;" [matMenuTriggerFor]="messageEditor"></i></div>
                                
                                <mat-menu #messageEditor="matMenu" [xPosition]="(messageDisplayed.To == messagesTo ? 'before' : 'after')">
                                    <button mat-menu-item disabled><i class="fa-solid fa-pen-to-square" style="margin: 5px"></i>Edit</button>
                                    <button mat-menu-item (click)="deleteChatMessage(messageDisplayed.id)"><i class="fa-solid fa-trash" style="margin: 5px"></i>Delete</button>
                                </mat-menu>
                            </div>
                        </div>
                    </ng-container>
                </div>
                
                <div class="chat-input" *ngIf="GlobalMenu.Messages && messagesRecepient != '' && !isAnonymeDisplay">
                    <input type="text" name="ChatText" (keydown)="OnKeyDown($event)" [(ngModel)]="messageData" class="input input-text-bar border-20" placeholder="Aa">
                    <i class="fa-regular fa-xl fa-face-smile pointer" style="align-content: center; margin-left: 5px;" [matMenuTriggerFor]="menu"></i>
                    <button class="no-button pointer" (click)="sendClickInfoMessage('Chat-SendMessage'); sendChatMessage();" id="sendMessageSubmit">
                        <svg width="30" height="30" class="pointer">
                            <image xlink:href="/assets/icons/send.svg" width="30" height="30"/>
                        </svg>
                    </button>
        
                    <mat-menu #menu="matMenu">
                    <ng-template matMenuContent>
                        <div class="skin-picker" (click)="$event.stopPropagation()">
                            <div></div>
                            <div *ngFor="let skin of skinTonesKeys" class="emoji-item" style="place-self: center" [ngStyle]="{'border': skinTones[skin] == skinTone ? 'solid 2px hsl(153, 53%, 53%)' : 'none'}">
                                <div class="square" (click)="setSkinTone(skin);" [ngStyle]="{'background-color': skin === 'Light' ? '#FFD7C2' : skin === 'MediumLight' ? '#EEBFAA' : skin === 'Medium' ? '#C68D7B' : skin === 'MediumDark' ? '#966661' : skin === 'Dark' ? '#573739' : '#FFCC00'}"></div>
                            </div>
                        </div>
                        <div class="emoji-picker">
                            <div *ngFor="let emoji of emojis" (click)="selectEmoji(emoji);" class="emoji-item">
                              {{ emoji }}
                            </div>
                          </div>
                    </ng-template>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="header-container is-hidden-desktop" *ngIf="!bAccountConfig && !bAdminConfig">
        <svg width="60" height="60" (click)="sendClickInfoMessage('HomeMenu-Touch');GlobalMenuClicked('home')" class="pointer">
            <image *ngIf="GlobalMenu.Home" xlink:href="/assets/icons/home.svg" width="60" height="60"/>
            <image *ngIf="!GlobalMenu.Home" xlink:href="/assets/icons/home-unfill.svg" width="60" height="60"/>
        </svg>
        <div class="conf-icon-wrapper flex-column-container">
            <svg width="60" height="60" (click)="sendClickInfoMessage('RoomsMenu-Touch');GlobalMenuClicked('rooms')" class="pointer">
                <image *ngIf="GlobalMenu.Rooms" xlink:href="/assets/icons/users-full.svg" width="60" height="60"/>
                <image *ngIf="!GlobalMenu.Rooms" xlink:href="/assets/icons/users-empty.svg" width="60" height="60"/>
            </svg>
            <!--<span *ngIf="bAttendeesWaiting" class="enter-button-out stack-notif-to-menu"></span>-->
        </div>
        <svg width="60" height="60" (click)="sendClickInfoMessage('ContactsMenu-Touch');GlobalMenuClicked('contacts')" class="pointer">
            <image *ngIf="GlobalMenu.Contacts" xlink:href="/assets/icons/profile-full.svg" width="60" height="60"/>
            <image *ngIf="!GlobalMenu.Contacts" xlink:href="/assets/icons/profile.svg" width="60" height="60"/>
        </svg>
    </div>
    <div class="main-space is-hidden-desktop" *ngIf="!bAccountConfig && !bAdminConfig">
        <div class="rooms-title" *ngIf="!bAccountConfig && !bAdminConfig && GlobalMenu.Home">{{ 'shell.room.my_room' | translate }}</div>
        <div class="content-perso margin-top-content margin-bottom-content" *ngIf="!bAccountConfig && !bAdminConfig && GlobalMenu.Home">
            <div class="main-card-title-perso has-text-primary" [ngStyle]="setDomainAltColor()">
                <img src="{{Customer.LogoURI}}" *ngIf="Customer.LogoURI != undefined && Customer.LogoURI != ''" height="80" width="80" class="center-profile-perso">
                <svg width="80" height="80" *ngIf="Customer.LogoURI == undefined || Customer.LogoURI == ''" class="center-profile">
                    <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                </svg>
            </div>
            <div class="main-card-content perso-card-content" [class]="Customer?.SubscriptionType === 'free' || Customer?.SubscriptionType === 'free' ? 'padding-30' : ''">
                <div class="room-name-perso" *ngIf="Customer != undefined">
                    <div>{{ 'shell.room.room_of' | translate }}{{Customer.Name}}</div>
                    
                </div>
                <div class="flex-column-container room-element">
                    <div class="conf-icon-wrapper">
                        <!--<app-people-icon ></app-people-icon>-->
                        <!--<span *ngIf="ParticipantsCount > 0" class="enter-button-out">{{ ParticipantsCount }}</span>-->
                    </div>
                    <div *ngIf="Attendees.length != 0" class="attendees-container">
                        <div>{{ 'shell.room.attendees' | translate }} :</div>
                        <span *ngFor="let attendee of Attendees" class="conf-participant-name">{{ attendee }} </span>
                    </div>
                    <div *ngIf="Attendees.length == 0" class="attendees-container">
                        <div>{{ 'shell.room.waiting' | translate }}</div>
                    </div>
                </div>
                <div class="flex-column-container room-element">
                    <input *ngIf="Customer !== undefined" type="text" id="{{Customer.Room}}" class="link-content">
                    <button *ngIf="Customer !== undefined" (click)="sendClickInfoMessage('CopyPrivateLink');CopyLink(Customer.Room)" class="button is-vizu-look is-white-button room-element shadowed tooltip" [ngStyle]="setDomainMainColor()">
                        <svg width="20" height="20">
                            <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                        </svg>
                        <span class="tooltiptext">{{ 'shell.room.copy_link' | translate }}</span>
                    </button>

                    <div *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" class="room-element dropdown is-hoverable" [href]="Customer.Uri" target="_blank">
                        <button *ngIf="Customer !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                            <i class="fa-solid fa-share-nodes font-x-large"></i>
                        </button>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a *ngIf="Customer !== undefined && mobileDevice" id="{{Customer.Room}}-invite" class="dropdown-item" [href]="Customer.Uri" target="_blank">
                                    {{ 'shell.room.send_sms' | translate }}
                                </a>
                                <a *ngIf="Customer !== undefined && mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeLink(Customer);">
                                    {{ 'shell.room.qrcode_link' | translate }}
                                </a>
                                <a *ngIf="Customer !== undefined && !mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodeSmsPrivateLink'); displayQrCodeSms(Customer);">
                                    {{ 'shell.room.qrcode_sms' | translate }}
                                </a>
                                <a *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" class="dropdown-item" [href]="Customer.Uri" target="_blank">
                                    {{ 'shell.room.send_email' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!--<a *ngIf="Customer !== undefined && mobileDevice" id="{{Customer.Room}}-invite" class="tooltip shadowed room-element" [href]="Customer.Uri" target="_blank">
                        <button *ngIf="Customer !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                            <i class="fas fa-sms font-x-large"></i>
                        </button>
                        <span class="tooltiptext">{{ 'shell.room.send_sms' | translate }}</span>
                    </a>
                    <a *ngIf="Customer !== undefined && !mobileDevice" class="tooltip shadowed room-element">
                        <button *ngIf="Customer !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeSms(Customer);" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                            <i class="fas fa-sms font-x-large"></i>
                        </button>
                        <span class="tooltiptext">{{ 'shell.room.qrcode_sms' | translate }}</span>
                    </a>
                    <a *ngIf="Customer !== undefined && !mobileDevice" id="{{Customer.Room}}-invite" class="tooltip shadowed room-element" [href]="Customer.Uri" target="_blank">
                        <button *ngIf="Customer !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPrivateLink');" class="button is-vizu-look is-white-buttonroom-element shadowed" [ngStyle]="setDomainMainColor()">
                            <i class="far fa-envelope font-x-large"></i>
                        </button>
                        <span class="tooltiptext">{{ 'shell.room.send_email' | translate }}</span>
                    </a>-->
                </div>

                <button class="button is-join-button is-vizu-look room-element" (click)="sendClickInfoMessage('CopyPublicLink');EnterOwnRoom(PrivateRoom.Name)" *ngIf="!bTelephonyMode">
                    <div class="enter-button-out pointer" *ngIf="!waitingToEnter">
                        <div class="enter-button-in pointer">
                            <span class="enter-button pointer">
                            </span>
                        </div>
                    </div>
                    <b class="button-text pointer" *ngIf="!waitingToEnter">{{ 'shell.room.join' | translate }} ?</b>
                    <div *ngIf="waitingToEnter">
                        <!--<i class="fas fa-spinner fa-spin"></i>-->
                        <app-loader-points></app-loader-points>
                    </div>
                </button>
            </div>
        </div>

        <div class="rooms-title" *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig && GlobalMenu.Rooms">{{ 'shell.room.title' | translate }}</div>
        <div class="content content-size margin-top-content margin-bottom-content" *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig && GlobalMenu.Rooms">
            <div *ngFor="let Room of Rooms; index as i" class="rounded-card">
                <div class="main-card">
                    <div class="main-card-title has-text-primary pointer" (click)="sendClickInfoMessage('DisplaySpecificRoom-Touch');DisplayRoom(i)" [ngStyle]="setDomainAltColor()">
                        <div class="conf-icon-wrapper">
                            <!--<div *ngIf="Attendees.length > 0" class="conf-participant-count-circle">
                                <div class="conf-participant-count">{{ Attendees.length }}</div>
                            </div>-->
                            <div class="conf-icon-wrapper flex-column-container">
                                <app-people-icon [On]="Room.ParticipantsCount > 0" [isTouch]="true"></app-people-icon>
                                <span *ngIf="Room.ParticipantsCount > 0" class="attendees-number-touch stack-count-to-image">{{ Room.ParticipantsCount }}</span>
                            </div>
                        </div>
                        <div>{{ 'shell.room.room' | translate }} {{Room.data.Name}}</div>
                        <!--<div *ngIf="Room != undefined" class="room-lock-switch tooltip">
                            <i *ngIf="Room.data.Data1.AudioNotif"class="fas fa-bell" (click)="manageRoomNotification(Room)"></i>
                            <i *ngIf="!Customer.CustomData.AudioNotif" class="fas fa-bell-slash" (click)="manageRoomNotification(Room)"></i>
                        </div>-->
                        <!-- ROOM LOCKED FEATURE
                        <div *ngIf="Room != undefined" class="room-lock-switch tooltip">
                            <svg width="25" height="25">
                                <image *ngIf="Room.data.Locked" xlink:href="/assets/icons/lock-white.svg" width="25" height="25" (clic)="ToggleRoomLock(false, i)"/>
                                <image *ngIf="!Room.data.Locked" xlink:href="/assets/icons/unlock-white.svg" width="25" height="25" (clic)="ToggleRoomLock(true, i)"/>
                            </svg>
                            <img *ngIf="!Room.data.Locked" src="/assets/icons/unlock-white.svg" (clic)="sendClickInfoMessage('ToggleRoomLock-Touch');ToggleRoomLock($event, i)"/>
                            <span *ngIf="Room.data.Locked" class="tooltiptext">{{ 'shell.room.locked_tooltip' | translate }}</span>
                            <span *ngIf="Room.data.Locked" class="tooltiptext">{{ 'shell.room.unlocked_tooltip' | translate }}</span>
                        </div>-->
                    </div>
                    <div class="main-card-content conf-card-content" *ngIf="RoomDisplayed == i">
                        <input *ngIf="Room != undefined" type="text" id="{{Room.data.Room}}" class="link-content">
                        <!--<button class="button is-vizu-look is-white-button room-element shadowed" (click)="CopyLink(Room.data.Room)" [ngStyle]="setDomainMainColor()">
                            <span button-text>{{ 'shell.room.copy_link' | translate }}</span>
                            <svg width="20" height="20">
                                <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                            </svg>
                        </button>
                        <a *ngIf="Room !== undefined && mobileDevice" id="{{Room.data.Room}}-invite" [href]="Room.Uri" target="_blank">
                            <button *ngIf="Room !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPublicLink');" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                                <span>{{ 'shell.room.send_sms' | translate }} </span>
                            </button>
                        </a>
                        <a *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" [href]="Room.Uri" target="_blank">
                            <button *ngIf="Room !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPublicLink');" class="button is-vizu-look is-white-button room-element shadowed" [ngStyle]="setDomainMainColor()">
                                <span>{{ 'shell.room.send_email' | translate }} </span>
                            </button>
                        </a>-->
                        <div class="flex-row-container">
                            <button class="button is-vizu-look is-white-button room-element shadowed tooltip" (click)="CopyLink(Room.data.Room)" [ngStyle]="setDomainMainColor()">
                                <svg width="20" height="20">
                                    <image xlink:href="/assets/icons/copy-white.svg" width="20" height="20"/>
                                </svg>
                                <span button-text class="tooltiptext">{{ 'shell.room.copy_link' | translate }}</span>
                            </button>

                            <div *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" class="room-element dropdown is-hoverable" [href]="Room.Uri" target="_blank">
                                <button *ngIf="Room !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPrivateLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fa-solid fa-share-nodes font-x-large"></i>
                                </button>
                                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div class="dropdown-content">
                                        <a *ngIf="Room !== undefined && mobileDevice" id="{{Room.data.Room}}-invite" class="dropdown-item" [href]="Room.Uri" target="_blank">
                                            {{ 'shell.room.send_sms' | translate }}
                                        </a>
                                        <a *ngIf="Room !== undefined && mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeLink(Room);">
                                            {{ 'shell.room.qrcode_link' | translate }}
                                        </a>
                                        <a *ngIf="Room !== undefined && !mobileDevice" class="dropdown-item" (click)="sendClickInfoMessage('QrCodeSmsPrivateLink'); displayQrCodeSms(Room);">
                                            {{ 'shell.room.qrcode_sms' | translate }}
                                        </a>
                                        <a *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" class="dropdown-item" [href]="Room.Uri" target="_blank">
                                            {{ 'shell.room.send_email' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!--<a *ngIf="Room !== undefined && mobileDevice" id="{{Room.data.Room}}-invite" class="tooltip shadowed room-element" [href]="Room.Uri" target="_blank">
                                <button *ngIf="Room !== undefined && mobileDevice" (click)="sendClickInfoMessage('SmsPublicLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fas fa-sms font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.send_sms' | translate }}</span>
                            </a>
                            <a *ngIf="Room !== undefined && !mobileDevice" class="tooltip shadowed room-element">
                                <button *ngIf="Room !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('QrCodePublicLink'); displayQrCodeSms(Room);" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="fas fa-sms font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.qrcode_sms' | translate }}</span>
                            </a>
                            <a *ngIf="Room !== undefined && !mobileDevice" id="{{Room.data.Room}}-invite" class="tooltip shadowed room-element" [href]="Room.Uri" target="_blank">
                                <button *ngIf="Room !== undefined  && !mobileDevice" (click)="sendClickInfoMessage('EmailPublicLink');" class="button is-vizu-look is-white-button shadowed" [ngStyle]="setDomainMainColor()">
                                    <i class="far fa-envelope font-x-large"></i>
                                </button>
                                <span class="tooltiptext">{{ 'shell.room.send_email' | translate }}</span>
                            </a>-->
                        </div>
                        <button class="button is-join-button is-vizu-look room-element" (click)="sendClickInfoMessage('EnterPrivateRoom-Touch');EnterOwnRoom(Room.data.Room)">
                            <div class="enter-button-out pointer" *ngIf="!waitingToEnter">
                                <div class="enter-button-in pointer">
                                    <span class="enter-button pointer">
                                    </span>
                                </div>
                            </div>
                            <b class="button-text pointer" *ngIf="!waitingToEnter">{{ 'shell.room.join' | translate }} ?</b>
                            <div class="spinner-icon" *ngIf="waitingToEnter">
                                <!--<i class="fas fa-spinner fa-spin"></i>-->
                                <app-loader-points></app-loader-points>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content content-size margin-top-content margin-bottom-content" *ngIf="Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig && GlobalMenu.Rooms">

        </div>
            
        <div [class.contacts-container]="!GlobalMenu.Messages" [class.contacts-container-messages]="GlobalMenu.Messages" *ngIf="!bAccountConfig && !bAdminConfig && GlobalMenu.Contacts">
            <!--<div class="contacts-title">
                {{ 'shell.room.collaborators' | translate }}
            </div>-->
            <div [class.conf-card-content]="!GlobalMenu.Messages" [class.conf-card-content-messages]="GlobalMenu.Messages" [class.messages-box] = "GlobalMenu.Messages && messagesRecepient != ''">
                <div class="header-container">
                    <div class="flex-column-container" (click)="GlobalMenuClicked('contacts')">
                        <i class="fas fa-user-friends fa-2x" [ngClass]="(GlobalMenu.Contacts == true ? 'menu-enabled' : 'menu-disabled')"></i>
                        <p class="margin-10">{{ 'shell.room.team' | translate }}</p>
                    </div>
                    <!--<i class="fas fa-history fa-3x" [ngClass]="(GlobalMenu.History == true ? 'menu-enabled' : 'menu-disabled')"></i>-->
                    <div class="flex-column-container" (click)="GlobalMenuClicked('messages')">
                        <div class="icon-notification">
                            <i class="fas fa-comments fa-2x pointer" [ngClass]="(GlobalMenu.Messages == true ? 'menu-enabled' : 'menu-disabled')"></i>
                            <div *ngIf="hasNewMessages()" class="conf-participant-count"></div>
                        </div>
                        <p class="margin-10">Messages</p>
                    </div>
                </div>
                <div class="control search-container" *ngIf="GlobalMenu.Contacts">
                    <input type="text" name="SearchBar" [(ngModel)]="SearchField" class="input input-text-bar" placeholder="Rechercher un nom...">
                    
                    <!--<button type="button" (click)="sendClickInfoMessage('TelephonyCall');EnterOtherRoom(SearchField)" class="button is-success margin-2">
                        <span class="icon is-small">
                            <i class="fas fa-phone"></i>
                        </span>
                    </button>-->
                </div>

                <div *ngIf="GlobalMenu.Messages && messagesRecepient != ''" class="classic-text centered marged-bordered-bottom full-width">
                    <br/>
                    <i class="fas fa-xl fa-arrow-left pointer" (click)="this.messagesRecepient = ''; this.messagesDisplayed = []"></i>
                    <b class="messages-name">{{ messagesRecepient }}</b>
                    <i class="fas fa-xl fa-ban pointer" (click)="deleteChatMessages(this.messagesTo, this.messagesRecepient)"></i>
                </div>
                
                <div class="main-container">
                    <ng-container *ngIf="GlobalMenu.Contacts">
                        <div *ngIf="Limitations?.TeamPanelHidden === false">
                            <div class="contact-card-row" *ngFor="let Contact of Contacts | collaborator:SearchField">
                                <ng-container [ngSwitch]="Contact.Type">
                                    <div *ngSwitchCase="'User'">
                                        <img *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''" height="80" width="80" src="{{Contact.data.LogoURI}}" class="profile-custom" />
                                        <img *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''" width="80" height="80" src="/assets/icons/profile-default.svg" />
                                        
                                        <app-vizu-presence [bMessageView]="false" [Contact]="Contact" [DomainData]="DomainData"></app-vizu-presence>
                                    </div>
                                    <div *ngSwitchCase="'UserContacts'">
                                        <img width="80" height="80" src="/assets/icons/profile-default.svg" />
                                    </div>
                                    <div *ngSwitchCase="'Anonymous'">
                                        <img width="80" height="80" src="/assets/icons/profile-anonymous-default.svg" />
                                    </div>
                                </ng-container>

                                <div class="content-name-60">
                                    <b>{{ Contact.DisplayName }}</b>
                                </div>

                                <svg width="25" height="25" (click)="sendClickInfoMessage('EnterContactRoom-Touch');EnterOtherRoom(Contact.data.Room)" class="pointer">
                                    <image xlink:href="/assets/icons/call.svg" width="25" height="25"/>
                                </svg>
                                <!-- <i class="far fa-envelope icon-size pointer" (click)="displayMessages(Contact.To, Contact.DisplayName)"></i> -->
                                <i [matMenuTriggerFor]="menuContact" class="fa-solid fa-bars icon-size pointer"></i>
                                <mat-menu #menuContact="matMenu">
                                    <button mat-menu-item (click)="displayMessages(Contact.To, Contact.DisplayName)"><i class="far fa-envelope icon-size pointer"></i>Messagerie</button>
                                    <button mat-menu-item (click)="CopyLink(Contact.data.Room)" id="{{Contact.data.Room}}"><i class="icon-size pointer"><svg width="25" height="25"><image xlink:href="/assets/icons/copy.svg" width="25" height="25"/></svg></i>Lien</button>
                                </mat-menu>
                            </div>
                        </div>

                        <!-- subscription type free ou pro -->
                        <div *ngIf="Customer.SubscriptionType !== 'enterprise' && Limitations?.TeamPanelHidden" class="panel-hidden-container team-limitation-text">
                            <div class="margin-center big-text text-center">
                                Cette zone est dédiée aux comptes entreprise.
                            </div><br>
                            <div class="margin-center big-text text-center">
                                Pour avoir plus d'informations sur ce type d'abonnement cliquez ci-dessous
                            </div><br>
                            <input type="button" class="button is-success" value="{{ 'room.upgrade' | translate }}" (click)="openLink('https://welcome.vizu.live/contact')" style="margin: 0px 60px;"/>
                        </div>

                        <!-- entreprise cloisonnée -->
                        <div *ngIf="Customer.SubscriptionType === 'enterprise' && Limitations?.TeamPanelHidden" class="panel-hidden-container">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="GlobalMenu.History">
                        <div class="contact-card-row pointer" *ngFor="let Cdr of Cdrs | collaborator:SearchField" (click)="sendClickInfoMessage('EnterCollaboratorRoom');EnterOtherRoomFromHistory(Cdr)">
                            <b class="classic-text" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')">{{ Cdr.ToUri }}</b>
                            <i class="fas fa-arrow-left arrow" *ngIf="Cdr.In" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')"></i>
                            <i class="fas fa-arrow-right arrow" *ngIf="!Cdr.In" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')"></i>
                            <p class="classic-text" [ngClass]="(Cdr.ConnectTime == undefined ? 'unconnected-call' : '')">{{ Cdr.DateFormatted }}</p>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="GlobalMenu.Messages && messagesRecepient == ''">
                        <div class="contact-card-row" *ngFor="let Contact of Contacts_and_Anonymous() | collaborator:SearchField" (click)="displayMessages(Contact.To, Contact.DisplayName)">
                            <div *ngIf="DomainData.SharedUsers && Contact.Type === 'User'">
                                <img *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''" height="80" width="80" src="{{Contact.data.LogoURI}}" class="profile-custom" />
                                <img *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''" width="80" height="80" src="/assets/icons/profile-default.svg" />
                                <!--<svg width="80" height="80" *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''">
                                    <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80"/>
                                </svg>-->

                                <app-vizu-presence [bMessageView]="true" [Contact]="Contact" [DomainData]="DomainData"></app-vizu-presence>
                            </div>
                            <div *ngIf="DomainData.SharedUsers && Contact.Type === 'UserContacts'">
                                <img width="80" height="80" src="/assets/icons/profile-default.svg" />
                            </div>
                            <div *ngIf="Contact.Type === 'Anonymous'">
                                <img width="80" height="80" src="/assets/icons/profile-anonymous-default.svg" />
                            </div>
                            
                            <div class="content-name-60" *ngIf="DomainData.SharedUsers || (!DomainData.SharedUsers && Contact.Type === 'Anonymous')" >
                                <b class="classic-text">{{ Contact.DisplayName }}</b>
                                <p *ngIf="!Limitations?.MessagePanelHidden" class="message-preview">{{ Contact?.Messages[Contact.Messages.length - 1]?.Content }}</p>
                                <p *ngIf="Limitations?.MessagePanelHidden" class="message-preview">{{ Contact?.Messages[Contact.Messages.length - 1]?.Content | translate }}</p>
                            </div>

                            <div class="conf-icon-wrapper" *ngIf="DomainData.SharedUsers || (!DomainData.SharedUsers && Contact.Type === 'Anonymous')">
                                <div class="conf-icon-wrapper contact-messages-count-circle" [ngStyle]="{'visibility': (Contact.UnreadMessagesNumber !== undefined && Contact.UnreadMessagesNumber > 0) ? 'visible' : 'hidden'}">
                                    <div class="conf-participant-count">{{ Contact.UnreadMessagesNumber }}</div>
                                </div>
                            </div>
                            <i class="far fa-envelope icon-size pointer" (click)="displayMessages(Contact.To, Contact.DisplayName)"></i>
                        </div>
                    </ng-container>

                <!--<ng-container *ngIf="GlobalMenu.Messages && messagesRecepient != ''">
                    <div class="classic-text centered">
                        <br/>
                        <b>{{ messagesRecepient }}</b>
                    </div>
                    <hr>
                    <div *ngFor="let messageDisplayed of messagesDisplayed">
                        <div class="classic-text" [ngClass]="(messageDisplayed.In ? 'remote-side' : 'local-side')">
                            <p class="text-message">{{ messageDisplayed.Content }}</p>
                        </div>
                    </div>
                </ng-container>-->

                <div *ngIf="GlobalMenu.Messages && messagesRecepient != ''" class="messages-container">
                    <div *ngFor="let messageDisplayed of messagesDisplayed;" (mouseenter)="isMessageHover[messageDisplayed.id] = true" (mouseleave)="isMessageHover[messageDisplayed.id] = false">
                        <div class="classic-text" *ngIf="messageDisplayed.Contact !== ''" [ngClass]="(messageDisplayed.To == messagesTo ? 'local-side' : 'remote-side')">
                            <div *ngIf="messageDisplayed.To == messagesTo" [hidden]="!isMessageHover[messageDisplayed.id]" class="message-edit pointer"><i class="fa-solid fa-ellipsis-vertical" style="position: absolute; width: 10px; text-align: center; top: calc(50% - 10px);" [matMenuTriggerFor]="messageEditor"></i></div>
                            <p class="text-message" [attr.data-time]="formatTimestamp(messageDisplayed.Timestamp)">{{ messageDisplayed.Contact }}</p>
                        </div>
                        <div class="classic-text" [ngClass]="(messageDisplayed.To == messagesTo ? 'local-side' : 'remote-side')">
                            <div *ngIf="messageDisplayed.To == messagesTo" [hidden]="!isMessageHover[messageDisplayed.id]" class="message-edit pointer"><i class="fa-solid fa-ellipsis-vertical" style="position: absolute; width: 10px; text-align: center; top: calc(50% - 10px);" [matMenuTriggerFor]="menu"></i></div>
                            <p *ngIf="!this.Limitations?.MessagePanelHidden" class="text-message" [attr.data-time]="formatTimestamp(messageDisplayed.Timestamp)">{{ messageDisplayed.Content }}</p>
                            <p *ngIf="this.Limitations?.MessagePanelHidden" class="text-message" [attr.data-time]="formatTimestamp(messageDisplayed.Timestamp)">{{ messageDisplayed.Content | translate }}</p>
                            <div *ngIf="messageDisplayed.To != messagesTo" [hidden]="!isMessageHover[messageDisplayed.id]" class="message-edit pointer"><i class="fa-solid fa-ellipsis-vertical" style="width: 10px; text-align: center;" [matMenuTriggerFor]="menu"></i></div>
                            
                            <mat-menu #menu="matMenu" [xPosition]="(messageDisplayed.To == messagesTo ? 'before' : 'after')">
                                <button mat-menu-item>Edit</button>
                                <button mat-menu-item (click)="deleteChatMessage(messageDisplayed.id)">Delete</button>
                            </mat-menu>
                        </div>
                    </div>
                </div>

                <div class="chat-input" *ngIf="GlobalMenu.Messages && messagesRecepient != '' && !isAnonymeDisplay">
                    <input type="text" name="ChatText" (keydown)="OnKeyDown($event)" [(ngModel)]="messageData" class="input input-text-bar border-20" placeholder="Aa">
                    <button class="no-button" (click)="sendClickInfoMessage('Chat-SendMessage');sendChatMessage()" id="sendMessageSubmit">
                        <svg width="30" height="30" class="pointer">
                            <image xlink:href="/assets/icons/send.svg" width="30" height="30"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="bWebsocketReconnecting" class="offline-notification">
        <p>{{ 'common.link_failure1' | translate }}</p>
        <p>{{ 'common.link_failure2' | translate }}</p>
        <br/>
    </div>
    </div>
<app-vizu-footer></app-vizu-footer>

<div *ngIf="EmailNotification" class="notification email-notif">
    <button (click)="CloseEmailNotification()" class="delete"></button>
    Un email d'invitation a été envoyé à votre correspondant.
</div>

<app-notification #notificationComponent></app-notification>

<!--<div [class.is-active]="bSelectDevicesActive" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <div class="step-text is-hidden-touch">
                Un dernier coup d'oeil dans le miroir ?
            </div>

            <div class="step-title is-hidden-desktop" *ngIf="DomainData != undefined">
                {{ 'public_room.welcome' | translate }}{{ DomainData.CustomData.FriendlyName }}
            </div>
            
            <div class="landscape-mobile">
                <div class="panel-size smaller-preview">
                    
                    <video id="{{LocalStream.id}}" *ngIf="LocalStream != undefined" [appNgmediaVideo]="LocalStream" playsinline autoplay [muted]="true" class="video-preview video-view-participant" [ngStyle]="setDomainMainColor()"></video>
                    <video *ngIf="LocalStream == undefined" [muted]="true" class="video-preview video-view-participant" [ngStyle]="setDomainMainColor()"></video>
                    
                    <div class="control-bar">
                                    
                        <div>
                            <progress class="progress-bar" [value]="meterValue" max="1">{{meterValue}}%</progress>
                            <svg width="60" height="60" class="is-hidden-touch control-item relative-item pointer" *ngIf="LocalStreamAudio" (click)="sendClickInfoMessage('BeforeCall-ToggleMic-Desktop'); ToggleMic()">
                                <image xlink:href="/assets/icons/micro-dark.svg" width="60" height="60" *ngIf="LocalStreamAudioEnabled"/>
                                <image xlink:href="/assets/icons/micro-slash.svg" width="60" height="60" *ngIf="!LocalStreamAudioEnabled"/>
                            </svg>
                        </div>
                        <svg width="60" height="60" class="is-hidden-touch control-item pointer" *ngIf="LocalStreamVideo" (click)="sendClickInfoMessage('BeforeCall-ToggleVid-Desktop'); ToggleVid()">
                            <image xlink:href="/assets/icons/video-dark.svg" width="60" height="60" *ngIf="LocalStreamVideoEnabled"/>
                            <image xlink:href="/assets/icons/video-slash.svg" width="60" height="60" *ngIf="!LocalStreamVideoEnabled"/>
                        </svg>
                        <svg width="40" height="40" class="is-hidden-desktop control-item pointer" *ngIf="LocalStreamAudio" (click)="sendClickInfoMessage('BeforeCall-ToggleMic-Touch'); ToggleMic()">
                            <image xlink:href="/assets/icons/micro-dark.svg" width="40" height="40" *ngIf="LocalStreamAudioEnabled"/>
                            <image xlink:href="/assets/icons/micro-slash.svg" width="40" height="40" *ngIf="!LocalStreamAudioEnabled"/>
                        </svg>
                        <svg width="40" height="40" class="is-hidden-desktop control-item pointer" *ngIf="LocalStreamVideo" (click)="sendClickInfoMessage('BeforeCall-ToggleVid-Touch'); ToggleVid()">
                            <image xlink:href="/assets/icons/video-dark.svg" width="40" height="40" *ngIf="LocalStreamVideoEnabled"/>
                            <image xlink:href="/assets/icons/video-slash.svg" width="40" height="40" *ngIf="!LocalStreamVideoEnabled"/>
                        </svg>
                    </div>
                </div>
            </div>
            
            <div>
                <div class="field is-horizontal">
                    <div class="label-media">
                        <label class="label">
                            Audio:
                        </label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <select class="select video-view-participant"(change)="updateAudioDevice($event.target.value)">
                                    <option *ngFor="let audioDevice of audioDevices; index as i" value="{{i}}" [selected]="(i == audioDeviceSelected ? true : false)">{{audioDevice.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="label-media">
                        <label class="label">
                            Video:
                        </label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <select class="select video-view-participant" (change)="updateVideoDevice($event.target.value)">
                                    <option *ngFor="let videoDevice of videoDevices; index as i" value="{{i}}" [selected]="(i == videoDeviceSelected ? true : false)">{{videoDevice.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->

<div [class.is-active]="IncomingCall" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content fixed-size-desktop">
        <div class="modal-card-head">
            <div class="incoming-call-title has-text-primary">Nouvel Appel</div>
        </div>
        <div class="modal-card-body no-overflow">
            <div class="incoming-call-icon">
                <i class="fas fa-user"></i>
            </div>
            <div *ngIf="waitingGetUserMedia" class="outgoing-call-icon">
                <!--<i class="fas fa-spinner fa-spin"></i>-->
                <app-loader-points></app-loader-points>
            </div>
            <ng-container *ngIf="!waitingGetUserMedia">
                <div *ngFor="let remoteData of remoteDatas; index as i" class="incoming-attendee-container">
                    <div class="incoming-call-title has-text-primary">
                        {{ remoteData.Data.Name }}&nbsp;
                    </div>
                    <div class="incoming-attendee-buttons">
                        <button type="button" (click)="sendClickInfoMessage('AcceptSingleAttendee');EnterAndAccept(Customer.Room, remoteData)" class="button full-width is-success margin-2">
                            <span class="icon is-small">
                                <i class="fas fa-check"></i>
                            </span>
                        </button>

                        <div class="dropdown is-hoverable">
                            <button type="button" class="button full-width incoming-call-button-refuse margin-2">
                                <span class="icon is-small">
                                    <i class="fas fa-ban"></i>
                                </span>
                            </button>
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content position-fixed">
                                    <a class="dropdown-item" (click)="sendClickInfoMessage('RejectSpecificCallNoMessage'); RefuseIncomingSpecificCall(remoteData);">
                                        {{ 'shell.room.reject_call' | translate }}
                                    </a>
                                    <a class="dropdown-item" (click)="sendClickInfoMessage('OpenRejectMessage'); DisplayRefuseMessage(remoteData);">
                                        {{ 'shell.room.reject_with_message' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="incoming-call-buttons has-text-primary">
                    <!--<div *ngIf="!Limitations?.RoomPanelHidden && !bTelephonyMode && remoteDatas.length > 1" (click)="sendClickInfoMessage('AcceptAllAttendees');EnterAndAccept(Customer.Room, remoteDatas)" class="incoming-call-button incoming-call-button-accept margin-2">
                        <i class="fas fa-check incoming-call-button-icon"></i>
                        Accepter tous
                    </div>
                    
                    TODO FAIRE PATIENTER
                    <div (click)="IncomingCall = false" class="incoming-call-button incoming-call-button-wait">
                        <i class="fas fa-hourglass-half incoming-call-button-icon"></i>
                        Faire patienter
                    </div>
                    
                    <div (click)="sendClickInfoMessage('RefuseMultipleAttendees');RefuseIncomingCall()" class="incoming-call-button incoming-call-button-refuse margin-2">
                        <i class="fas fa-ban incoming-call-button-icon"></i>
                        Rejeter tous
                    </div>-->

                    <button *ngIf="!Limitations?.RoomPanelHidden && !bTelephonyMode && remoteDatas.length > 1" class="button is-success" (click)="sendClickInfoMessage('AcceptAllAttendees');EnterAndAccept(Customer.Room, remoteDatas)">
                        <span class="icon is-small">
                          <i class="fas fa-check"></i>
                        </span>
                        <span>{{ 'shell.room.accept_all' | translate }}</span>
                    </button>

                    <button *ngIf="!Limitations?.RoomPanelHidden && remoteDatas.length > 1" class="button is-danger" (click)="sendClickInfoMessage('RefuseMultipleAttendees');RefuseIncomingCall()">
                        <span class="icon is-small">
                          <i class="fas fa-ban"></i>
                        </span>
                        <span>{{ 'shell.room.reject_all' | translate }}</span>
                    </button>

                    <button *ngIf="Limitations?.RoomPanelHidden && !bTelephonyMode && remoteDatas.length > 1" class="button is-success" disabled>
                        <span class="icon is-small">
                          <i class="fas fa-check"></i>
                        </span>
                        <span>{{ 'shell.room.accept_all' | translate }}</span>
                    </button>

                    <button *ngIf="Limitations?.RoomPanelHidden && remoteDatas.length > 1" class="button is-danger" disabled>
                        <span class="icon is-small">
                          <i class="fas fa-ban"></i>
                        </span>
                        <span>{{ 'shell.room.reject_all' | translate }}</span>
                    </button>
                </div>
            </ng-container>
        </div>
        <div class="modal-card-foot">
            <div *ngIf="refuseMessageShown">
                <div class="control has-icons-right">
                    <textarea class="textarea refuse-input" id="textarea-refuse-message" rows="2" placeholder="{{ 'shell.room.reject_message_placeholder' | translate }}" [(ngModel)]="refuseMessage" (ngModelChange)="valueRejectMessageChange()" maxlength="140"></textarea>
                    <span class="icon is-small is-right" (click)="refuseMessage = '';" style="height: 60px; width: 30px;">
                      <i class="fas fa-times"></i>
                    </span>
                  </div>
                <span>{{ refuseMessageRemaining }} / 140</span>
            </div>
            <div *ngIf="refuseMessageShown" class="margin-20">
                <button type="button" class="button is-danger" (click)="RefuseIncomingSpecificCallWithMessage()">
                    {{ 'shell.room.reject' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div [class.is-active]="deniedPermissionsModal" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="modal-card-head">
            <p class="modal-card-title" *ngIf="deniedPermissions.audio && deniedPermissions.video">
                {{ 'room.permissions_denied_cam_and_mic_title' | translate }}
            </p>
            <p class="modal-card-title" *ngIf="deniedPermissions.audio && !deniedPermissions.video">
                {{ 'room.permissions_denied_mic_title' | translate }}
            </p>
            <p class="modal-card-title" *ngIf="!deniedPermissions.audio && deniedPermissions.video">
                {{ 'room.permissions_denied_cam_title' | translate }}
            </p>
            <button class="delete" (click)="clearDeniedPermissionsModal();" aria-label="close"></button>
        </div>
        <div class="modal-card-body">
            <div class="flex-row-container">
                <img src="/assets/denied-permissions.gif" class="shadow-light" width="300px" height="300px" />
                <div class="font-large margin-10">
                    <p>
                        {{ 'room.permissions_denied_content_1' | translate }}
                    </p><br>
                    <p *ngIf="deniedPermissions.audio && deniedPermissions.video">
                        {{ 'room.permissions_denied_content_cam_and_mic_2' | translate }}
                    </p>
                    <p *ngIf="deniedPermissions.audio && !deniedPermissions.video">
                        {{ 'room.permissions_denied_content_mic_2' | translate }}
                    </p>
                    <p *ngIf="!deniedPermissions.audio && deniedPermissions.video">
                        {{ 'room.permissions_denied_content_cam_2' | translate }}
                    </p><br>
                    <p>
                        {{ 'room.permissions_denied_content_3' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-card-foot">
        </div>
    </div>
</div>

<div [class.is-active]="OutgoingCall" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content fixed-size-desktop">
        <div class="modal-card-head">
            <div class="incoming-call-title has-text-primary">{{ 'shell.room.call_to' | translate }} {{OutgoingCallName}}</div>
            <button *ngIf="OutgoingCallRefused" class="delete" (click)="CancelOutgoingCall();" aria-label="close"></button>
        </div>
        <div class="modal-card-body">
            <div *ngIf="!OutgoingCallRefused" class="outgoing-call-icon">
                <!--<i class="fas fa-spinner fa-spin"></i>-->
                <app-loader-points [isDark]="true"></app-loader-points>
            </div>
            <button *ngIf="!OutgoingCallRefused" type="button" (click)="sendClickInfoMessage('CancelOutgoingCall');sendClickInfoMessage('CancelIncomingCall');CancelOutgoingCall()" class="button outgoing-call-button-refuse">
                <span>{{ 'shell.room.call_cancel' | translate }}</span>
                <span class="icon is-small">
                    <i class="fas fa-ban"></i>
                </span>
            </button>
            <div class="flex-column-container" *ngIf="OutgoingCallRefusedMessage">
                <span class="font-large">{{ 'shell.room.call_reject_message' | translate }}</span><br>
                <b class="font-large">{{ OutgoingCallRefusedMessage }}</b>
            </div>
        </div>
        <div class="modal-card-foot">
        </div>
    </div>
</div>

<div [class.is-active]="bQrCodeSmsModal && qrCodeSafeUrl != undefined" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ 'shell.room.qrcode_sms_title' | translate }}</p>
            <button class="delete" (click)="clearQrCodeObject();" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
            <div class="flex-row-container">
                <div class="font-large">
                    <p>1. {{ 'shell.room.qrcode_sms_1' | translate }}</p><br>
                    <p>2. {{ 'shell.room.qrcode_sms_2' | translate }}</p><br>
                    <p>3. {{ 'shell.room.qrcode_sms_3' | translate }}</p><br>
                    <p>{{ 'shell.room.qrcode_sms_number' | translate }}:</p>
                    <div class="field has-addons">
                        <div class="control">
                            <input type="text" class="input" [ngModel]="contactToSms" (ngModelChange)="updateQrCode($event)" />
                        </div>
                        <div class="control">
                            <button class="button tooltip" (click)="pasteContent()" [ngStyle]="setDomainMainColor()">
                                <i class="fas fa-paste is-white-button"></i>
                                <span class="tooltiptext">{{ 'shell.room.paste' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <img [src]="qrCodeSafeUrl">
            </div>
        </section>
        <footer class="modal-card-foot">
            <a (click)="sendClickInfoMessage('SensSMSQrCodeModal-Ok'); clearQrCodeObject();" class="button">Ok</a>
        </footer>
    </div>
</div>

<div [class.is-active]="bQrCodeLinkModal && qrCodeSafeUrl != undefined" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ 'shell.room.qrcode_link_title' | translate }}</p>
            <button class="delete" (click)="clearQrCodeObject();" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
            <div class="flex-row-container">
                <div class="font-large margin-10">
                    <p>{{ 'shell.room.room_link' | translate }}</p>
                    <div class="field has-addons">
                        <div class="control" *ngIf="tempQrCode !== undefined">
                            <input type="text" class="input" value="{{tempQrCode.title}}" disabled/>
                        </div>
                        <div class="control">
                            <button class="button tooltip" (click)="CopyLink(qrCodeRoom)" [ngStyle]="setDomainMainColor()">
                                <i class="fas fa-copy is-white-button"></i>
                                <span class="tooltiptext">{{ 'shell.room.copy' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <img [src]="qrCodeSafeUrl">
            </div>
        </section>
        <footer class="modal-card-foot">
            <a (click)="sendClickInfoMessage('SensSMSQrCodeModal-Ok'); clearQrCodeObject();" class="button">Ok</a>
        </footer>
    </div>
</div>



<div [class.is-active]="Customer.Room == ''" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content fixed-size-desktop">
        <div class="modal-card-body" style="border-radius: 6px;">
            <h4 class="title is-4">Your Room Name</h4>
            <div class="field is-grouped">
                <p class="control is-expanded">
                    <input class="input room-input" type="text" placeholder="MyRoom" [(ngModel)]="roomNameEdit">
                </p>
                <p class="control">
                    <button class="button is-danger" (click)="setUserRoom()">Save</button>
                </p>
            </div>
            <p *ngIf="ErrorUserRoom" class="help is-danger is-active">This name is invalid</p>
        </div>
    </div>
</div>

<app-vizu-upgrade-plan [bUpgradeModalOpened]="bUpgradeModal" (CloseUpgradeModal)="bUpgradeModal = false"></app-vizu-upgrade-plan>

<audio *ngIf="IncomingCall" src="/assets/ringing.m4a" playsinline autoplay loop></audio>
<audio *ngIf="IncomingRoomCall" src="/assets/ringing.m4a" playsinline autoplay loop></audio>

<!--<div [class.is-active]="bReadMessage" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <div *ngIf="readMessageId != undefined">
                {{ Messages[readMessageId].Title }}
            </div>
        </header>
        <section class="modal-card-body">
            <div *ngIf="readMessageId != undefined">
                {{ Messages[readMessageId].Content }}
            </div>
        </section>
        <footer class="modal-card-foot-space" *ngIf="readMessageId != undefined">
            <a class="button" (click)="sendClickInfoMessage('ReadMessageModal-Cancel'); CloseReadMessage()">Cancel</a>
            <a *ngIf="Messages[readMessageId].isContact == true" class="button" (click)="sendClickInfoMessage('ReadMessageModal-Reply'); OpenWriteMessage(Messages[readMessageId].From)">Reply</a>
            
        </footer>
    </div>
</div>

<div [class.is-active]="bWriteMessage" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            To:
            <input type="text" name="AddNewParticipant" [(ngModel)]="contactToMessage" class="control-item input" list="contacts-list" placeholder="ex: John Doe">
            <datalist id="contacts-list">
                <option *ngFor="let Contact of Contacts_and_Anonymous()" value="{{Contact.To}}"></option>
            </datalist>
        </header>
        <section class="modal-card-body">
            Title:
            <br/>
            <textarea rows="1" cols="80" class="textarea-improved"></textarea>
            Content:
            <br/>
            <textarea rows="6" cols="80" class="textarea-improved"></textarea>
        </section>
        <footer class="modal-card-foot-space">
            <a (click)="sendClickInfoMessage('ReadMessageModal-Cancel'); CloseWriteMessage();" class="button">Cancel</a>
            <a (click)="sendClickInfoMessage('ReadMessageModal-Reply'); CloseWriteMessage();" class="button">Send</a>
        </footer>
    </div>
</div>-->