import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer.service'
import { DomainData } from '../../domain/domain'

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrl: './verify-account.component.scss'
})
export class VerifyAccountComponent implements OnInit {
  DomainData: DomainData
  token: string = undefined;
  isValidToken: boolean = false
  inProgress: boolean = false
  isActivated: boolean = false
  bCompanyFound: boolean = false

  constructor(
    private customerService: CustomerService,
  )
  {
    let pathname = window.location.pathname.split( '/' );
    this.token = pathname[2];
    this.customerService.CreateAccount.Token = this.token
  }

  ngOnInit() {
    this.customerService.GetDomainPublicTemplate("")
    .subscribe({
        next: (data: any) => {
            this.bCompanyFound = true
            let domainData: any = data
            this.DomainData = {
                Name: data.Name,
                SharedUsers: data.SharedUsers, 
                CustomData: (domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData))
            };
            this.inProgress = true
            this.customerService.ConfirmUser()
            .subscribe({
                next: (data: any) => {
                    this.inProgress = false
                    console.log(data)
                    this.isActivated = true
                },
                error: (e: any) => {
                    this.inProgress = false
                    console.log(e)
                }
            });
            this.clearUser()
        },
        error: (response: any) => {
			      this.bCompanyFound = false
            if (response.error) {
                if(response.error.title) {
                    //this.error = response.error.title + '. '
                } 
                if(response.error.parameters) {
                    //this.error += response.error.parameters[0].reason;
                    //this.required[response.error.parameters[0].name] = true;
                }  
            }
        }
    })
  }

  clearUser() {
    this.customerService.CreateAccount = {
        Email: "",
        Password: "",
        ConfirmPassword: "",
        RoomName: "",
        DisplayName: "",
        Token: "",
        Code: ""
    }
  }

  setDomainMainColor() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
        style = {
            "background-color": this.DomainData.CustomData.ColorHomeMain,
        };
    } else {
        style = {
            "background-color": "rgba(63, 64, 86, 0.8)"
        };
    }
    return style;
  }

  setImageBackground() {
      let style = undefined;
      if (this.DomainData !== undefined && this.DomainData !== null) {
          if (this.DomainData.CustomData !== undefined) {
              if (this.DomainData.CustomData.ImgBackground !== undefined) {
                  if (this.DomainData.CustomData.ImgBackground === "") {
                      style = {
                          "background-image": "url('/assets/img/brown-wooden-style.jpg')",
                      };
                  } else if (this.DomainData.CustomData.ImgBackground.startsWith("/") || this.DomainData.CustomData.ImgBackground.startsWith("http")) {
                      style = {
                          "background-image": "url('" + this.DomainData.CustomData.ImgBackground + "')",
                      };
                  } else {
                      style = {
                          "background-image": "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.ImgBackground + "')",
                      };
                  }
              } else {
                  style = {
                      "background-image": "url('/assets/img/brown-wooden-style.jpg')",
                  };
              }
          } else {
              style = {
                  "background-image": "url('/assets/img/brown-wooden-style.jpg')",
              };
          }
      } else {
          style = {
              "background-image": "url('/assets/img/brown-wooden-style.jpg')",
          };
      }
      return style;
  }

  setTextColor() {
      let style = undefined;
      if (this.DomainData !== undefined) {  
        if (this.DomainData.CustomData.ColorHomeMain === "white" || this.DomainData.CustomData.ColorHomeMain === "#FFFFFF" || this.DomainData.CustomData.ColorHomeMain === "rgb(255,255,255)") {
          style = {
              "color": "black",
          };
        } else {
          style = {
              "color": "white",
          };  
        }
      }
      return style;
  }

  setDomainAltColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
        style = {
            "background-color": this.DomainData.CustomData.ColorHomeAlt,
        };
    } else {
        style=""
    }
    return style   
  }
}