
<div class="full-height landing-container" *ngIf="DomainData !== undefined" [ngStyle]="setImageBackground()">
    <div class="opacity-background" [ngStyle]="setDomainMainColor()">
    </div>
    <div class="full-height">
        <div class="full-height landing-content">
            <div class="landing-center">
                <div *ngIf="!bCompanyFound">
                    <div class="name-form name-text">{{ 'public_room.not_found_url_title' | translate }}</div>
                    <div class="name-form step-step-unknown">{{ 'public_room.not_found_main' | translate }}</div>
                </div>
                <div *ngIf="bCompanyFound && isActivated === true" class="panel">
                    <h1 class="login-title">{{ 'login.signup' | translate }}</h1>
                    <div class="login-form fix-width centered">
                        <p>{{ 'login.account_activated' | translate }}</p>
                        <a class="button is-medium is-danger login-button width-80" href="/">{{ 'login.login' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading-screen *ngIf="DomainData === undefined"></app-loading-screen>