<div class="modal" [ngClass]="{'is-active': bUpgradeModalOpened}">
    <div class="modal-background" (click)="closeModal()"></div>
    <div class="modal-card custom-width">
      <header class="modal-card-head" style="background-color: white; border: none; text-align: center;">
        <p class="modal-card-title"></p>
        <button class="delete" aria-label="close" (click)="closeModal()"></button>
      </header>
      <section class="modal-card-body">
        <div class="mb-6" style="text-align: center;">
          <h3 *ngIf="!isFeatureClicked" class="title is-4">{{ 'upgrade_plan.title' | translate }}</h3>
          <h3 *ngIf="isFeatureClicked" class="title is-4">{{ 'upgrade_plan.alt_title' | translate }}</h3>
          <p class="subtitle is-6">{{ 'upgrade_plan.subtitle' | translate }} <a href="https://welcome.vizu.live/outil-de-visio-conference-francais" target="_blank">{{ 'upgrade_plan.subtitle_link' | translate }}</a>.</p>
        </div>

        <div class="tabs" style="place-content: center;">
            <div class="buttons has-addons" style="display: none;">
                <button class="button is-selected is-primary">{{ 'upgrade_plan.monthly' | translate }}</button>
                <button class="button">{{ 'upgrade_plan.annual' | translate }}</button>
            </div>
        </div>

        <div class="columns">
          <div class="column">

            <div class="box plan" (click)="planSelected = Plans.Pro" [ngClass]="{'selected-plan': planSelected == Plans.Pro}">
              <div>
                  <h3 class="title is-4">{{ 'upgrade_plan.pro.title' | translate }}</h3>
                  <p class="subtitle is-6">{{ 'upgrade_plan.pro.subtitle' | translate }}</p>
              </div>
              <p class="title is-3" style="width: 125px; text-align: right;">10€ <span class="subtitle is-7 has-text-grey-light">{{ 'upgrade_plan.perMonth' | translate }}</span></p>
            </div>

            <div class="box plan" (click)="planSelected = Plans.Enterprise" [ngClass]="{'selected-plan': planSelected == Plans.Enterprise}">
              <div>
                <h3 class="title is-4">{{ 'upgrade_plan.enterprise.title' | translate }}<span class="tag is-success is-light" style="margin-left: 10px;">{{ 'upgrade_plan.popular' | translate }}</span></h3>
                <p class="subtitle is-6">{{ 'upgrade_plan.enterprise.subtitle' | translate }}</p>
              </div>
              <p class="title is-3" style="width: 125px; text-align: right;">15€ <span class="subtitle is-7 has-text-grey-light">{{ 'upgrade_plan.user' | translate }} {{ 'upgrade_plan.perMonth' | translate }}</span></p>
            </div>

            <div class="box plan" [ngClass]="{'selected-plan': planSelected == Plans.Custom}" (click)="openLink('https://welcome.vizu.live/contact')">
              <div>
                  <h3 class="title is-4">{{ 'upgrade_plan.custom.title' | translate }}</h3>
                  <p class="subtitle is-6">{{ 'upgrade_plan.custom.subtitle' | translate }}</p>
              </div>
              <span class="tag is-success is-medium">{{ 'upgrade_plan.custom.contact' | translate }}</span>
            </div>

          </div>

          <div class="column">
            <div class="box" style="background-color: whitesmoke; height: 100%;">
                <h3 *ngIf="planSelected == Plans.Enterprise"  class="title is-4">{{ 'upgrade_plan.enterprise.advantages.title' | translate }}</h3>
                <p  *ngIf="planSelected == Plans.Enterprise"  class="subtitle is-7">{{ 'upgrade_plan.enterprise.advantages.subtitle' | translate }}</p>
                <h3 *ngIf="planSelected == Plans.Pro"         class="title is-4">{{ 'upgrade_plan.pro.advantages.title' | translate }}</h3>
                <p  *ngIf="planSelected == Plans.Pro"         class="subtitle is-7">{{ 'upgrade_plan.pro.advantages.subtitle' | translate }}</p>

                <div class="feature-list">
                  <div class="list">
                      <div class="list-item">
                          <span *ngIf="planSelected == Plans.Enterprise">{{ 'upgrade_plan.enterprise.advantages.list.first' | translate }}</span>
                          <span *ngIf="planSelected == Plans.Pro">{{ 'upgrade_plan.pro.advantages.list.first' | translate }}</span>
                          <span class="icon has-text-success">
                              <i class="fas fa-check-circle"></i>
                          </span>
                      </div>
                      <div class="list-item">
                        <span *ngIf="planSelected == Plans.Enterprise">{{ 'upgrade_plan.enterprise.advantages.list.second' | translate }}</span>
                        <span *ngIf="planSelected == Plans.Pro">{{ 'upgrade_plan.pro.advantages.list.second' | translate }}</span>
                          <span class="icon has-text-success">
                              <i class="fas fa-check-circle"></i>
                          </span>
                      </div>
                      <div class="list-item">
                        <span *ngIf="planSelected == Plans.Enterprise">{{ 'upgrade_plan.enterprise.advantages.list.third' | translate }}</span>
                        <span *ngIf="planSelected == Plans.Pro">{{ 'upgrade_plan.pro.advantages.list.third' | translate }}</span>
                          <span class="icon has-text-success">
                              <i class="fas fa-check-circle"></i>
                          </span>
                      </div>
                      <div class="list-item">
                        <span *ngIf="planSelected == Plans.Enterprise">{{ 'upgrade_plan.enterprise.advantages.list.fourth' | translate }}</span>
                        <span *ngIf="planSelected == Plans.Pro">{{ 'upgrade_plan.pro.advantages.list.fourth' | translate }}</span>
                          <span class="icon has-text-success">
                              <i class="fas fa-check-circle"></i>
                          </span>
                      </div>
                      <div class="list-item">
                        <span *ngIf="planSelected == Plans.Enterprise">{{ 'upgrade_plan.enterprise.advantages.list.fifth' | translate }}</span>
                        <span *ngIf="planSelected == Plans.Pro">{{ 'upgrade_plan.pro.advantages.list.fifth' | translate }}</span>
                          <span class="icon has-text-success">
                              <i class="fas fa-check-circle"></i>
                          </span>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot" style="background-color: white; border: none; place-content: center;">
        <button class="button is-primary" (click)="upgradePlan()">{{ 'upgrade_plan.buttons.valid' | translate }}</button>
        <button class="button" (click)="closeModal()">{{ 'upgrade_plan.buttons.cancel' | translate }}</button>
      </footer>
    </div>
  </div>