import * as moment from 'moment'
import 'moment-timezone'

export class Customer {
    id: string
    Name: string
    Enabled: boolean
    Username: string
    Password: string
    Email: string
    ProfileId: string
    DomainId: string
    LogoURI: string
    ServiceProvider: boolean
    SubscriptionType: string
    Administrator: boolean
    State: string
    Room: string

    constructor(fields: Partial<Customer>) {
        Object.assign(this, fields)
    }
}

export class Collaborator {
    To: string
    DisplayName: string
    Type: string
    data: {
        id: string
        Username: string
        Name: string
        InCall: string
        Firstname: string
        LastName: string
    }
    Messages: Message[]
    UnreadMessagesNumber: number

    constructor(fields: Partial<Collaborator>) {
        Object.assign(this, fields)
    }
}

export class Limitations {
    MessagePanelHidden: boolean
    TeamPanelHidden: boolean
    RoomPanelHidden: boolean
    InConfRestriction: boolean

    constructor(fields: Partial<Limitations>) {
        Object.assign(this, fields)
    }
}

export class HistoryEntry {
    id: string
    ToUri: string
    SetupTime: string
    In: boolean
    FromUri: string
    DisconnectTime: string
    DisconnectText: string
    DisconnectCause: string
    ConnectTime: string
    DateFormatted: string

    constructor(fields: Partial<Collaborator>) {
        Object.assign(this, fields)
    }
}

export class Message {
    id: string
    From: string
    FromName: string
    To: string
    IsAnonymous: boolean
    IsRead: boolean
    Content: string
    Timestamp: Date
}

export class Description {
    Title: string
    Subtitle: string
    Sections: {
        Title: string
        Content: string
    }[]

    constructor() {
        this.Title = ''
        this.Subtitle = ''
        this.Sections = []
    }
}

export class Appointment {
    ID: string
    FriendlyID: string
    DisplayName: string
    Name: string
    To: string
    Date: Date
    Locked: boolean
    Config: string
    Quizz: string
    Shared: boolean
    Available: boolean
    Attendees: Contact[]

    constructor(fields: Partial<Appointment>) {
        Object.assign(this, fields)
    }

    FormatedDate(): string {
        return moment(this.Date).tz(moment.tz.guess()).format('dddd Do MMMM')
    }

    FormatedTime(): string {
        return moment(this.Date).tz(moment.tz.guess()).format('HH:mm')
    }
}

export interface RoomConfigItem {
    Client: boolean,
    Agent: boolean,
}

export class RoomConfig {
    //Audio: RoomConfigItem
    Video: RoomConfigItem
    Picture: RoomConfigItem
    ScreenSharing: RoomConfigItem
    Name: RoomConfigItem

    constructor(json?: string) {
        if (json != undefined && json !== '') {
            try {
                const fields = JSON.parse(json)
                Object.assign(this, fields)
                return
            } catch (error) {
            }
        }

        //this.Audio = { Client: true, Agent: true }
        this.Video = { Client: true, Agent: true }
        this.Picture =  { Client: true, Agent: true }
        this.ScreenSharing =  { Client: true, Agent: true }
        this.Name = { Client: false, Agent: false }
    }

    String(): string {
        return JSON.stringify(this)
    }
}

export class DomainData {
	Name : string
    SharedUsers: boolean
    CustomData: {
        LoginBackground: string
        ImgBackground: string
        LogoURI: string
        ColorHomeMain: string
        ColorHomeAlt: string
        ColorHomeText: string
        ColorRoomHeader: string
        ColorHomeAltText: string
        ColorHomeMainText: string
        ImgLogoRoom: string
        FriendlyName: string
        IsWildcard: boolean
    }
    
    constructor(fields: Partial<DomainData>) {
        Object.assign(this, fields)
    }
}

export interface ContactData {
    Email: string
    Name: string
}

export interface Contact {
    ID: string
    Email: string
    Name: string
}

export interface AppointmentRequest {
    Appointment: Appointment
    Attendees: string[]
}

export interface UserData {
    ID: string
    Email: string
	IsAdmin: boolean
	CustomerName: string
}

export interface Record {
    CustomerName: string
    AppointmentName: string
    Beginning: Date
    FormatedBeginning: string
    Ending: Date
    Duration: string
    Indicator: string
    OSBrowser: string
    QuizzResult: string
    Stats: string
}

export interface Feedback {
    Content: string
}

export class Answer {
    Text = ''
    Correct = false
}

export class Question {
    Text = ''
    Answers: Answer[]

    constructor() {
        this.Answers = []
        for (let i = 0; i < 4; i++) {
            this.Answers.push(new Answer())
        }
    }
}

export class Quizz {
    Questions: Question[] = []

    constructor(json?: string) {
        if (json != undefined && json !== '') {
            try {
                const fields = JSON.parse(json)
                Object.assign(this, fields)
                return
            } catch (error) {
            }
        }
    }
}

export interface QuizzResult {
    AttendeeID: number
    Name: string
    Score: number
}

export interface QuestionResult {
    AttendeeID: number
    Name: string
    Answered: boolean
    Correct: boolean
}
